import { useEffect, useState } from 'react'
import InfoCard from '../recent-and-voicemail-components/info/InfoCard'
import Recents from './recent-content/Recents'
import Voicemails from './voicemail-content/Voicemails'
import { useMediaQuery } from 'react-responsive'
import { useSelector, useDispatch } from 'react-redux'
import { MARK_RECENT_CALL_OR_VOICEMAIL_AS_READ } from '../../../../graphql'
import { useMutation } from '@apollo/client'
import {
  setVoicemailsNotifications,
  setRecentCallsNotifications,
} from '../../../../redux-toolkit/slices/notifications/notifications-slice'
import VoicemailGreetings from './record-voicemail/voicemailGreetings'
import CustomModal from '../../../common/CustomModal/Modal'
import RecordVoiceModal from './record-voicemail/recordVoicemailModal/recordVoiceModal'
import { usePhoneChannel } from '../../../../hooks'

const UpdateIsRead = ({ item, type }) => {
  const [updateIsReadValue] = useMutation(MARK_RECENT_CALL_OR_VOICEMAIL_AS_READ, {})
  const dispatch = useDispatch()
  const { calls, voicemails } = useSelector((state) => state.notifications)

  useEffect(() => {
    if (!item.is_read) {
      updateIsReadValue({
        variables: {
          pk1: item.pk1,
          sk1: item.sk1,
          is_read: true,
        },
        onCompleted: () => {
          if (type === 'call') {
            dispatch(setRecentCallsNotifications())
          } else if (type === 'voicemail') {
            dispatch(setVoicemailsNotifications())
          }
        },
      })
    }
  }, [calls, voicemails])

  return <></>
}

const RecentAndVoicemail = ({
  tabSelected,
  contactInfoView,
  setContactInfoView,
  loading,
}) => {
  const [displayInfoCard, setDisplayInfoCard] = useState(false)
  const [showMobileContact, setShowMobileContact] = useState(false)
  const selectedCall = useSelector((state) => state.contacts.selectedContact)
  const isMobile = useMediaQuery({ maxWidth: 835 })
  const voicemails = useSelector((state) => state.calls.voicemails)
  const calls = useSelector((state) => state.calls.calls)
  const [openRecordNew, setOpenRecordNew] = useState(false)
  const [phoneChannel] = usePhoneChannel()
  const phoneAccessType = phoneChannel?.AccessType

  useEffect(() => {
    setDisplayInfoCard(false)
  }, [isMobile])

  useEffect(() => {
    setDisplayInfoCard(false)
  }, [tabSelected])

  return (
    <div className="h-full overflow-y-scroll">
      {displayInfoCard ? (
        <InfoCard
          tabSelected={tabSelected}
          setDisplayInfoCard={setDisplayInfoCard}
          selectedCall={selectedCall}
          contactInfoView={contactInfoView}
          setContactInfoView={setContactInfoView}
          showMobileContact={showMobileContact}
          setShowMobileContact={setShowMobileContact}
        />
      ) : tabSelected === 'recent' ? (
        <>
          <Recents
            setDisplayInfoCard={setDisplayInfoCard}
            isMobile={isMobile}
            setContactInfoView={setContactInfoView}
            setShowMobileContact={setShowMobileContact}
            loading={loading}
            phoneAccessType={phoneAccessType}
          />
          {calls?.map((call, i) => (
            <UpdateIsRead item={call} key={i} type={'call'} />
          ))}
        </>
      ) : (
        <>
          <Voicemails
            setDisplayInfoCard={setDisplayInfoCard}
            isMobile={isMobile}
            setContactInfoView={setContactInfoView}
            loading={loading}
            phoneAccessType={phoneAccessType}
          />
          {voicemails?.map((voicemail, i) => (
            <UpdateIsRead item={voicemail} key={i} type={'voicemail'} />
          ))}
          {voicemails.length === 0 && phoneAccessType !== '0' && (
            <div className="w-[100%]">
              <VoicemailGreetings setIsOpen={setOpenRecordNew} />
            </div>
          )}
        </>
      )}

      <CustomModal
        isOpen={openRecordNew}
        showTitle={true}
        close={() => setOpenRecordNew(false)}
        heading="Record a new greeting"
        width="616px"
        hasCrossIcon={true}
      >
        <RecordVoiceModal setOpenRecordNew={setOpenRecordNew} />
      </CustomModal>
    </div>
  )
}

export default RecentAndVoicemail
