import { Checkbox } from '@thryvlabs/maverick'
import ChannelsIcon from '../../../../channels-icon/channels-icon'
import { SolidMessagesIcon } from '../../../../command-center-icon/icons/icons'

const CHANNEL_TYPES = {
  gmail: 'Gmail',
  outlook: 'Outlook',
  yahoo: 'Yahoo mail',
  instagram: 'Instagram',
  facebook: 'Facebook',
  hotmail: 'Hotmail',
  webchat: 'Webchat',
  microsoft365: 'Microsoft 365',
  phone: 'Phone',
  video: 'Video',
  icloud: 'iCloud',
  imap: 'IMAP',
}

export const FilterOption = ({ channel, onClick, selected }) => {
  return (
    <div
      className={`flex items-center h-14 px-[18px] py-3 rounded-md flex-shrink-0 gap-4 text-sm snap-start text-thryv-night
        ${selected ? 'bg-[#E6F2FF]' : ''}`}
      onClick={onClick}
    >
      <Checkbox label={channel.Provider} isChecked={selected} onChange={() => {}} />
      {channel.Provider === 'All Channels' ? (
        <SolidMessagesIcon height="32px" width="32px" fill="#A3A5A7" />
      ) : (
        <ChannelsIcon height="32px" width="32px" variant={channel.Provider} />
      )}
      <div>
        {channel.Nickname || CHANNEL_TYPES[channel.Provider] || channel.Provider}
      </div>
    </div>
  )
}
