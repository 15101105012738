// Components
import sadGuyThryv from '../../../../../assets/sad-thryv-guy.svg'
import CloseButton from '../components/close-button'
import CancelFreemiumContent from './cancel-freemium-content'
import CancelPaidContent from './cancel-paid-content'

const CancellationSummaryModal = ({
  className,
  isVisible,
  handleClose,
  onCancelClick,
  isLoading,
  planName = 'placeholder',
  nextPaymentDate = 'March 31st, 2023',
}) => {
  const isFreemiumPlan = planName === 'Basic' || planName === 'Basic Plus'

  return (
    <div className={`${className} ${isVisible ? '' : 'hidden'}`}>
      <div className="flex justify-end">
        <CloseButton
          onClick={handleClose}
          aria-label="Close Modal"
          data-testid="modal-close-x"
          isDisabled={isLoading}
        />
      </div>
      <div className="max-w-[332px] m-auto text-center">
        <div className="flex justify-center">
          <img className="w-[96px] h-[120px]" src={sadGuyThryv} />
        </div>

        <p className="mt-[16px] font-montserrat text-[22px] font-semibold leading-[30px] text-[#231F20]">
          We&apos;re sorry to see you go!
        </p>

        {isFreemiumPlan && (
          <CancelFreemiumContent
            planName={planName}
            onNevermindClick={handleClose}
            onCancelClick={onCancelClick}
            isLoading={isLoading}
          />
        )}

        {!isFreemiumPlan && (
          <CancelPaidContent
            planName={planName}
            nextPaymentDate={nextPaymentDate}
            onDoneClick={handleClose}
          />
        )}
      </div>
    </div>
  )
}

export default CancellationSummaryModal
