import { Routes, Route, Navigate, useLocation, useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { useQuery, useLazyQuery } from '@apollo/client'
import { ContactInfoView, ContactsListView } from '@contacts'
import ActivationView from '../../components/calls/activation-screen/ActivationView'
import {
  CallsNavigation,
  CallsNavigationMobile,
} from '@calls/recent-and-voicemail/navigation/CallsNavigation'
import {
  Voicemails,
  Recents,
} from '@calls/recent-and-voicemail/voicemail-recent-container'
import { useRedux } from '@hooks'
import {
  loadAppoloContactsState,
  selectContactsData,
  selectContactOnPageLoad,
} from '@redux-toolkit'
import { FETCH_CONTACT_WITH_PHONE, GET_MESSAGES_BY_PHONE_CHANNEL } from '@graphql'
import './calls.css'
import { useMediaQuery } from 'react-responsive'
import MobileDialpad from '../../components/calls/mobile-dialpad/mobile-dialpad'
import CustomModal from '../../components/common/CustomModal/Modal'
import VideoCallLinkModal from '../../components/calls/video-call-link-modal/VideoCallLinkModal'
import {
  setOpenVideoCallLinkModal,
  setCalls,
  setVoicemails,
} from '../../components/calls/slices/callSlice'
import AddMoreMinutes from '../../components/calls/add-minutes/AddMoreMinutes'
import SuccessModal from '../../components/common/modals/pause-sucess-modal/PauseSuccess'
import { GET_CONNECTED_CHANNELS_BY_USER } from '../../graphql'
import { useAuth0 } from '@auth0/auth0-react'
import { useConnectPhoneModal } from '../../components/common/modals/phone-certification-modal/connect-phone-modal/ConnectPhoneModal'
import { setLeftNavigationCollapsed } from '../../redux-toolkit/slices/user-preferences/user-preferences'
import { setContactLists, updateContactsListSortOrder } from '@redux-toolkit'

const CALLS_URLS = ['/calls', '/calls/recents', '/calls/voicemail']

const Calls = () => {
  const [dispatch, useSelector] = useRedux()
  const [sortOrder, setSortOrder] = useState('asc')
  const [contactInfoView, setContactInfoView] = useState('default')
  const { phoneChannelID } = useSelector((state) => state.contacts.contacts)
  const {
    contacts: { connection, contactsList, phoneNumber },
  } = useSelector(selectContactsData)
  const { inboxThreadSk1s } = useSelector((state) => state.inboxThreads)
  const location = useLocation()
  const navigate = useNavigate()
  const collapseContactInfoView = useMediaQuery({ maxWidth: 1150 })
  const collapseLeftNavBar = useMediaQuery({ maxWidth: 1450 })

  useEffect(() => {
    if (collapseLeftNavBar) {
      dispatch(setLeftNavigationCollapsed(true))
    }

    return () => {
      if (collapseLeftNavBar) {
        dispatch(setLeftNavigationCollapsed(false))
      }
    }
  }, [collapseLeftNavBar])
  useEffect(
    () => {
      if (!CALLS_URLS.includes(location.pathname)) {
        navigate(CALLS_URLS[0])
      }
    },
    CALLS_URLS,
    location.pathname,
    navigate,
  )

  const {
    data: contactsData,
    loading: contactsLoading,
    refetch: refetchContacts,
  } = useQuery(FETCH_CONTACT_WITH_PHONE)

  const [openConnectPhoneModal, , ConnectPhoneModal] = useConnectPhoneModal()
  const { user } = useAuth0()
  const { data: connectedChannelsData } = useQuery(GET_CONNECTED_CHANNELS_BY_USER, {
    variables: { loc_id: user.businessId, user_id: user.cc_uid },
  })

  const phoneChannel = connectedChannelsData?.queryConnectedChannelsByUser.find(
    (channel) => channel?.Provider === 'phone',
  )
  // const phoneChId = phoneChannel?.ChannelID
  const [getRecentCalls, { loading: callsLoading, data: recentCallsData }] =
    useLazyQuery(GET_MESSAGES_BY_PHONE_CHANNEL, {
      onCompleted: () => {
        dispatch(setCalls(recentCallsData.queryMessagesByPhoneChannel))
      },
    })

  const [
    getRecentVoicemails,
    { loading: voicemailsLoading, data: recentVoicemailsData },
  ] = useLazyQuery(GET_MESSAGES_BY_PHONE_CHANNEL, {
    onCompleted: () => {
      dispatch(setVoicemails(recentVoicemailsData.queryMessagesByPhoneChannel))
    },
  })

  const { mobileKeypadOpen } = useSelector((state) => state.calls)
  const isMobile = useMediaQuery({ maxWidth: 835 })
  const openVideoCallLinkModal = useSelector(
    (state) => state.calls.openVideoCallLinkModal,
  )
  const [openAddMoreMinutes, setOpenAddMoreMinutes] = useState(false)
  const [openSuccessModal, setOpenSuccessModal] = useState(false)
  const countryISO = useSelector((state) => state.countryCode.countryIso2)
  const phoneAccessType = phoneChannel?.AccessType

  const handleClose = function () {
    setOpenAddMoreMinutes(false)
  }

  const initContactsReduxState = () => {
    const queriedContacts = contactsData?.queryContactsWithPhoneNumber

    const loadedState = {
      connection: connection || false,
      phoneNumber: phoneNumber || '',
    }

    dispatch(
      setContactLists({
        sortOrder: 'asc',
        queriedContacts: queriedContacts,
        countryISO: countryISO,
        phoneAccessType,
      }),
    )

    dispatch(loadAppoloContactsState(loadedState))
  }

  useEffect(() => {
    dispatch(updateContactsListSortOrder(sortOrder))
  }, [sortOrder])

  useEffect(() => {
    if (!contactsLoading && phoneAccessType) initContactsReduxState()
  }, [contactsLoading, phoneAccessType])

  // if user successfully update an contact, refetch contactsData
  useEffect(() => {
    if (!contactsLoading) {
      refetchContacts()
    }
  }, [inboxThreadSk1s, contactsLoading])

  const refetchVoicemailsAndCalls = () => {
    getRecentVoicemails({
      fetchPolicy: 'no-cache',
      variables: {
        chanpk: phoneChannelID,
        item_type: 'VOICEMAIL',
      },
    })
    getRecentCalls({
      fetchPolicy: 'no-cache',
      variables: {
        chanpk: phoneChannelID,
        item_type: 'CALL',
      },
    })
  }

  useEffect(() => {
    if (phoneChannelID) {
      refetchVoicemailsAndCalls()
    }
  }, [phoneChannelID])

  useEffect(() => {
    if (contactsList?.length) {
      dispatch(selectContactOnPageLoad())
    }
  }, [contactsList?.length])

  const renderWelcomeScreen = !connection ? (
    <ActivationView openConnectPhoneModal={openConnectPhoneModal} />
  ) : null

  const renderContactsListView = !isMobile ? (
    <ContactsListView
      setOpenAddMoreMinutes={setOpenAddMoreMinutes}
      sortedOrder={sortOrder}
      setSortedOrder={setSortOrder}
      contactInfoView={contactInfoView}
      setContactInfoView={setContactInfoView}
    />
  ) : null

  const renderCallsHistory = connection ? (
    <Routes>
      <Route
        path="/"
        element={
          <Recents
            loading={callsLoading}
            contactInfoView={contactInfoView}
            setContactInfoView={setContactInfoView}
          />
        }
      />
      <Route
        path="/recents"
        element={
          <Recents
            loading={callsLoading}
            contactInfoView={contactInfoView}
            setContactInfoView={setContactInfoView}
          />
        }
      />
      <Route
        path="/voicemail"
        element={
          <Voicemails
            loading={voicemailsLoading}
            contactInfoView={contactInfoView}
            setContactInfoView={setContactInfoView}
          />
        }
      />
      <Route
        path="/contacts"
        element={
          isMobile ? (
            <ContactsListView
              contactInfoView={contactInfoView}
              setContactInfoView={setContactInfoView}
            />
          ) : (
            <Navigate replace to={'/calls'} />
          )
        }
      />
    </Routes>
  ) : null

  return (
    <div className="h-full flex md:flex-col items-start justify-start gap-0">
      {mobileKeypadOpen && isMobile ? (
        <MobileDialpad />
      ) : (
        <>
          {connection && <CallsNavigationMobile />}
          <div className="h-full md:hidden relative">{renderContactsListView}</div>
          <div className="h-full w-full flex m-0 bg-white">
            <div id="contact-calls-and-profile-wrapper">
              {connection && (
                <div id="calls-navigation">
                  <CallsNavigation
                    setOpenAddMoreMinutes={setOpenAddMoreMinutes}
                    connection={connection}
                    isMobile={isMobile}
                  />
                </div>
              )}
              {renderCallsHistory}
              {renderWelcomeScreen}
              {!collapseContactInfoView && (
                <div
                  id="contact-sidesheet"
                  className={`w-[350px] overflow-y-scroll md:hidden border-x border-[#F1F1F5] ${
                    collapseContactInfoView && 'hidden'
                  }`}
                  style={{ height: 'calc(100vh - 146px)' }}
                >
                  <ContactInfoView
                    connection={connection}
                    refetchContacts={refetchContacts}
                    setContactInfoView={setContactInfoView}
                    contactInfoView={contactInfoView}
                  />
                </div>
              )}
            </div>
          </div>
        </>
      )}
      <CustomModal
        isModalForm={true}
        isOpen={openVideoCallLinkModal}
        setIsOpen={setOpenVideoCallLinkModal}
        handleClose={() => {
          dispatch(setOpenVideoCallLinkModal(false))
        }}
        width={isMobile ? '325px' : '500px'}
      >
        <VideoCallLinkModal />
      </CustomModal>

      <CustomModal
        isModalForm={true}
        showTitle={true}
        heading="Add More Minutes"
        hasCrossIcon={true}
        isOpen={openAddMoreMinutes}
        setIsOpen={setOpenAddMoreMinutes}
        close={handleClose}
        width={isMobile ? '94%' : '1024px'}
      >
        <AddMoreMinutes
          setOpenAddMoreMinutes={setOpenAddMoreMinutes}
          setOpenSuccessModal={setOpenSuccessModal}
          handleClose={handleClose}
        />
      </CustomModal>

      <CustomModal
        isModalForm={true}
        showTitle={false}
        hasCrossIcon={false}
        isOpen={openSuccessModal}
        setIsOpen={setOpenSuccessModal}
        close={setOpenSuccessModal}
        width={isMobile ? '94%' : '500px'}
      >
        <SuccessModal
          modalType="Success"
          modalHeaderMessage="Congratulations, payment completed"
          modalBodyMessage="Monthly minutes have been added to your Plan. Need more? Visit Settings or upgrade your plan!"
          setOpenSuccessModal={setOpenSuccessModal}
        />
      </CustomModal>

      {ConnectPhoneModal}
    </div>
  )
}

export default Calls
