import { useLazyQuery } from '@apollo/client'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useContext, useState } from 'react'
import {
  SUBSCRIPTION_FOR_MESSAGE_CREATED,
  GET_MESSAGES_BY_PHONE_CHANNEL,
} from '../../graphql'
import { FETCH_THREADS } from '../../graphql'

import {
  setInboxNotifications,
  setCallsNotifications,
} from '../../redux-toolkit/slices/notifications/notifications-slice'
import { setCalls, setVoicemails } from '../calls/slices/callSlice'
import { AmplifyClientContext } from '../authentication/amplify-context'
import { useAuth0 } from '@auth0/auth0-react'

import { Buffer } from 'buffer'
window.Buffer = Buffer

export function CallsSubscriptionListener() {
  const dispatch = useDispatch()

  const { getAccessTokenSilently } = useAuth0()
  const [createMessageSubs, setCreateMessageSubs] = useState()

  const { phoneChannelID } = useSelector((state) => state.contacts.contacts)

  const client = useContext(AmplifyClientContext)

  const [getUnreadThreads, { data: unreadThreadsData }] = useLazyQuery(
    FETCH_THREADS,
    {
      errorPolicy: 'ignore',
      fetchPolicy: 'no-cache',
      variables: {
        folder: 'unread',
      },
      onCompleted: () => {
        const threads = unreadThreadsData?.queryThreads?.items?.filter(
          (x) => x?.messages?.items?.length > 0,
        )
        const pinned = unreadThreadsData?.pinned?.items?.filter(
          (x) => x?.messages?.items?.length > 0 && x?.read === false,
        )
        dispatch(setInboxNotifications(threads?.length + pinned?.length || 0))
      },
    },
  )

  const [getRecentCalls, { data: recentCallsData }] = useLazyQuery(
    GET_MESSAGES_BY_PHONE_CHANNEL,
    {
      fetchPolicy: 'no-cache',
      onCompleted: () => {
        dispatch(setCalls(recentCallsData.queryMessagesByPhoneChannel))
        const unreadCalls = recentCallsData.queryMessagesByPhoneChannel.filter(
          (x) => !x.is_read,
        ).length

        dispatch(
          setCallsNotifications({
            calls: unreadCalls || 0,
          }),
        )
      },
    },
  )

  const [getRecentVoicemails, { data: recentVoicemailsData }] = useLazyQuery(
    GET_MESSAGES_BY_PHONE_CHANNEL,
    {
      fetchPolicy: 'no-cache',
      onCompleted: () => {
        dispatch(setVoicemails(recentVoicemailsData.queryMessagesByPhoneChannel))
        const unreadVoicemails =
          recentVoicemailsData.queryMessagesByPhoneChannel.filter(
            (x) => !x.is_read,
          ).length

        dispatch(
          setCallsNotifications({
            voicemails: unreadVoicemails || 0,
          }),
        )
      },
    },
  )

  const subscribeOnMessage = async () => {
    const accessToken = await getAccessTokenSilently()
    const subscription = client
      ?.graphql({
        query: SUBSCRIPTION_FOR_MESSAGE_CREATED,
        authToken: `Bearer ${accessToken}`,
      })
      .subscribe({
        next: ({ data: subscriptionAlert }) => {
          if (subscriptionAlert) {
            getUnreadThreads()
            getRecentVoicemails({
              variables: {
                chanpk: phoneChannelID,
                item_type: 'VOICEMAIL',
              },
            })

            getRecentCalls({
              variables: {
                chanpk: phoneChannelID,
                item_type: 'CALL',
              },
            })
          }
        },
      })
    setCreateMessageSubs(subscription)
  }

  useEffect(() => {
    subscribeOnMessage()
    return () => {
      createMessageSubs?.unsubscribe()
    }
  }, [])

  return <></>
}
