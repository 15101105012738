const EmphasisText = ({ className, children, ...rest }) => (
  <span className={`${className} text-[#4761C3] font-bold`} {...rest}>
    {children}
  </span>
)

const ParagraphText = ({ className, children, ...rest }) => (
  <p
    className={`${className} font-open-sans text-[14px] leading-[20px] text-[#231F20]`}
    {...rest}
  >
    {children}
  </p>
)

const ErrorText = ({ className, children, ...rest }) => (
  <p
    className={`${className} text-open-sans text-[12px] leading-[18px] text-[#DF2A2A] mt-[24px]`}
    {...rest}
  >
    {children}
  </p>
)

export { EmphasisText, ParagraphText, ErrorText }
