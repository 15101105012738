// import { LuSquareArrowOutUpRight } from 'react-icons/lu'

const ViewInBusinessCenterLink = ({ className, href = '#' }) => {
  return (
    <a
      href={href}
      rel="noreferrer"
      className={`${className} block text-center rounded`}
      aria-label="View Contact in Business Center"
    >
      <div className="inline-block">
        <div className="flex items-center gap-[8px]">
          <p className="text-[#057AFF] font-bold text-[13px]">
            View in Business Center
          </p>
          {/* commenting out for hotfix leaving here in case we want it back*/}
          {/* <LuSquareArrowOutUpRight color="#057AFF" size="12" />  */}
        </div>
      </div>
    </a>
  )
}

export default ViewInBusinessCenterLink
