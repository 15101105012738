import { useEffect } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { useNavigate } from 'react-router'
import {
  AuthLoader,
  ApolloWrapper,
  UnauthenticatedApp,
  AuthenticatedApp,
} from '@authentication'
import { NotificationBar } from '@thryvlabs/maverick'
import { useLDClient } from 'launchdarkly-react-client-sdk'
import { redirectToBC } from './utils/redirect-to-bc'
import { ErrorBoundary } from 'react-error-boundary'
import ErrorFallback from './components/ErrorBoundary/ErrorFallback'
import './App.css'
import { setInitialCardData } from './components/common/modals/slices/connectChannelsSlice'
import { useDispatch } from 'react-redux'
import ButtonsData from './components/welcome-screen/buttonsData'
import CancellationSuccessScreen from './components/cancellation-success-screen/CancellationSuccessScreen'

//  Please refer to src/components/authentication/authenticated-app.jsx
//  if you want to add state when the app initializes
function App() {
  const { error, isAuthenticated, isLoading, user, logout } = useAuth0()
  const navigate = useNavigate()
  const LDClient = useLDClient()
  const dispatch = useDispatch()
  const initialState = ButtonsData()

  useEffect(() => {
    dispatch(setInitialCardData(initialState))
  }, [])

  useEffect(() => {
    if (isAuthenticated && !user?.changePassword) {
      LDClient.identify({
        kind: 'user',
        key: user.email,
        email: user.email,
        cc_id: user.cc_id,
        role: user.cc_role,
        name: user.name,
        cc_uid: user.cc_uid,
        country: user.country,
        businessId: user.businessId,
        staffUID: user.staffUID,
      })
    }
  }, [isAuthenticated, LDClient, user])

  useEffect(() => {
    if (isAuthenticated && user?.changePassword) {
      navigate('/reset-password')
    } else if ((!user?.cc_uid || !user?.cc_id) && user?.ssoToken) {
      redirectToBC(user?.dirCode, user?.staffUID, user?.ssoToken)
    }
  }, [isAuthenticated, user, navigate])

  useEffect(() => {
    if (isAuthenticated && window.location.pathname === `/email-verified`) {
      navigate('/')
    }
  }, [isAuthenticated, window, navigate])

  const logError = (error) => {
    // eslint-disable-next-line no-console
    console.log(error)
  }

  if (isLoading || user?.changePassword) return <AuthLoader />

  if (error) {
    // eslint-disable-next-line no-undef
    if (process.env.NODE_ENV === 'development') {
      // eslint-disable-next-line no-console
      console.error('ERROR IN APP: ', error)
    }
    const commandCenterCancelled = error.message.includes('Cancelled Command Center')

    if (commandCenterCancelled) {
      return <CancellationSuccessScreen />
    }

    const commandCenterIsInvalid = error.message.includes('Invalid state')
    if (commandCenterIsInvalid) {
      setTimeout(() => {
        logout({ logoutParams: { returnTo: window.location.origin } })
      }, 5000)
    }

    return <NotificationBar variant="error" content={<>{error.message}</>} />
  }

  if (isAuthenticated && !user?.changePassword) {
    return (
      <ErrorBoundary FallbackComponent={ErrorFallback} onError={logError}>
        <ApolloWrapper>
          <AuthenticatedApp />
        </ApolloWrapper>
      </ErrorBoundary>
    )
  }

  return <UnauthenticatedApp />
}

export default App
