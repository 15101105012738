import { AddStaffModal } from '../../../common/add-staff-modal'
import { withModal, Button } from '@thryvlabs/maverick'

export const AddStaffButtonWithModal = ({ className }) => {
  const NewButton = ({ showModal }) => (
    <Button
      data-testid="add-staff"
      variant="primary"
      buttonType="outlined"
      className={`${className} w-[106px] h-8 font-semibold border-2 flex justify-center items-center flex-shrink-0`}
      onClick={showModal}
    >
      Add Staff
    </Button>
  )

  const ButtonWithModal = withModal(NewButton, AddStaffModal)

  return <ButtonWithModal />
}
