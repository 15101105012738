import { useState, useEffect } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { Header, Input, MaskedInput } from '@thryvlabs/maverick'
import { Select } from '@thryvlabs/maverick'
import { useMutation } from '@apollo/client'
import {
  SET_ADD_RECURLY_BILLING_INFO,
  SET_UPDATE_RECURLY_BILLING_INFO,
} from '../../../graphql'
import { getRecurlyErrorMessage } from '../../../utils/recurly-parse-error'
import { getCountryStates } from '../../top-navigation-bar/StateProvinceSelectDropdown'
import { useSelector } from 'react-redux'
import { postalCodeValidator } from '../../../utils/postalCodeValidator'
import { useAuth0 } from '@auth0/auth0-react'
import { usePaymentMethods } from '../../../hooks/use-payment-methods'

const Step2 = ({
  formData,
  setFormData,
  primary_payment_method,
  setFormPart,
  setLoader,
  refetchBillingInfo,
}) => {
  const { user } = useAuth0()
  const [isEdit, setIsEdit] = useState(false)
  const [error, setError] = useState(null)
  const countryCode = useSelector((state) => state.countryCode.countryIso2)
  const [states] = useState(
    getCountryStates(useSelector((state) => state.countryCode.countryIso2)),
  )
  const { refetchUpdatedPaymentData } = usePaymentMethods()

  const getSelectedState = function (selectedState) {
    return states.find((state) => state.value === selectedState)
  }

  const getDoubleDigitDate = function (date) {
    if (date.toString().length == 1) {
      return `0${date}`
    }
    return date
  }

  const {
    handleSubmit,
    control,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      cardNumber: '',
      expiresOn: formData
        ? `${getDoubleDigitDate(
            formData.billingInfo?.paymentMethod.expMonth,
          )}${formData.billingInfo.paymentMethod.expYear.toString().substring(2, 4)}`
        : '',
      cvv: '',
      fname: formData ? formData.firstName : '',
      lname: formData ? formData.lastName : '',
      street1: formData ? formData.billingInfo.address.street1 : '',
      street2: formData ? formData.billingInfo.address.street2 : '',
      city: formData ? formData.billingInfo.address.city : '',
      state: formData
        ? getSelectedState(formData.billingInfo.address.region)
        : { name: '', value: '' },
      postalCode: formData ? formData.billingInfo.address.postalCode : '',
    },
  })

  const [createBilling, { error: errorAddingBillingInfo }] = useMutation(
    SET_ADD_RECURLY_BILLING_INFO,
    {
      onCompleted: () => {
        refetchBillingInfo()
        refetchUpdatedPaymentData()
        setLoader(false)
        setFormPart('Add Minutes')
      },
      onError: () => {
        setLoader(false)
      },
    },
  )

  const [updateBilling, { error: errorUpdatingBillingInfo }] = useMutation(
    SET_UPDATE_RECURLY_BILLING_INFO,
    {
      onCompleted: (data) => {
        const { updateRecurlyBillingInfo: updatedBillingInfo } = data
        refetchUpdatedPaymentData()
        setFormData((data) => ({ ...data, billingInfo: updatedBillingInfo }))
        setLoader(false)
        setFormPart('Add Minutes')
      },
      onError: () => {
        setLoader(false)
      },
    },
  )

  useEffect(() => {
    if (errorAddingBillingInfo || errorUpdatingBillingInfo) {
      let errorMessage
      try {
        const addBillingInfoError = JSON.parse(
          errorAddingBillingInfo?.message || '{}',
        )?.params?.transactionError?.message
        const updateBillingInfoError = JSON.parse(
          errorUpdatingBillingInfo?.message || '{}',
        )?.params?.transactionError?.message
        errorMessage = addBillingInfoError || updateBillingInfoError
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error('Error parsing error message', err)
      }

      setError(errorMessage || 'Error adding payment method')
    }
  }, [errorAddingBillingInfo, errorUpdatingBillingInfo])

  function updateBillingInfo(data) {
    const client = window.recurly
    const cc_id = user.cc_id

    client.configure({
      publicKey: import.meta.env.VITE_RECURLY_PUBLIC_KEY,
      parent: false,
      cors: true,
    })

    client.token(
      {
        id: formData.code,
        first_name: data.fname,
        last_name: data.lname,
        number: data.cardNumber,
        year: `20${Number(data.expiresOn.substring(2, 4))}`,
        month: Number(data.expiresOn.substring(0, 2)),
        cvv: data.cvv,
        address1: data.street1,
        address2: data.street2,
        country: countryCode,
        city: data.city,
        state: data.state.value,
        postal_code: data.postalCode,
        primary_payment_method: primary_payment_method,
      },
      (err, token) => {
        if (token) {
          updateBilling({
            variables: {
              CCID: cc_id,
              token: token.id,
              billingInfoId: formData.billingInfo.id,
              isPrimary: true,
            },
          })
        } else if (err) {
          setLoader(false)
          setError(getRecurlyErrorMessage(err))
        }
      },
    )
  }

  async function createBillingInfo(data) {
    const client = window.recurly
    client.configure({
      publicKey: import.meta.env.VITE_RECURLY_PUBLIC_KEY,
      parent: false,
      cors: true,
    })
    client.token(
      {
        first_name: data.fname,
        last_name: data.lname,
        number: data.cardNumber,
        year: `20${Number(data.expiresOn.substring(2, 4))}`,
        month: Number(data.expiresOn.substring(0, 2)),
        cvv: data.cvv,
        address1: data.street1,
        address2: data.street2,
        country: countryCode,
        city: data.city,
        state: data.state.value,
        postal_code: data.postalCode,
        primary_payment_method: primary_payment_method,
      },
      (err, token) => {
        if (token) {
          createBilling({
            variables: {
              CCID: user.cc_id,
              token: token.id,
              isPrimary: primary_payment_method,
            },
          })
        } else if (err) {
          setLoader(false)
          setError(getRecurlyErrorMessage(err))
        }
      },
    )
  }

  const customValidationPincode = (value) => {
    if (postalCodeValidator(countryCode, value) === 'valid') {
      return undefined
    } else {
      return postalCodeValidator(countryCode, value)
    }
  }

  useEffect(() => {
    if (formData?.billingInfo?.id) {
      setIsEdit(true)
    }
  }, [formData])

  const handleFormSubmit = (data, e) => {
    e.preventDefault()
    setLoader(true)

    if (isEdit) {
      updateBillingInfo(data)
    } else {
      createBillingInfo(data)
    }
  }

  return (
    <>
      <form
        id="payment-form"
        className="w-[95%] m-auto border border-[#ECEEF1] p-[1rem]"
        onSubmit={handleSubmit(handleFormSubmit)}
      >
        <Header
          fontWeight="medium"
          variant="h3"
          className="mb-3 text-sm open-sans text-[#231F20]"
        >
          Credit Card Info*
        </Header>
        <div className="grid grid-cols-6 gap-3">
          <div className="sm:col-span-6 col-span-2 mt-5 mb-5">
            <>
              <Controller
                control={control}
                rules={{
                  required: 'Card Number is required',
                  minLength: {
                    value: 15,
                    message: 'Card Number must be 15/16 digits',
                  },
                  maxLength: {
                    value: 16,
                    message: 'Card Number must be 15/16 digits',
                  },
                }}
                name="cardNumber"
                render={({ field }) => (
                  <MaskedInput
                    onChange={(e) => setValue('cardNumber', e.target.value)}
                    className="w-[100%] mt-[7px]"
                    type="tel"
                    {...field}
                    id="cardNumber"
                    name="cardNumber"
                    withLabel
                    placeholder="Card Number"
                    labelType="floating"
                    variant="default"
                    mask="#### #### #### ####"
                    maxLength={20}
                  />
                )}
              />
              <p className="text-notification-red text-xs ">
                {errors.cardNumber?.message}
              </p>
            </>
          </div>

          <div className="sm:col-span-6 col-span-2 mt-5 mb-5">
            <>
              <Controller
                control={control}
                rules={{ required: 'Expiry date is required' }}
                name="expiresOn"
                render={({ field }) => (
                  <MaskedInput
                    onChange={(e) => setValue('expiresOn', e.target.value)}
                    className="w-[100%] mt-[7px]"
                    type="tel"
                    {...field}
                    id="expiresOn"
                    name="expiresOn"
                    withLabel
                    placeholder="Expires On (MM/YY)"
                    labelType="floating"
                    variant="default"
                    mask="##/##"
                    maxLength={5}
                  />
                )}
              />
              <p className="text-notification-red text-xs ">
                {errors.expiresOn?.message}
              </p>
            </>
          </div>

          <div className="sm:col-span-6 col-span-2 mt-5 mb-5">
            <>
              <Controller
                control={control}
                rules={{
                  required: 'CVV is required',
                  minLength: {
                    value: 3,
                    message: 'CVV must be 3/4 digits',
                  },
                  maxLength: {
                    value: 4,
                    message: 'CVV must be 3/4 digits',
                  },
                }}
                name="cvv"
                render={({ field }) => (
                  <Input
                    onChange={(e) => {
                      if (e.target.value.toString().length < 5)
                        setValue('cvv', e.target.value)
                    }}
                    className="w-[100%] mt-[7px]"
                    type="tel"
                    {...field}
                    id="cvv"
                    name="cvv"
                    withLabel
                    placeholder="CVV"
                    labelType="floating"
                    variant="default"
                  />
                )}
              />
              <p className="text-notification-red text-xs ">{errors.cvv?.message}</p>
            </>
          </div>
        </div>
        <Header
          fontWeight="medium"
          variant="h3"
          className="mt-5 mb-3 text-sm open-sans text-[#231F20]"
        >
          Billing Address
        </Header>
        <div className="grid grid-cols-6 gap-3">
          <div className="sm:col-span-6 col-span-3 mt-5 mb-5">
            <>
              <Controller
                control={control}
                rules={{ required: 'First Name is required' }}
                name="fname"
                render={({ field }) => (
                  <Input
                    onChange={(e) => setValue('fname', e.target.value)}
                    className="w-[100%] mt-[7px]"
                    type="text"
                    {...field}
                    id="fname"
                    name="fname"
                    placeholder="First Name"
                    withLabel
                    labelType="floating"
                    variant="default"
                  />
                )}
              />
              <p className="text-notification-red text-xs ">
                {errors.fname?.message}
              </p>
            </>
          </div>

          <div className="sm:col-span-6 col-span-3 mt-5 mb-5">
            <>
              <Controller
                control={control}
                rules={{ required: 'Last Name is required' }}
                name="lname"
                render={({ field }) => (
                  <Input
                    onChange={(e) => setValue('lname', e.target.value)}
                    className="w-[100%] mt-[7px]"
                    type="text"
                    {...field}
                    id="lname"
                    name="lname"
                    withLabel
                    placeholder="Last Name"
                    labelType="floating"
                    variant="default"
                  />
                )}
              />
              <p className="text-notification-red text-xs ">
                {errors.lname?.message}
              </p>
            </>
          </div>

          <div className="sm:col-span-6 col-span-3 mt-5 mb-5">
            <>
              <Controller
                control={control}
                rules={{ required: 'Email is required' }}
                name="street1"
                render={({ field }) => (
                  <Input
                    onChange={(e) => setValue('street1', e.target.value)}
                    className="w-[100%] mt-[7px]"
                    type="text"
                    {...field}
                    id="street1"
                    name="street1"
                    withLabel
                    labelType="floating"
                    placeholder="Street Address"
                    variant="default"
                  />
                )}
              />
              <p className="text-notification-red text-xs ">
                {errors.street1?.message}
              </p>
            </>
          </div>

          <div className="sm:col-span-6 col-span-3 mt-5 mb-5">
            <>
              <Controller
                control={control}
                name="street2"
                render={({ field }) => (
                  <Input
                    onChange={(e) => setValue('street2', e.target.value)}
                    className="w-[100%] mt-[7px]"
                    type="text"
                    {...field}
                    id="street2"
                    name="street2"
                    withLabel
                    labelType="floating"
                    placeholder="Street Address 2 (optional)"
                    variant="default"
                  />
                )}
              />
            </>
          </div>

          <div className="sm:col-span-6 col-span-2 mt-5 mb-5">
            <>
              <Controller
                control={control}
                rules={{ required: 'City is required' }}
                name="city"
                render={({ field }) => (
                  <Input
                    onChange={(e) => setValue('city', e.target.value)}
                    className="w-[100%] mt-[7px]"
                    type="text"
                    {...field}
                    id="city"
                    name="city"
                    placeholder="City"
                    withLabel
                    labelType="floating"
                    variant="default"
                  />
                )}
              />
              <p className="text-notification-red text-xs ">
                {errors.city?.message}
              </p>
            </>
          </div>

          <div className="sm:col-span-6 col-span-2 mt-4 mb-5">
            <>
              <Controller
                control={control}
                rules={{ required: 'State is required' }}
                name="state"
                render={({ field }) => (
                  <Select
                    options={states}
                    id="state"
                    name="state"
                    width="full"
                    selectLabel="State/Region"
                    labelType="floating"
                    selectedOption={getValues('state')}
                    {...field}
                    setSelectedOption={(e) => setValue('state', e)}
                  />
                )}
              />
              <p className="text-notification-red text-xs ">
                {errors.state?.message}
              </p>
            </>
          </div>

          <div className="sm:col-span-6 col-span-2 mt-5 mb-5">
            <>
              <Controller
                control={control}
                rules={{
                  required: 'Postal Code is required',
                  validate: customValidationPincode,
                }}
                name="postalCode"
                render={({ field }) => (
                  <Input
                    onChange={(e) => setValue('postalCode', e.target.value)}
                    className="w-[100%] mt-[7px]"
                    type="tel"
                    {...field}
                    id="postalCode"
                    name="postalCode"
                    placeholder="Postal code"
                    withLabel
                    labelType="floating"
                    variant="default"
                  />
                )}
              />
              <p className="text-notification-red text-xs ">
                {errors.postalCode?.message}
              </p>
            </>
          </div>
        </div>
      </form>
      {error ? (
        <p className="!text-[14px] !text-[#B22222] ml-[20px]">{error}</p>
      ) : null}
    </>
  )
}

export default Step2
