import { useState, useEffect, forwardRef } from 'react'
import { useClickOutside } from '../../../../../hooks/use-click-outside'
import { useRef } from 'react'
import { useSelector, useDispatch, batch } from 'react-redux'
// import { getEditorText } from '../quill-editor/quill-editor-helpers'
import {
  setEditorContents,
  handleClearResponseBar,
  setSendButtonClicked,
  setEditorView,
  setSubmitMessageFlag,
  // setUnsendButtonClicked,
} from '../../../slices/messageComposeSlice'
import { setError, handleReloadMessage } from '../../../slices/inboxSlice'
import { setMessageSent } from '../../../slices/inboxThreadsSlice'
import {
  setIsLoading,
  setJustNowTimer,
  clearConfirmAttachmentData,
} from '../../../slices/inboxSlice'
// import { updateInboxNotif } from '../../../slices/inbox-notifications/inbox-notifications-slice'

import { useMutation } from '@apollo/client'
import { CREATE_MESSAGE } from '../../../../../graphql/mutations/inbox-message-mutations'
import { FETCH_MESSAGES_BY_THREAD_ID } from '@graphql'

// Components
import { TransitionContainer } from '../../../../common/transition-container'
import { CommandCenterIcon } from '../../../../command-center-icon'
import { TooltipWithCenteredArrow } from '../../../../common/tooltip-with-centered-arrow'
import { QuickActionModal } from './quick-action-modal/quick-action-modal'
import { Icon } from '@thryvlabs/maverick'

export const RightActionButtons = forwardRef(
  (
    {
      loadingAttachments,
      // quillEditorRef,
      setUploadedFiles,
      setLimitAttachmentError,
      setAttachmentLimit,
      setDraftData,
      uploadedFiles,
      refetchMessages,
      setDraftMutationBody,
    },
    ref,
  ) => {
    const {
      selectedChannel,
      editorText,
      editorHTML,
      subjectText,
      submitMessageFlag,
      // sendButtonClicked,
      // unsendButtonClicked,
    } = useSelector((s) => s.messageCompose)
    const { isLoading, reloadMessage } = useSelector((state) => state.inbox)
    const [isOpen, setIsOpen] = useState(false)
    const quickActionRef = useRef(null)
    const selectedThreadId = useSelector(
      (state) => state.inboxThreads.selectedThread.id,
    )

    const dispatch = useDispatch()

    useClickOutside((e) => {
      const quickActionButton = document.getElementById('quickActionButton')
      const buttonClicked = e.composedPath().includes(quickActionButton)
      if (buttonClicked) return //if we click the button dont run the useClickOutside
      if (isOpen) {
        //so that it only sets it to false if its true
        setIsOpen(false)
      }
    }, quickActionRef)

    // FIXME:
    //  THIS NEEDS TO CHANGE TO ACCOUNT FOR HOW THE CHANNELS ARE COMING NOW
    // const limitSend = !(
    //   selectedChannel.type === 'email' || selectedChannel.type === 'phone'
    // )

    const clearMessageCompose = () => {
      setDraftData({})
      dispatch(
        setEditorContents({
          text: '',
          html: '',
        }),
      )
      dispatch(handleClearResponseBar())
      setUploadedFiles([])
      setAttachmentLimit(0)
      setLimitAttachmentError(false)
    }

    const recipientEmail = useSelector((state) => state.inbox.recipient.email)
    const recipientName = useSelector((state) => state.inbox.recipient.name)
    const recipientPhone = useSelector((state) => state.inbox.recipient.phoneNumber)
    const { hasBusinessCenter } = useSelector((state) => state.userPreferences)

    const emailCC = useSelector(
      (state) => state.messageCompose.message.emailRecipients.cc,
    )
    const emailBCC = useSelector(
      (state) => state.messageCompose.message.emailRecipients.bcc,
    )

    const getAllEmailCcs = () => {
      return emailCC?.map((e) => {
        return { name: e.name, email: e.email }
      })
    }
    const getAllEmailBcs = () => {
      return emailBCC?.map((e) => {
        return { name: e.name, email: e.email }
      })
    }

    const renderMessageError = (error) => {
      if (error) {
        dispatch(setError({ status: true, message: error.message }))
      }
    }

    const handleScroll = () => {
      sessionStorage.removeItem('scrollPosition')
      const scrollTimeout = setTimeout(() => {
        ref.current.scrollTop = ref.current.scrollHeight
      }, 10)
      return () => clearTimeout(scrollTimeout)
    }

    const [createSMSMessage, { loading: smsLoading }] = useMutation(CREATE_MESSAGE, {
      variables: {
        body: editorHTML,
        thread_id: selectedThreadId,
        channel_id: selectedChannel?.ChannelID,
        number_from: selectedChannel?.AccountUserValue,
        number_to: recipientPhone,
      },
      refetchQueries: [
        {
          query: FETCH_MESSAGES_BY_THREAD_ID,
          variables: { threadid: selectedThreadId },
          fetchPolicy: 'network-only',
        },
      ],
      onCompleted: () => {
        setDraftMutationBody(null)
        clearMessageCompose()
        batch(() => {
          dispatch(setMessageSent(true))
          dispatch(setJustNowTimer(true))
          dispatch(setSendButtonClicked(false))
          dispatch(setError({ status: false, message: '' }))
          // dispatch(
          //   updateInboxNotif({
          //     show: false,
          //     name: 'unsendMessageNotification',
          //   }),
          // )
        })

        if (reloadMessage) {
          refetchMessages()
          dispatch(handleReloadMessage(false))
        }
      },
      onError: (error) => {
        dispatch(setIsLoading(false))
        renderMessageError(error)
      },
    })

    const [createMessage, { error, loading }] = useMutation(CREATE_MESSAGE, {
      variables: {
        body: editorHTML,
        email_subject: subjectText,
        thread_id: selectedThreadId,
        email_to: { name: recipientName, email: recipientEmail },
        email_cc: getAllEmailCcs(),
        email_bcc: getAllEmailBcs(),
        channel_id: selectedChannel?.ChannelID,
        email_from: {
          name: selectedChannel?.AccountUserValue,
          email: selectedChannel?.AccountUserValue,
        },
      },
      onCompleted: () => {
        batch(() => {
          dispatch(setMessageSent(true))
          dispatch(setJustNowTimer(true))
          dispatch(clearConfirmAttachmentData())
          dispatch(setError({ status: false, message: '' }))
          dispatch(setSendButtonClicked(false))
          // dispatch(
          //   updateInboxNotif({
          //     show: false,
          //     name: 'unsendMessageNotification',
          //   }),
          // )
        })
        refetchMessages()
        clearMessageCompose()
        if (reloadMessage) {
          refetchMessages()
          dispatch(handleReloadMessage(false))
        }
      },
      onError: () => {
        dispatch(setIsLoading(false))
        renderMessageError(error)
      },
    })

    useEffect(() => {
      if (loading || smsLoading) {
        dispatch(setIsLoading(true))
      } else {
        dispatch(setIsLoading(false))
      }
    }, [loading, smsLoading])

    // useEffect(() => {
    //   if (
    //     selectedChannel.VendorID === '2' &&
    //     recipientPhone &&
    //     sendButtonClicked &&
    //     !unsendButtonClicked
    //   ) {
    //     const smsTimer = setTimeout(() => {
    //       if (!unsendButtonClicked) {
    //         createSMSMessage()
    //       }
    //     }, 5000)
    //     return () => clearTimeout(smsTimer)
    //   } else if (sendButtonClicked && !unsendButtonClicked) {
    //     const msgTimer = setTimeout(() => {
    //       if (!unsendButtonClicked) {
    //         createMessage()
    //       }
    //     }, 5000)
    //     return () => clearTimeout(msgTimer)
    //   } else {
    //     return
    //   }
    // }, [unsendButtonClicked, sendButtonClicked])

    const handleClickSend = async () => {
      // if (unsendButtonClicked) {
      //   dispatch(setUnsendButtonClicked(false))
      // }
      // dispatch(
      //   updateInboxNotif({
      //     show: true,
      //     name: 'unsendMessageNotification',
      //   }),
      // )
      handleScroll()
      if (selectedChannel.VendorID === '2' && recipientPhone) {
        createSMSMessage()
        batch(() => {
          dispatch(setEditorView('closed'))
          dispatch(setSendButtonClicked(true))
          dispatch(setSubmitMessageFlag(false))
        })
      } else {
        createMessage()
        batch(() => {
          dispatch(setEditorView('closed'))
          dispatch(setSendButtonClicked(true))
          dispatch(setSubmitMessageFlag(false))
        })
      }
    }

    useEffect(() => {
      if (submitMessageFlag || reloadMessage) {
        handleClickSend()
      }
    }, [submitMessageFlag, reloadMessage])

    const readyToSendMessage =
      (editorText.trim()?.length > 0 || uploadedFiles.length > 0) &&
      loadingAttachments === false &&
      selectedChannel?.AccessType == '2'

    return (
      <div
        id="buttons-right"
        className={`relative flex items-center justify-between ${
          !hasBusinessCenter && '-ml-3'
        } gap-x-2`}
      >
        {hasBusinessCenter ? (
          <>
            <TooltipWithCenteredArrow
              variant="top"
              title="Quick Actions"
              className="!max-w-[500px] !min-w-0"
              positioning={{ offsetX: '0px', offsetY: '3px' }}
              disableVisibleOnClick={true}
            >
              <button
                onClick={() => setIsOpen(!isOpen)}
                id="quickActionButton"
                type="button"
                className={`border-[1.5px] border-solid rounded-full  ${
                  isOpen
                    ? 'border-[#FF5000] rotate-45'
                    : 'rotate-0 border-thryv-gray-medium-500'
                } hover:border-[#FF5000]
        transition-border duration-300 p-2`}
              >
                <Icon variant="plus" type="solid" width={12} height={12} />
              </button>
            </TooltipWithCenteredArrow>
            <TransitionContainer
              ref={quickActionRef}
              className="border border-thryv-gray-light-400 bottom-[35px] right-[25px] overflow-y-auto"
              width={'210px'}
              show={isOpen}
              transformOrigin={'bottom right'}
              positioning="absolute"
            >
              <QuickActionModal />
            </TransitionContainer>
          </>
        ) : null}

        {/* SEND BUTTON */}
        {readyToSendMessage ? (
          <button
            type="button"
            data-testid="send-message"
            onClick={() => handleClickSend()}
            disabled={isLoading}
            className={`bg-[#CCCCCC] rounded-full transition-all duration-100 block ${
              readyToSendMessage && 'bg-[#FF5000]'
            }`}
          >
            <CommandCenterIcon
              variant="paperPlaneCircle"
              type="regular"
              width={43}
              height={43}
            />
          </button>
        ) : (
          <TooltipWithCenteredArrow
            variant="left"
            title={
              selectedChannel?.AccessType != '2'
                ? "You don't have write permissions for this channel"
                : "You can't send an empty message"
            }
            width="130px"
          >
            <button
              type="button"
              data-testid="send-message"
              disabled={true}
              className={`bg-[#CCCCCC] rounded-full transition-all duration-100 block`}
            >
              <CommandCenterIcon
                variant="paperPlaneCircle"
                type="regular"
                width={43}
                height={43}
              />
            </button>
          </TooltipWithCenteredArrow>
        )}
      </div>
    )
  },
)
