import usePhoneChannel from '../../hooks/use-phone-channel'
import { useSelector } from 'react-redux'
import { useFlags } from 'launchdarkly-react-client-sdk'

export const ButtonsData = () => {
  const [phoneChannel] = usePhoneChannel()
  const campaignStatus = useSelector(
    (state) => state.phoneRegistration.brand?.campaign_status,
  )
  const brandStatus = useSelector(
    (state) => state.phoneRegistration.brand?.brand_status,
  )
  const countryISO = useSelector((state) => state.countryCode.countryIso2)
  const { toggleTenDlc } = useFlags()
  const isActive = () => {
    const isActive =
      toggleTenDlc ||
      countryISO === 'AU' ||
      countryISO === 'CA' ||
      countryISO === 'NZ' ||
      brandStatus === 'PENDING' ||
      brandStatus === 'ACTIVE' ||
      brandStatus === 'VERIFIED' ||
      brandStatus === 'UNVERIFIED' ||
      campaignStatus === 'UPDATES_REQUIRED' ||
      campaignStatus === 'PENDING_REVIEW' ||
      campaignStatus === 'ACTIVE' ||
      phoneChannel?.ChannelAccessLevel === '2'

    return isActive ? 'Calls & SMS' : 'Calls'
  }

  const buttonsToRender = [
    {
      id: 0,
      iconName: 'gmail',
      ButtonText: 'Gmail',
      isConnected: false,
      iconType: 'solid',
      connectError: false,
      connectWarning: false,
      description:
        'Import Google contacts and labels to manage your Gmail account in Command Center.',
      channelsConnected: 0,
      channelProvider: 'nylas',
    },
    {
      id: 1,
      iconName: 'calls',
      ButtonText: isActive(),
      isConnected: false,
      iconType: 'regular',
      description:
        ' Send and receive voice calls or text messages from Command Center.',
      connectError: false,
      connectWarning: false,
      channelsConnected: 0,
      channelProvider: '',
    },
    {
      id: 2,
      iconName: 'video',
      ButtonText: 'Video',
      isConnected: false,
      iconType: 'solid',
      description: 'Host video calls using the generated links.',
      connectError: false,
      connectWarning: false,
      channelsConnected: 0,
      channelProvider: '',
    },
    {
      id: 3,
      iconName: 'facebookMessenger',
      ButtonText: 'Facebook',
      isConnected: false,
      iconType: 'solid',
      description:
        'Reply directly to customers with Facebook Messenger! Requires a Facebook business account.',
      connectError: false,
      connectWarning: false,
      channelsConnected: 0,
      channelProvider: 'sunshine',
    },
    {
      id: 4,
      iconName: 'instagram',
      ButtonText: 'Instagram',
      isConnected: false,
      iconType: 'solid',
      description:
        'Respond directly to users who message your business. An Instagram business account linked to a Facebook page is required.',
      connectError: false,
      connectWarning: false,
      channelsConnected: 0,
      channelProvider: 'sunshine',
    },
    {
      id: 5,
      iconName: 'webchat',
      ButtonText: 'WebChat',
      isConnected: false,
      iconType: 'solid',
      description:
        'Engage with your website visitors using real-time Webchat messages.',
      connectError: false,
      connectWarning: false,
      channelsConnected: 0,
      channelProvider: '',
    },
    {
      id: 6,
      iconName: 'icloud',
      ButtonText: 'iCloud',
      isConnected: false,
      iconType: 'solid',
      description: 'Manage messages from your iCloud email address.',
      connectError: false,
      connectWarning: false,
      channelsConnected: 0,
      channelProvider: 'nylas',
    },
    {
      id: 7,
      iconName: 'hotmail',
      ButtonText: 'Hotmail',
      isConnected: false,
      iconType: 'solid',
      description: 'Manage messages from your Hotmail email address.',
      connectError: false,
      connectWarning: false,
      channelsConnected: 0,
      channelProvider: 'nylas',
    },
    {
      id: 8,
      iconName: 'outlook',
      ButtonText: 'Outlook',
      isConnected: false,
      iconType: 'solid',
      description: 'Manage messages from your Outlook email address.',
      connectError: false,
      connectWarning: false,
      channelsConnected: 0,
      channelProvider: 'nylas',
    },
    {
      id: 9,
      iconName: 'microsoft365',
      ButtonText: 'Microsoft 365',
      isConnected: false,
      connectWarning: false,
      iconType: 'solid',
      description: 'Microsoft 365 for work or school accounts.',
    },
    {
      id: 10,
      iconName: 'imap',
      ButtonText: 'IMAP',
      isConnected: false,
      iconType: 'solid',
      description:
        'Add an email to your Command Center Inbox from any IMAP configurable address. ',
      connectError: false,
      connectWarning: false,
      channelsConnected: 0,
      channelProvider: '',
    },
    {
      id: 11,
      iconName: 'yahoo',
      ButtonText: 'Yahoo',
      isConnected: false,
      iconType: 'solid',
      description: 'Manage messages from your Yahoo email address.',
      connectError: false,
      connectWarning: false,
      channelsConnected: 0,
      channelProvider: 'nylas',
    },

    // {
    // id:12,
    //   iconName: 'aol',
    //   ButtonText: 'AOL',
    //   isConnected: false,
    //   iconType: 'solid',
    //   description: 'Manage messages from your AOL email account.',
    //   connectError: false,
    //   channelsConnected: 0,
    //   channelProvider: '',
    // },

    // {
    // id:13,
    //   iconName: 'googleMessenger',
    //   ButtonText: 'Google’s Business Messages',
    //   isConnected: false,
    //   iconType: 'solid',
    //   description:
    //     'Connect with Google Services like Google Search, Maps, and other Google services.',
    //   connectError: false,
    //   connectWarning: false,
    //   channelsConnected: 0,
    //   channelProvider: '',
    // },
    // {
    // id:14,
    //   iconName: 'ttl',
    //   ButtonText: 'TTL (Text to Landline)',
    //   isConnected: false,
    //   iconType: 'solid',
    //   description: 'Send and receive texts from your personal landline number.',
    //   connectError: false,
    //   connectWarning: false,
    //   channelsConnected: 0,
    //   channelProvider: '',
    // },
  ]

  return buttonsToRender
}

export default ButtonsData
