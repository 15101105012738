import { useState } from 'react'
import { useSelector } from 'react-redux'
import { CaretDown } from '@thryvlabs/maverick'

const DropDownButton = ({ children, className, onClick, active }) => {
  return (
    <button
      type="button"
      className={`${className} flex h-11 bg-thryv-cloud items-center pl-8 cursor-pointer`}
      onClick={onClick}
      aria-label="Toggle chats"
    >
      <CaretDown
        className={`fill-current duration-300 ${!active && '-rotate-90'}`}
        color="#808080"
      />
      <p className="text-p-sm font-open-sans font-normal text-[#4D4D4D] pl-6">
        {children}
      </p>
    </button>
  )
}

export const ConversationTable = ({
  className,
  children,
  isDropdown,
  typeOfConversations,
  hideTableFromDOM,
}) => {
  const { inboxAdvancedFilters } = useSelector((state) => state.inboxThreads)

  const [showTable, setShowTable] = useState(true)

  const toggleShow = () => setShowTable(!showTable)

  const hasNoRows =
    Array.isArray(children) &&
    (children.length === 0 || children.every((child) => child === null))
  const hasChannelFilterApplied = inboxAdvancedFilters.channels.length > 0

  const showNoMessagesText = showTable && hasNoRows && hasChannelFilterApplied

  return (
    <>
      {!hideTableFromDOM ? (
        <div className={className}>
          {isDropdown && (
            <DropDownButton
              onClick={toggleShow}
              className="w-full"
              active={showTable}
            >
              {typeOfConversations}
            </DropDownButton>
          )}

          <table className="flex flex-col" role="grid" aria-readonly="true">
            <thead style={{ height: 0, opacity: 0, pointerEvents: 'none' }}>
              <tr>
                <th
                  scope="col"
                  rowSpan="2"
                >{`${typeOfConversations} chat messages`}</th>
              </tr>
            </thead>

            <tbody className="flex flex-col gap-1 px-2 py-1">
              {showTable && children}

              {showNoMessagesText && (
                <div className="text-[14px] h-[66px] flex items-center justify-left rounded p-[8px] text-thryv-steel font-open-sans">
                  <p>No messages</p>
                </div>
              )}
            </tbody>
          </table>
        </div>
      ) : null}
    </>
  )
}
