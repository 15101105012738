import { useQuery } from '@apollo/client'
import { GET_STAFF } from '../../../../../graphql/queries/staff-queries'
import { STAFF_STATUS } from './data/staff-status'
import { useMediaQuery } from 'react-responsive'
import { useState } from 'react'
import CustomModal from '../../../../../components/common/CustomModal/Modal'
// Components
import { ParagraphText } from '@thryvlabs/maverick'
import { AddStaffCardWithModal, StaffMembersTable } from './components'
import {
  SecurityStaffSectionTitle,
  SecurityStaffSectionPopover,
} from '../../components'
import EditStaffDetailsModal from './components/edit-staff-details-modal/EditStaffDetailsModal'
import { useAuth0 } from '@auth0/auth0-react'
import { UpdateStaffConfirmationModal } from './components/edit-staff-details-modal/UpdateStaffConfirmationModal'

export const StaffSection = ({ setShowPasswordResetModal }) => {
  const { data: staffData } = useQuery(GET_STAFF, {
    fetchPolicy: 'cache-and-network',
  })
  const staffMembers =
    staffData?.staff.filter((member) => member.Status !== STAFF_STATUS.deleted) || []
  const isMobile = useMediaQuery({ maxWidth: 830 })

  const [openEditStaffModal, setOpenEditStaffModal] = useState(false)
  const [openUpdateStaffConfirmationModal, setOpenUpdateStaffConfirmationModal] =
    useState(false)
  const [staffMemberData, setStaffMemberData] = useState({})
  const [staffUpdatedName, setStaffUpdatedName] = useState({
    firstName: '',
    lastName: '',
  })
  const [updateStaffError, setUpdateStaffError] = useState({ error: false, msg: '' })

  const [updatedStaffData, setUpdatedStaffData] = useState({})
  const [updatedStaffPermissionsData, setUpdatedStaffPermissionsData] = useState([])
  const [newStaffPermissions, setNewStaffPermissions] = useState([])
  const sortedStaff = staffMembers.sort((a, b) =>
    a.FirstName.localeCompare(b.FirstName),
  )
  const { user } = useAuth0()
  const userId = user.cc_uid
  const { data: singleStaffData } = useQuery(GET_STAFF, {
    variables: {
      input: {
        CC_UserID: { eq: userId },
      },
    },
  })
  const userRole = singleStaffData?.staff[0]?.Role || user.cc_role

  const handleClick = (staff) => {
    // if role === admin or owner and userId === staff.CC_UserID then open modal else prevent modal from opening
    //     1. admin
    //     2. staff
    //     3. Owner
    if (userRole === '3') {
      setStaffMemberData({
        firstName: staff.FirstName,
        lastName: staff.LastName,
        phone: staff.Phone,
        email: staff.Email,
        role: staff.Role,
        cc_userId: staff.CC_UserID,
      })
      setOpenEditStaffModal(true)
    } else if (
      (userRole === '1' && staff.Role === '2') ||
      (staff.Role === '1' && userId === staff.CC_UserID)
    ) {
      setStaffMemberData({
        firstName: staff.FirstName,
        lastName: staff.LastName,
        phone: staff.Phone,
        role: staff.Role,
        cc_userId: staff.CC_UserID,
      })
      setOpenEditStaffModal(true)
    } else null
  }

  return (
    <SecurityStaffSectionPopover>
      {/* ----- STAFF SECTION HEADER ----- */}
      <div className={`flex mb-[22px] items-center`}>
        <SecurityStaffSectionTitle>Staff</SecurityStaffSectionTitle>
        <span className="pl-2 font-open-sans text-[#231F20]">
          ({staffMembers?.length} {staffMembers?.length > 1 ? 'Members' : 'Member'})
        </span>
        <span className="pl-2">{userRole !== '2' && <AddStaffCardWithModal />}</span>
      </div>

      <CustomModal
        isModalForm={true}
        hasCrossIcon={true}
        close={() => {
          setOpenEditStaffModal(false)
          setNewStaffPermissions([])
        }}
        isOpen={openEditStaffModal}
        setIsOpen={setOpenEditStaffModal}
        width={isMobile ? '325px' : '780px'}
      >
        <EditStaffDetailsModal
          setOpenEditStaffModal={setOpenEditStaffModal}
          staffMemberData={staffMemberData}
          setStaffUpdatedName={setStaffUpdatedName}
          setOpenUpdateStaffConfirmationModal={setOpenUpdateStaffConfirmationModal}
          setUpdateStaffError={setUpdateStaffError}
          setUpdatedStaffData={setUpdatedStaffData}
          setUpdatedStaffPermissionsData={setUpdatedStaffPermissionsData}
          newStaffPermissions={newStaffPermissions}
          setNewStaffPermissions={setNewStaffPermissions}
        />
      </CustomModal>
      <CustomModal
        isModalForm={true}
        hasCrossIcon={true}
        close={() => {
          setUpdateStaffError({ error: false, msg: '' })
          setOpenUpdateStaffConfirmationModal(false)
        }}
        isOpen={openUpdateStaffConfirmationModal}
        setIsOpen={setOpenUpdateStaffConfirmationModal}
        width={isMobile ? '325px' : '480px'}
        disableBackdropClick={true}
      >
        <UpdateStaffConfirmationModal
          setOpenUpdateStaffConfirmationModal={setOpenUpdateStaffConfirmationModal}
          staffUpdatedName={staffUpdatedName}
          updateStaffError={updateStaffError}
          setUpdateStaffError={setUpdateStaffError}
          updatedStaffData={updatedStaffData}
          updatedStaffPermissionsData={updatedStaffPermissionsData}
          setOpenEditStaffModal={setOpenEditStaffModal}
          setNewStaffPermissions={setNewStaffPermissions}
        />
      </CustomModal>
      {isMobile ? (
        sortedStaff.map((staff, idx) => {
          const handleStaffClassName = () => {
            if (userRole === '3') {
              return '!text-[#057AFF] underline'
            } else if (
              (userRole === '1' && staff.CC_UserID === userId) ||
              (userRole === '1' && staff.Role === '2')
            ) {
              return '!text-[#057AFF] underline'
            } else {
              return '!text-[#808080]'
            }
          }
          const staffClassNames = handleStaffClassName()

          return (
            <div
              className="flex justify-center my-3 border-b-[1px] pb-3 border-[#E3E6E8]"
              key={idx}
            >
              <ParagraphText
                key={idx}
                variant="lg"
                className={staffClassNames}
                onClick={() => handleClick(staff)}
              >
                {staff.FirstName} {staff.LastName}
              </ParagraphText>
            </div>
          )
        })
      ) : (
        <StaffMembersTable
          className="mb-[30px] mr-[25px]"
          staffMembers={staffMembers}
          auth0User={user}
          setOpenEditStaffModal={setOpenEditStaffModal}
          setStaffMemberData={setStaffMemberData}
          setShowPasswordResetModal={setShowPasswordResetModal}
        />
      )}
    </SecurityStaffSectionPopover>
  )
}
