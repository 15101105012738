import { useEffect, useState, useRef, forwardRef } from 'react'
import { Icon } from '@thryvlabs/maverick'
import { Droppable } from 'react-beautiful-dnd'
import EditMenuItem from './EditMenuItem'

const EditMenu = forwardRef(
  (
    {
      title,
      sideMenuItem,
      handleChange,
      setSectionIsEditing,
      setOpenEditMenuId,
      editedId,
      checkedItems,
      setEditedId,
      unCheckedItems,
      setAllowAnimate,
      outOfRangeNum,
      previousSectionOrder,
      setIsEditing,
      setOutOfRangeNum,
    },
    ref,
  ) => {
    const handleClose = () => {
      setIsEditing(false)
      setOpenEditMenuId(-1)
      setEditedId('')
      setSectionIsEditing('')
      setOutOfRangeNum(0)
      setAllowAnimate((current) => !current)
    }

    const [shouldAnimate, setShouldAnimate] = useState(false)
    const previouslyEdited = useRef(null)

    useEffect(() => {
      if (editedId) {
        setShouldAnimate(true)
        previouslyEdited.current = editedId
      }
    }, [editedId])
    return (
      <div>
        <div ref={ref}>
          <Droppable droppableId={'2'} type="SECTION">
            {(provided) => {
              return (
                <div
                  data-testid="edit-menu"
                  className="absolute w-[207px] p-[8px] flex flex-col justify-center items-start bg-white border-b border-[1px] border-[#E3E6E8] rounded-[4px] shadow-[0_4px_4px_rgba(0,0,0,0.25)]"
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                >
                  <div className="flex justify-space-between items-center py-[4px] px-[8px] gap-[16px] h-[30px] w-[191px]">
                    <span className="flex w-full font-primary font-semibold text-[12px] leading-[18px] justify-between text-[#4D4D4D]">
                      {title}
                    </span>

                    <Icon
                      data-testid="close-edit-menu"
                      onClick={handleClose}
                      height="12"
                      type="regular"
                      variant="x"
                      className="cursor-pointer"
                    />
                  </div>

                  {sideMenuItem.map((data, idx) => {
                    if (data.title === 'Campaigns') return
                    return (
                      <EditMenuItem
                        title={data.title}
                        visible={data.visible}
                        idx={idx}
                        key={data.id}
                        id={data.id}
                        data={data}
                        handleChange={handleChange}
                        editedId={editedId}
                        setEditedId={setEditedId}
                        checkedItems={checkedItems}
                        shouldAnimate={shouldAnimate}
                        unCheckedItems={unCheckedItems}
                        outOfRangeNum={outOfRangeNum}
                        previousSectionOrder={previousSectionOrder}
                      />
                    )
                  })}
                  {provided.placeholder}
                </div>
              )
            }}
          </Droppable>
        </div>
      </div>
    )
  },
)

export default EditMenu
