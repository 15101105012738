import { useState, useEffect } from 'react'
import Keypad from '../keypad/keypad'
import { useSelector } from 'react-redux'
import OutgoingCall from '../outgoing-call'
import formatContactData from '../../inbox/hooks/formatContactData'

const MobileDialpad = () => {
  const [phoneValue, _setPhoneValue] = useState('')
  const [callStream, setCallStream] = useState({ status: 'ongoing' })
  const [callAllowed, _setCallAllowed] = useState(false)
  const { onOutgoingCall, prepopulatedNumber, vonageClient } = useSelector(
    (state) => state.calls,
  )
  const KEYPAD_KEYS = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0', '*', '#']
  const [showKeypad, setShowKeypad] = useState(true)
  const _formatSearchValueToPhone = (value) => {
    if (!value) return value
    const phone = value.replace(/[^\d]/g, '')
    if (phone?.length < 4) return phone
    if (phone?.length < 7) return `(${phone.slice(0, 3)}) - ${phone.slice(3)}`
    // if (phone?.length === 10) setShowKeypad(true)

    return `(${phone.slice(0, 3)}) - ${phone.slice(3, 6)} - ${phone.slice(6, 10)}`
  }
  const selectedContact = useSelector((state) => state.contacts.selectedContact)
  const selectedContactPhoneNumber = selectedContact.phone
  useEffect(() => {
    if (prepopulatedNumber.length > 0) {
      _setPhoneValue(formatContactData(prepopulatedNumber))
    }
  }, [prepopulatedNumber])
  const _handleInputWithKeyboard = (e) => {
    e?.persist()
    const numbersOnly = e.target.value?.replace(/[^\d]/g, '')
    if (numbersOnly.length) {
      if (Number.isInteger(parseInt(numbersOnly))) {
        return _setPhoneValue(_formatSearchValueToPhone(numbersOnly))
      }
    }

    return _setPhoneValue(e.target.value)
  }

  const _handleAddInputFromKeypad = (key) =>
    _setPhoneValue((prevState) => _formatSearchValueToPhone(prevState + key))

  const _deleteInputFromKeypad = () =>
    _setPhoneValue((prevState) => prevState?.substring(0, prevState?.length - 1))

  const onChange = ({ e, key, type }) => {
    if (e) {
      _handleInputWithKeyboard(e)
      // dispatch(searchContactByNameOrEmail(e.target.value))
    }
    if (!e && key && KEYPAD_KEYS.includes(key) && type === 'key' && showKeypad) {
      _handleAddInputFromKeypad(key)
      // dispatch(searchContactByPhone(key))
    }
    if ((!key || !e) && type === 'delete') _deleteInputFromKeypad()
  }
  useEffect(() => {
    const isPhone = Number.isInteger(parseInt(phoneValue.replace(/[^\d]/g, '')))
    const length = phoneValue.length
    if (selectedContactPhoneNumber) {
      _setCallAllowed(true)
    } else {
      if (isPhone && length === 18) {
        _setCallAllowed(true)
      }

      if (isPhone && length < 18) {
        _setCallAllowed(false)
      }
    }
  }, [phoneValue.length, _setCallAllowed])
  useEffect(() => {
    if (onOutgoingCall) {
      setShowKeypad(false)
    }
  }, [onOutgoingCall])
  return (
    <div className="w-full h-full ">
      <div
        id="mobile-keypad"
        className="absolute mt-[100px] top-0 left-0 right-0 ml-auto mr-auto h-auto w-[334px] mx-2 bg-none rounded-t-[15px] flex flex-col items-center justify-start z-10 p-6 transition-all duration-200 ease-in-out"
      >
        {vonageClient.isInit && onOutgoingCall ? (
          <div id="test" className="flex items-center">
            <OutgoingCall
              phoneValue={phoneValue}
              _setPhoneValue={_setPhoneValue}
              callStream={callStream}
              setCallStream={setCallStream}
              isMobile={true}
              setShowKeypad={setShowKeypad}
              showKeypad={showKeypad}
            />
          </div>
        ) : null}
        {showKeypad ? (
          <>
            <span
              className="text-[22px] pb-[55px] font-semibold py-[6px] text-[#231F20] font-primary flex h-[36px] w-full items-center justify-center"
              onChange={() => {}}
              data-testid="phoneValue"
            >
              {phoneValue}
            </span>
            <Keypad
              onChange={onChange}
              setShowKeypad={setShowKeypad}
              callAllowed={callAllowed}
            />
          </>
        ) : null}
      </div>
    </div>
  )
}

export default MobileDialpad
