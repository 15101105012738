// Hooks
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useMediaQuery } from 'react-responsive'

// Components
import ContactDetailsPanel from './contact-details-panel/contact-details-panel'
import EditContactPanel from '../../right-panel/edit-contact-panel/edit-contact-panel'
import BackButton from '../../calls/contacts/components/back-button'

// Utils
import { FormContextProvider } from '../../right-panel/user-profile-and-activity/contexts/form-context'
import {
  ContactsContextProvider,
  useContactsContext,
} from '../../right-panel/user-profile-and-activity/contexts/contacts-context'

const EditPanel = ({ show, isMobile }) => {
  const { contact } = useContactsContext()

  return (
    <FormContextProvider contact={contact}>
      <EditContactPanel show={show} isMobile={isMobile} />
    </FormContextProvider>
  )
}

export const ContactInfoView = ({ connection, setMobileContactSideSheetOpen }) => {
  const isMobile = useMediaQuery({ maxWidth: 835 })

  // selectedPanel options: 'contact-details-panel', 'edit-contact-panel'
  const [selectedPanel, setSelectedPanel] = useState('contact-details-panel')
  const { selectedContact } = useSelector((state) => state.contacts)

  useEffect(() => {
    setSelectedPanel('contact-details-panel')
  }, [selectedContact])

  return (
    <ContactsContextProvider>
      <div className="h-full p-[30px]">
        {/* NOTE: This div is used to keep a consistent height regardless if there is a back button or not. */}
        {selectedPanel === 'contact-details-panel' && !isMobile && (
          <div className="h-[21px] w-full"></div>
        )}
        {selectedPanel === 'edit-contact-panel' && (
          <BackButton
            id="contact-panel-back-button"
            onClick={() => setSelectedPanel('contact-details-panel')}
          >
            Back
          </BackButton>
        )}
        <ContactDetailsPanel
          show={selectedPanel === 'contact-details-panel'}
          connection={connection}
          setMobileContactSideSheetOpen={setMobileContactSideSheetOpen}
          onEditButtonClick={() => setSelectedPanel('edit-contact-panel')}
        />
        {selectedPanel === 'edit-contact-panel' && (
          <EditPanel show={true} isMobile={isMobile} />
        )}
      </div>
    </ContactsContextProvider>
  )
}

export default ContactInfoView
