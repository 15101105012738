import { useState, useRef, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useThreads } from '../../../hooks/use-threads'
import { Icon } from '@thryvlabs/maverick'
import {
  setModalContent,
  setShowModal,
} from '../../common/modals/slices/connectChannelsSlice'
import { ChannelPill } from './channel-pill'

const FilterBar = () => {
  const dispatch = useDispatch()
  const scroll = useRef(null)
  const dropdownRef = useRef(null)
  const { updateThreads } = useThreads()

  const {
    inboxAdvancedFilters: { channels: channelFilters },
  } = useSelector((state) => state.inboxThreads)
  const { channelInfo: channels } = useSelector((state) => state.connectChannels)

  const [filter, setFilter] = useState(channelFilters || [])
  const [isOverflowing, setIsOverflowing] = useState(false)
  const [scrollEnd, setScrollEnd] = useState(false)
  const [scrollStart, setScrollStart] = useState(true)
  const [visible, setVisible] = useState(Array(channels.length).fill(false))
  const [hiddenLeft, setHiddenLeft] = useState(0)
  const [hiddenRight, setHiddenRight] = useState(0)
  const [dropdownOpen, setDropdownOpen] = useState(false)

  const toggleChannel = (channel) => {
    if (filter.includes(channel)) {
      setFilter(filter.filter((item) => item !== channel))
    } else {
      if (filter.length + 1 === channels.length) {
        setFilter([])
      } else {
        setFilter([...filter, channel])
      }
    }
  }

  const openModal = (modalNumber) => {
    dispatch(setModalContent(modalNumber))
    dispatch(setShowModal(true))
  }

  const slide = (direction) => {
    if (direction === 'left') {
      scroll.current.scrollBy({
        left: -250,
      })
    } else {
      scroll.current.scrollBy({
        left: 250,
      })
    }
  }

  const scrollCheck = () => {
    if (
      Math.floor(scroll.current.scrollWidth - scroll.current.scrollLeft) <=
      scroll.current.offsetWidth
    ) {
      setScrollEnd(true)
    } else {
      setScrollEnd(false)
    }
    if (scroll.current.scrollLeft === 0) {
      setScrollStart(true)
    } else {
      setScrollStart(false)
    }
  }

  useEffect(() => {
    if (scroll.current?.scrollWidth > scroll.current?.clientWidth) {
      setIsOverflowing(true)
    } else {
      setIsOverflowing(false)
    }
  }, [scroll.current])

  useEffect(() => {
    const selected = channels.map((channel) => filter.includes(channel.ChannelID))
    const selectedNotVisible = selected.map((selected, i) => {
      return selected && !visible[i]
    })
    const leftBound = visible.indexOf(true)
    const rightBound = visible.lastIndexOf(true)
    let leftHidden = 0
    let rightHidden = 0
    for (let i = 0; i < leftBound; i++) {
      if (selectedNotVisible[i]) {
        leftHidden++
      }
    }
    for (let i = rightBound + 1; i < visible.length; i++) {
      if (selectedNotVisible[i]) {
        rightHidden++
      }
    }
    setHiddenLeft(leftHidden)
    setHiddenRight(rightHidden)
  }, [visible, filter])

  useEffect(() => {
    updateThreads(filter)
  }, [filter])

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false)
      }
    }
    // Bind the event listener
    document.addEventListener('mouseup', handleClickOutside)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mouseup', handleClickOutside)
    }
  }, [dropdownRef])

  return (
    <div className="flex w-full h-full items-center gap-1 z-20 px-6 bg-white drop-shadow-[0_2px_4px_rgba(0,0,0,0.05)]">
      <AddManageChannelButtons
        openModal={openModal}
        dropdownRef={dropdownRef}
        dropdownOpen={dropdownOpen}
        setDropdownOpen={setDropdownOpen}
      />

      <div
        ref={scroll}
        onScroll={scrollCheck}
        className={`flex w-4/5 overflow-hidden scroll-smooth snap-x snap-mandatory items-center gap-1 p-6 py-20 pl-0 pointer-events-none ${
          hiddenLeft ? 'scroll-pl-16' : 'scroll-pl-10'
        }`}
      >
        {!scrollStart && (
          <LeftArrow onClick={() => slide('left')} hiddenLeft={hiddenLeft} />
        )}
        <AllChannelsPill
          onClick={() => setFilter([])}
          selected={filter.length === 0}
          channelCount={channels.length}
        />
        <div className="flex px-3 gap-3">
          {channels.map((channel, i) => {
            return (
              <ChannelPill
                key={i}
                i={i}
                channel={channel}
                onClick={() => toggleChannel(channel.ChannelID)}
                selected={filter.includes(channel.ChannelID)}
                setVisible={setVisible}
              />
            )
          })}
        </div>
      </div>
      {isOverflowing && !scrollEnd && (
        <>
          <div className="h-full w-16 pointer-events-none bg-gradient-to-l from-white -ml-16" />
          <RightArrow onClick={() => slide('right')} hiddenRight={hiddenRight} />
        </>
      )}
    </div>
  )
}

const AddManageChannelButtons = ({
  openModal,
  dropdownRef,
  dropdownOpen,
  setDropdownOpen,
}) => {
  const handleModal = (modal) => {
    setDropdownOpen(false)
    openModal(modal)
  }

  return (
    <div className="flex items-center gap-2 mr-6" ref={dropdownRef}>
      <div
        data-testid="add-channel-button"
        className="h-10 w-10 rounded-full bg-thryv-cloud flex justify-center items-center cursor-pointer flex-shrink-0 drop-shadow-[0_2px_4px_rgba(0,0,0,0.25)]"
        onClick={() => handleModal(1)}
      >
        <Icon variant="plus" type="regular" height="18px" width="18px" />
      </div>
      <Icon
        data-testid="channel-modal-dropdown"
        variant="chevronDown"
        type="solid"
        height="16px"
        width="16px"
        className="cursor-pointer"
        onClick={() => setDropdownOpen((prev) => !prev)}
      />
      {dropdownOpen && (
        <div className="flex flex-col absolute select-none cursor-pointer top-[95%] left-3 p-2 font-open-sans text-sm font-normal border border-barely-gray rounded w-[200px] bg-white">
          <div
            data-testid="connect-channel-selection"
            className="px-4 py-2 rounded hover:bg-thryv-gray-light-200"
            onClick={() => handleModal(1)}
          >
            Connect Channels
          </div>
          <div
            data-testid="manage-channel-selection"
            className="px-4 py-2 rounded hover:bg-thryv-gray-light-200"
            onClick={() => handleModal(2)}
          >
            Manage Channels
          </div>
        </div>
      )}
    </div>
  )
}

const AllChannelsPill = ({ onClick, selected, channelCount }) => {
  return (
    <div
      data-testid="all-channels-pill"
      className={` inline-flex h-10 px-3 py-2 justify-center items-center shrink-0 border snap-start gap-[6px] rounded-full select-none cursor-pointer pointer-events-auto ${
        selected
          ? 'bg-[#E6F2FF] border-utility/text-link-info'
          : 'border-barely-gray'
      }`}
      onClick={onClick}
    >
      <div className="font-semibold text-sm">All Channels ({channelCount})</div>
    </div>
  )
}

const RightArrow = ({ onClick, hiddenRight }) => (
  <div
    className={`flex justify-center items-center h-9 min-w-[36px] px-2 border border-white bg-white rounded-full cursor-pointer text-sm gap-3 select-none drop-shadow-[0_2px_4px_rgba(0,0,0,0.1)] hover:bg-barely-gray pointer-events-auto
      ${
        hiddenRight
          ? 'pl-3 text-utility/text-link-info hover:border-utility/text-link-info hover:bg-[#E6F2FF]'
          : ''
      }`}
    onClick={onClick}
  >
    {hiddenRight ? `+${hiddenRight}` : ''}
    <Icon
      variant="chevronRight"
      type="solid"
      height="12px"
      width="12px"
      color={hiddenRight ? '#057AFF' : 'black'}
    />
  </div>
)

const LeftArrow = ({ onClick, hiddenLeft }) => (
  <div className="absolute h-full flex items-center bg-white z-20">
    <div
      className={`flex justify-center items-center h-9 min-w-[36px] px-2 select-none bg-white rounded-full gap-3 text-sm cursor-pointer drop-shadow-[0_2px_4px_rgba(0,0,0,0.1)] border border-white hover:bg-barely-gray pointer-events-auto
        ${
          hiddenLeft
            ? 'pr-3 text-utility/text-link-info hover:border-utility/text-link-info hover:bg-[#E6F2FF]'
            : ''
        }`}
      onClick={onClick}
    >
      <Icon
        variant="chevronLeft"
        type="solid"
        height="12px"
        width="12px"
        color={hiddenLeft ? '#057AFF' : 'black'}
      />
      {hiddenLeft ? `+${hiddenLeft}` : ''}
    </div>
  </div>
)

export default FilterBar
