import { useMediaQuery } from 'react-responsive'
import { Accordion } from '@thryvlabs/maverick'
import { SubscriptionMobile } from './SubscriptionMobile'
import { SubscriptionWeb } from './SubscriptionWeb'

export const Subscriptions = ({ loading, activeSubscriptions, latestPayment }) => {
  const isMobile = useMediaQuery({ maxWidth: 835 })

  return (
    <div className="bg-[#FFFFFF] bg-[!red]">
      {!isMobile && (
        <SubscriptionWeb
          tableData={activeSubscriptions}
          loading={loading}
          activeSubscriptions={activeSubscriptions}
          latestPayment={latestPayment}
        />
      )}

      {isMobile && (
        <Accordion section={true} title="Active Subscription" defaultState={true}>
          <SubscriptionMobile
            tableData={activeSubscriptions}
            loading={loading}
            activeSubscriptions={activeSubscriptions}
            latestPayment={latestPayment}
          />
        </Accordion>
      )}
    </div>
  )
}
