import { useQuery, useApolloClient } from '@apollo/client'
import { useAuth0 } from '@auth0/auth0-react'
import { FETCH_PAYMENT_METHODS } from '../graphql/queries/settings-queries'
import { useState } from 'react'

export const usePaymentMethods = () => {
  const [paymentMethods, setPaymentMethods] = useState([])
  const client = useApolloClient()
  const { user } = useAuth0()

  const {
    loading,
    refetch: refetchPaymentMethods,
    networkStatus,
  } = useQuery(FETCH_PAYMENT_METHODS, {
    variables: {
      loc_id: user.businessId,
      refresh: false,
    },
    fetchPolicy: 'cache-first',
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      setPaymentMethods(data.queryPaymentMethodsByLocation.data)
    },
  })

  const refetchUpdatedPaymentData = async () => {
    const { data: freshData } = await refetchPaymentMethods({
      loc_id: user.businessId,
      refresh: true,
    })

    // Write to cache so fresh payment data (from refetch) is available while DB is updated (~10 mins)
    client.writeQuery({
      query: FETCH_PAYMENT_METHODS,
      variables: {
        loc_id: user.businessId,
        refresh: false,
      },
      data: freshData,
    })

    return freshData
  }

  return {
    paymentMethods,
    loading,
    networkStatus,
    refetchUpdatedPaymentData,
  }
}
