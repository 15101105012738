import './cancel-unavailable-modal.css'
import { useMediaQuery } from 'react-responsive'

// Components
import CustomModal from '../../CustomModal/Modal'
import { ThryvExclamationIcon } from '../../../command-center-icon/icons'
import { RegularX } from '@thryvlabs/maverick'
import { Button } from '@thryvlabs/maverick'

const CancelUnavailableModal = ({ isOpen, handleClose }) => {
  const isMobile = useMediaQuery({ maxWidth: 835 })

  return (
    <CustomModal
      isModalForm={true}
      heading=""
      hasCrossIcon={false}
      isOpen={isOpen}
      setIsOpen={handleClose}
      close={handleClose}
      width={isMobile ? '80%' : '434px'}
    >
      <div data-testid="cancel-unavailable-modal">
        <div className="flex justify-end">
          <Button
            data-testid="close-x-btn"
            variant="text"
            level={2}
            onClick={handleClose}
          >
            <RegularX type="regular" variant="x" height="18" fill="#A3A5A7" />
          </Button>
        </div>

        <div className="text-center">
          <div className="flex justify-center mb-[24px]">
            <ThryvExclamationIcon className="block" />
          </div>

          <div className="flex flex-col justify-center gap-6 items-center mb-[24px]">
            <p className="max-w-[380px] font-montserrat text-[22px] font-semibold leading-[30px]">
              Work in progress: <br /> Cancel Plan
            </p>

            <div className="max-w-[342px] w-full font-open-sans text-[14px] leading-5">
              <p>
                At this time, “Cancel Plan” is a work in progress.
                <br />
                We'll notify you via email and send you a notification
                <br />
                as soon as it's ready. We apologize for any
                <br /> inconvenience and appreciate your patience.
              </p>
              <br />
              <p>
                In the meantime, please contact{' '}
                <span className="text-[#057AFF]">support@thryv.com </span> to cancel
                your Command Center plan. Thank you for understanding!
              </p>
            </div>
          </div>

          <div className="flex items-end justify-center self-stretch">
            <div className="flex px-[24px] py-[9px] justify-center items-center gap-2 bg-[#FF5000] rounded">
              <Button
                data-testid="close-btn"
                variant="text"
                level={1}
                onClick={handleClose}
                className="font-open-sans text-[#FFF] font-semibold text-[13px]"
              >
                Close
              </Button>
            </div>
          </div>
        </div>
      </div>
    </CustomModal>
  )
}

export default CancelUnavailableModal
