import axios from 'axios'

const deregisterKnock = async (cc_uid, accessToken) => {
  const androidToken = localStorage.getItem('FCM_TOKEN')
  const iosToken = localStorage.getItem('APNS_TOKEN')

  const mobileTokenExists = Boolean(androidToken) || Boolean(iosToken)
  const accessTokenExists = Boolean(accessToken)
  if (!mobileTokenExists || !accessTokenExists) return

  const { VITE_NYLAS_HOST } = import.meta.env

  let deviceType = ''
  const MOBILE_TOKEN = androidToken || iosToken

  if (androidToken) deviceType = 'android'
  if (iosToken) deviceType = 'ios'

  const options = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  }
  const response = await axios.post(
    `${VITE_NYLAS_HOST}/remove_knock_user`,
    {
      token: MOBILE_TOKEN,
      user_id: cc_uid,
      device: deviceType,
    },
    options,
  )

  return response
}

export { deregisterKnock }
