import { useState, useEffect } from 'react'
import { Button, Checkbox } from '@thryvlabs/maverick'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Step2 from './Step2'
import Step1 from './Step1'
import { faAngleRight } from '@fortawesome/pro-regular-svg-icons'
import {
  GET_CALLS_MINUTES,
  GET_RECURLY_ACCOUNT_INFO,
  SET_ADD_RECURLY_MINUTES_QUANTITY,
  GET_LOCATION_BY_ID,
} from '../../../graphql'
import { useAuth0 } from '@auth0/auth0-react'
import { useMutation, useQuery } from '@apollo/client'
import { useDispatch, useSelector } from 'react-redux'
import { useRedux } from '../../../hooks'
import { setMinutesRemaining } from '../../../redux-toolkit'
import { secondsToMinutesConverter } from './SecondsToMinutesConverter'
import { LoadingSpinner } from '../../common/loading-spinner'
import moment from 'moment'

const AddMoreMinutes = ({ setOpenSuccessModal, setOpenAddMoreMinutes }) => {
  const dispatch = useDispatch()
  const [showLoader, setShowLoader] = useState()
  const [showPaymentLoader, setShowPaymentLoader] = useState()
  const [isDefaultCard, setIsDefaultCard] = useState(false)
  const [formData, setFormData] = useState()
  const [additionalMinutes, setAdditionalMinutes] = useState()
  const [monthlyMinuteSubscription, setMonthlyMinuteSubscription] = useState(0)
  const [isCardSelected, setIsCardSelected] = useState(false)
  const [charge] = useState((5).toFixed(2))
  const [australiaCharge] = useState((5 * 1.1).toFixed(2))
  const [taxes] = useState(0)
  const [formPart, setFormPart] = useState('Add Minutes') //will have other two other Payment  & Review
  const [{ minutesRemaining }] = useRedux('meetings')
  const [addPaymentMethodError, setAddPaymentMethodError] = useState(false)
  const [signupDate, setSignupDate] = useState('')
  const { countryIso2 } = useSelector((state) => state.countryCode)
  const { user } = useAuth0()
  const {
    data: callsMinutes,
    refetch: refetchCallsMinutes,
    loading: getCallMinutesLoading,
  } = useQuery(GET_CALLS_MINUTES, {
    fetchPolicy: 'network-only',
    onCompleted: () => {
      dispatch(
        setMinutesRemaining(
          secondsToMinutesConverter(
            callsMinutes.queryCallsMinutesByCCID.SecondsRemaining,
          ),
        ),
      )
    },
  })

  const { loading: signupDateLoading } = useQuery(GET_LOCATION_BY_ID, {
    variables: {
      location_id: user.businessId,
    },
    onCompleted: (data) => {
      setSignupDate(moment(data?.queryLocationByID?.CreatedAt).format('Do'))
    },
  })

  //get payment / billing information
  const {
    data: billingInfo,
    refetch: refetchBillingInfo,
    loading: getBillingInfoLoading,
  } = useQuery(GET_RECURLY_ACCOUNT_INFO, {
    variables: { CCID: user.cc_id },
    fetchPolicy: 'network-only',
    onCompleted: () => {
      if (billingInfo.queryRecurlyAccountInfo.billingInfo.paymentMethod.cardType) {
        setIsCardSelected(true)
      }
      setIsDefaultCard(
        billingInfo.queryRecurlyAccountInfo.billingInfo.primaryPaymentMethod,
      )
      setFormData(billingInfo.queryRecurlyAccountInfo)
    },
  })

  const [addMinutesRecurly, { loading: addMinutesLoading }] = useMutation(
    SET_ADD_RECURLY_MINUTES_QUANTITY,
    {
      onCompleted: async () => {
        refetchCallsMinutes()
        setShowLoader(false)
        setOpenSuccessModal(true)
        setOpenAddMoreMinutes(false)
      },
      onError: () => {
        setShowLoader(false)
      },
    },
  )

  const handleOnChange = () => setIsDefaultCard(!isDefaultCard)

  const submitAddMinutes = async function () {
    if (formData) {
      setShowLoader(true)
      await addMinutesRecurly({
        variables: {
          CCID: user.cc_id,
          quantity: monthlyMinuteSubscription,
        },
      })
    } else {
      setAddPaymentMethodError('Add Payment Method !')
    }
  }

  useEffect(() => {
    setAdditionalMinutes(monthlyMinuteSubscription * 100)
  }, [monthlyMinuteSubscription])

  const makingStepRequest = () => showLoader || showPaymentLoader

  return (
    <>
      <div className="grid gap-3 grid-cols-6">
        <div className="col-span-4 sm:col-span-6">
          <p className="text-[14px] font-normal ml-[1rem] mt-[1rem] mb-[1rem]">
            <span
              className={`${
                formPart === 'Add Minutes' ? 'text-[#FF5000]' : 'text-[#A3A5A7]'
              }`}
            >
              Add Minutes
            </span>
            <FontAwesomeIcon
              className={`ml-[1rem] mr-[8px] text-[#FAC02B] ${
                formPart === 'Add Minutes' ? 'text-[#FF5000]' : 'text-[#A3A5A7]'
              }`}
              icon={faAngleRight}
            />
            <span
              className={`${
                formPart === 'Payment' ? 'text-[#FF5000]' : 'text-[#A3A5A7]'
              }`}
            >
              Payment
            </span>
            <FontAwesomeIcon
              className={`ml-[1rem] mr-[8px] text-[#FAC02B] ${
                formPart === 'Payment' ? 'text-[#FF5000]' : 'text-[#A3A5A7]'
              }`}
              icon={faAngleRight}
            />
            <span className="text-[#A3A5A7]">Review</span>
          </p>
          {addMinutesLoading ||
          getCallMinutesLoading ||
          getBillingInfoLoading ||
          signupDateLoading ? (
            <div className="pl-[50%] h-full pt-[50%]">
              <LoadingSpinner />
            </div>
          ) : (
            formPart === 'Add Minutes' && (
              <Step1
                cardInfo={formData?.billingInfo?.paymentMethod}
                cardStatus={formData?.billingInfo?.state}
                setFormPart={setFormPart}
                monthlyMinuteSubscription={monthlyMinuteSubscription}
                isCardSelected={isCardSelected}
                timeRemaining={minutesRemaining}
                setMonthlyMinuteSubscription={setMonthlyMinuteSubscription}
                signupDate={signupDate}
              />
            )
          )}
          {formPart === 'Payment' && (
            <Step2
              formData={formData}
              setFormData={setFormData}
              setFormPart={setFormPart}
              primary_payment_method={isDefaultCard}
              setLoader={setShowPaymentLoader}
              refetchBillingInfo={refetchBillingInfo}
            />
          )}

          {addPaymentMethodError && formPart === 'Add Minutes' && (
            <p className="ml-[1rem] text-[12px] text-[red]">Add Payment Method!</p>
          )}
        </div>

        <div className="col-span-2 sm:col-span-6">
          <p className="text-[#4D4D4D] text-[14px] font-bold mt-[1rem] mb-[1rem]">
            Order Summary
          </p>
          <div className="bg-[#F8F9FB] p-[1rem] h-[90%] ">
            <p className="w-full flex justify-between mt-[1rem] mb-[1rem] text-[14px] text-[#808080] open-sans">
              <span>Additional Minutes</span> <span>{additionalMinutes} min</span>
            </p>
            <p className="w-full flex justify-between mt-[1rem] mb-[1rem] text-[14px] text-[#808080] open-sans">
              <span>Charge</span>{' '}
              <span>
                {countryIso2 === 'AU'
                  ? `$${australiaCharge}/100 Mins`
                  : `$${charge}/100 Mins`}
              </span>
            </p>
            <p className="w-full flex justify-between mt-[1rem] mb-[1rem] text-[14px] text-[#808080] open-sans">
              <span>Taxes</span> <span>${taxes}</span>
            </p>
            <hr className="w-full mb-[1rem] mt-[1rem] border-[1px] border-[#808080]" />
            <p className="w-full flex justify-between mt-[1rem] mb-[1rem] text-[14px] font-bold text-[#231F20] open-sans">
              <span>Total</span>{' '}
              <span>
                {countryIso2 === 'AU'
                  ? `${((additionalMinutes / 100) * australiaCharge + taxes).toFixed(
                      2,
                    )}/month`
                  : `${((additionalMinutes / 100) * charge + taxes).toFixed(
                      2,
                    )}/month`}
              </span>
            </p>
          </div>
        </div>
      </div>

      {formPart === 'Payment' && (
        <div className="flex items-center mt-[0.5rem] ml-[1rem]">
          <Checkbox
            className="font-normal text-[12px] open-sans text-[#231F20]"
            hasLabel
            id="HTML"
            name="HTML"
            isChecked={isDefaultCard}
            onChange={handleOnChange}
            label="Make this my default credit card"
          />
        </div>
      )}

      {formPart !== 'Add Minutes' && !makingStepRequest() && (
        <span
          type="button"
          onClick={() => setFormPart('Add Minutes')}
          className="w-[107px] h-[32px] mt-[2rem] text-[#808080] text-[13px] text-center cursor-pointer"
        >
          BACK
        </span>
      )}

      {formPart !== 'Add Minutes' && !makingStepRequest() && (
        <Button
          form="payment-form"
          type="submit"
          variant="primary"
          className="w-[107px] h-[32px] mt-[1rem] mr-[2%] float-right"
        >
          Save
        </Button>
      )}

      {formPart === 'Add Minutes' && !makingStepRequest() && (
        <Button
          disabled={!formData || monthlyMinuteSubscription <= 0}
          onClick={submitAddMinutes}
          variant="primary"
          className="w-[107px] h-[32px] mt-[1rem] mr-[2%] float-right"
        >
          Pay Now
        </Button>
      )}

      {makingStepRequest() && (
        <div className="mt-[1.5rem] mr-[2%] h-[32px] float-right">
          <LoadingSpinner widthAndHeight={28} />
        </div>
      )}
    </>
  )
}

export default AddMoreMinutes
