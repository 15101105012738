import { useMediaQuery } from 'react-responsive'

// Components
import { IoWarning } from 'react-icons/io5'
import { Button } from '@thryvlabs/maverick'
import { LoadingSpinner } from '../../../loading-spinner/loading-spinner'
import CloseButton from '../components/close-button'
import { EmphasisText, ParagraphText, ErrorText } from '../components/text'

const ConfirmCancellationModal = ({
  className,
  isVisible = true,
  planName = 'placeholder',
  handleClose,
  handleCancel,
  isLoading,
  showError = false,
}) => {
  const isMobile = useMediaQuery({ query: '(max-width: 640px)' })

  return (
    <div className={`${className} ${isVisible ? '' : 'hidden'}`}>
      <div className="flex justify-end">
        <CloseButton
          style={{ display: isMobile ? 'block' : 'none' }}
          onClick={handleClose}
          isDisabled={isLoading}
          aria-label="Close Modal"
          data-testid="modal-close-x-mobile"
        />
      </div>

      <div className={`${isMobile && '!justify-center'} flex justify-between`}>
        <p
          className={`${
            isMobile && 'text-center'
          } font-montserrat text-[22px] font-semibold leading-[30px]`}
        >
          Confirm your <EmphasisText>{planName}</EmphasisText> plan <br />
          cancellation
        </p>

        <CloseButton
          style={{ display: isMobile ? 'none' : 'block' }}
          onClick={handleClose}
          isDisabled={isLoading}
          aria-label="Close Modal"
          data-testid="modal-close-x-desktop"
        />
      </div>

      <ParagraphText className={`${isMobile && 'text-center'} mt-[24px]`}>
        You are requesting to cancel your <EmphasisText>{planName}</EmphasisText>{' '}
        plan. You&apos;ll lose benefits and features that come with the{' '}
        <EmphasisText>{planName}</EmphasisText> plan.
      </ParagraphText>

      <div className="bg-[#F8F9FB] rounded border-solid border-[#E3E6E8] border p-[16px] mt-[24px] flex gap-[8px]">
        <div>
          <IoWarning color="#FAC02B" size="18" />
        </div>

        <ParagraphText>
          You will lose access to your Command Center account.
        </ParagraphText>
      </div>

      <ErrorText
        className={`${
          showError ? 'opacity-100' : 'opacity-0'
        } transition-opacity duration-300`}
      >
        There was an error submitting your request, please try again.
      </ErrorText>

      <div className={`flex gap-[16px] justify-end ${isMobile && 'justify-center'}`}>
        <Button
          className={`${
            isLoading && 'opacity-50 pointer-events-none'
          } transition-opacity duration-300`}
          variant="text"
          level={2}
          textVariant="light"
          onClick={handleClose}
          data-testid="close-btn"
        >
          Close
        </Button>

        {isLoading && (
          <div className="w-[106.41px] h-[33.7px] flex justify-center">
            <LoadingSpinner widthAndHeight={33.7} />
          </div>
        )}

        {!isLoading && (
          <Button variant="primary" onClick={handleCancel}>
            Cancel Plan
          </Button>
        )}
      </div>
    </div>
  )
}

export default ConfirmCancellationModal
