import Voicemail from './Voicemail'
import DefaultScreen from '../../recent-and-voicemail-components/default-screens/DefaultScreenMain'
import { useSelector } from 'react-redux'
import { PaginatedList } from '@thryvlabs/maverick'

const Voicemails = ({
  setDisplayInfoCard,
  isMobile,
  setContactInfoView,
  phoneAccessType,
}) => {
  const voicemails = useSelector((state) => state.calls.voicemails)

  if (voicemails?.length)
    return (
      <>
        {phoneAccessType === '0' ? (
          <div className="relative top-[20%] text-center bg-white font-montserrat font-semibold text-lg">
            You do not have access to this page
          </div>
        ) : (
          <div className="h-full flex flex-col min-w-md:pt-[16px] p-[20px] md:p-[0px] md:bg-white font-montserrat">
            {voicemails?.length && (
              <PaginatedList list={voicemails}>
                <Voicemail
                  setContactInfoView={setContactInfoView}
                  isMobile={isMobile}
                  setDisplayInfoCard={setDisplayInfoCard}
                />
              </PaginatedList>
            )}
          </div>
        )}
      </>
    )

  return (
    <>
      {phoneAccessType === '0' ? (
        <div className="relative top-[20%] text-center bg-white font-montserrat font-semibold text-lg">
          You do not have access to this page
        </div>
      ) : (
        <DefaultScreen tabSelected={'voicemail'} isMobile={isMobile} />
      )}
    </>
  )
}

export default Voicemails
