import { ApolloLink, HttpLink } from '@apollo/client'

const { VITE_BASE_API_URL: uri } = import.meta.env

const getApolloLink = (accessToken) => {
  const httpLink = new HttpLink({
    uri: (operation) => `${uri}?${operation.operationName}`,
  })

  const link = new ApolloLink((operation, forward) => {
    operation.setContext({
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
    })

    return forward(operation)
  })
  return link.concat(httpLink)
}

export default getApolloLink
