export const StatusPill = ({ className, children, color }) => {
  return (
    <div
      data-testid="status-pill"
      className={`${className} rounded-full w-max px-2 py-1 text-xs text-white`}
      style={{ backgroundColor: color }}
    >
      {children}
    </div>
  )
}
