import { useState, createContext, useEffect, useContext } from 'react'
import { FETCH_CONTACT_BY_THREAD } from '../../../../graphql'
import { useLazyQuery } from '@apollo/client'
import { useSelector } from 'react-redux'
import { selectedThreadSelector } from '../../../inbox/slices/inboxThreads.selectors'
import commandCenterPhoneValidator from '../../../../utils/commandCenterPhoneValidator'
import validator from 'validator'

/**
 *
 * Good video on how Context works in React - https://www.youtube.com/watch?v=HYKDUF8X3qI
 *
 */

const getName = (string, countryCode) => {
  const isPhone = commandCenterPhoneValidator(string, countryCode).isValid()
  const isEmail = validator.isEmail(string)

  if (!isPhone && !isEmail) return string
  else return ''
}

const ContactsContext = createContext(undefined)

const THRYV_SUPPORT_EMAIL = 'support@thryv.com'
const THRYV_SUPPORT_EMAIL_2 = 'support'

const parseContactBody = (contact) => {
  if (typeof contact?.body !== 'string') return {}

  let body
  body = JSON.parse(contact?.body)

  return body
}

const parseFullName = (firstName, lastName) => {
  const fullName = /\d{10}/.test(firstName)
    ? 'Unknown'
    : firstName?.length > 0 && lastName?.length === 0
      ? firstName
      : firstName?.length > 0 && lastName?.length > 0
        ? firstName + ' ' + lastName
        : 'Unknown'

  return fullName
}

export const ContactsContextProvider = ({ children }) => {
  const { countryIso2: countryCode } = useSelector((state) => state.countryCode)
  const { selectedThreadId } = useSelector(selectedThreadSelector)

  const [isThryvSupportEmail, setIsThryvSupportEmail] = useState(false)
  // const [refetchContact, setRefetchContect] = useState(() => {})
  const [contact, setContact] = useState({
    fullName: '',
    sk1: '',
    contactsk1: '',
    phone: '', // AKA: contactsk3
    email: '', // AKA: contactsk2
    firstName: '',
    lastName: '',
    pictureUrl: '',
  })

  const [getContactByThread, { data: contactData, refetch: refetchContact }] =
    useLazyQuery(FETCH_CONTACT_BY_THREAD, {
      onCompleted: () => {
        initContact()
        verifyIsThryvSupportEmail()
      },
    })

  // useEffect(() => {
  //   if (refetch) setRefetchContect(() => refetch)
  // }, [refetch])

  const initContact = () => {
    const currentContact = contactData?.queryContactsByThread?.items?.at(0)

    const body = parseContactBody(currentContact)
    const firstName = body ? getName(body.given_name, countryCode) : ''
    const lastName = body ? getName(body.surname, countryCode) : ''
    const fullName = parseFullName(firstName, lastName)
    const newBody = body ? body : {}

    const newContact = {
      sk1: currentContact.sk1,
      contactsk1: currentContact.contactsk1 || '',
      email: currentContact.contactsk2 || '',
      firstName: firstName,
      lastName: lastName,
      fullName: fullName,
      pictureUrl: body.picture_url,
      phone: currentContact.contactsk3 || '',
      body: newBody,
    }

    setContact(newContact)
  }

  const verifyIsThryvSupportEmail = () => {
    const currentContact = contactData?.queryContactsByThread?.items?.at(0)

    const formattedEmail = currentContact.contactsk2?.trim().toLowerCase()
    const body = parseContactBody(currentContact)
    const firstName = body ? body.given_name : ''
    const lastName = body ? body.surname : ''
    const fullName = parseFullName(firstName, lastName)

    const isThryvSupport =
      formattedEmail === THRYV_SUPPORT_EMAIL ||
      formattedEmail === THRYV_SUPPORT_EMAIL_2 ||
      fullName.trim().toLowerCase() === THRYV_SUPPORT_EMAIL ||
      fullName.trim().toLowerCase() === THRYV_SUPPORT_EMAIL_2

    if (isThryvSupport) setIsThryvSupportEmail(true)
    else setIsThryvSupportEmail(false)
  }

  const updateExistingContact = () => {
    const threadId = selectedThreadId?.split('#')[0]
    getContactByThread({
      variables: {
        threadid: threadId,
      },
    })
  }

  // Updates Contact Information once a new thread is selected
  useEffect(() => updateExistingContact(), [selectedThreadId])

  return (
    <ContactsContext.Provider
      value={{ contact, isThryvSupportEmail, refetchContact }}
    >
      {children}
    </ContactsContext.Provider>
  )
}

export const useContactsContext = () => {
  // Data in this case is { user, isThryvSupportEmail }
  const data = useContext(ContactsContext)

  if (data === undefined) {
    throw new Error(
      'useContactsContext must be used with a ContactsContextProvider. Please provide neccessary components in this provider.',
    )
  }

  return data
}
