import { ModalWrapper } from '@thryvlabs/maverick'
import FreemiumStaffUpdate from './FreemiumStaffUpdate'
import { useEffect, useState } from 'react'
import { GET_LOCATION_BY_ID } from '../../../../graphql'
import { useQuery } from '@apollo/client'
import { useAuth0 } from '@auth0/auth0-react'
import moment from 'moment'

const StaffSeatChangeModal = ({ planName, loading }) => {
  const [showModal, setShowModal] = useState(true)
  const [isBefore, setIsBefore] = useState(false)
  const { user } = useAuth0()

  useQuery(GET_LOCATION_BY_ID, {
    variables: {
      location_id: user.businessId,
    },
    onCompleted: (data) => {
      const compareDate = moment('2024-11-26', 'YYYY-MM-DD')
      const parsedTimestamp = moment(
        data?.queryLocationByID?.CreatedAt,
        'YYYY-MM-DD',
      )
      if (moment(parsedTimestamp).isBefore(compareDate)) {
        setIsBefore(true)
      }
    },
  })

  useEffect(() => {
    let seenStaffUpdateModal = localStorage.getItem('seenStaffUpdateModal')
    setShowModal(!seenStaffUpdateModal)
  }, [])

  const handleCloseStaffChangeModal = () => {
    localStorage.setItem('seenStaffUpdateModal', true)
    setShowModal(false)
  }

  if (loading) {
    return null
  }

  if (planName === 'Command Center Basic') {
    return (
      <ModalWrapper
        show={showModal && isBefore}
        close={handleCloseStaffChangeModal}
        disableBackdropClick={false}
      >
        <FreemiumStaffUpdate
          handleCloseStaffChangeModal={handleCloseStaffChangeModal}
        />
      </ModalWrapper>
    )
  } else {
    return null
  }
}

export default StaffSeatChangeModal
