import { Button } from '@thryvlabs/maverick'
import { ParagraphText, EmphasisText } from '../components/text'

const CancelPaidContent = ({
  className,
  planName,
  nextPaymentDate,
  onDoneClick,
}) => {
  return (
    <div className={className}>
      <ParagraphText className="mt-[40px] m-auto mb-[58px]">
        Your Command Center <EmphasisText>{planName}</EmphasisText> plan has been
        scheduled to cancel, effective{' '}
        <span className="font-bold">{nextPaymentDate}</span>. After that date, your
        account will be deleted.
      </ParagraphText>

      <Button variant="primary" onClick={onDoneClick} className="h-[32px]">
        Done
      </Button>
    </div>
  )
}

export default CancelPaidContent
