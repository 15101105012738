import { useDispatch } from 'react-redux'
import { setShowModal } from '../common/modals/slices/connectChannelsSlice'
import {
  LogoContainer,
  ParagraphTextStyledSmaller,
  ParagraphTextStyled,
  BottomBtn,
  CommandCenterIconResponsive,
} from './WelcomeScreen.styles'
import { setModalContent } from '../common/modals/slices/connectChannelsSlice'

const BottomModalButton = ({ IconName, ButtonText, IconType }) => {
  const dispatch = useDispatch()

  return (
    <BottomBtn
      data-testid="bottom-btn"
      onClick={() => {
        dispatch(setShowModal(true))
        dispatch(setModalContent(1))
      }}
      variant="text"
      level={2}
    >
      <LogoContainer className="cursor-pointer">
        <CommandCenterIconResponsive type={IconType} variant={IconName} />
      </LogoContainer>
      {IconName === 'ttl' || IconName === 'googleMessenger' ? (
        <ParagraphTextStyled
          data-testid="p-text-style"
          className="cursor-pointer"
          variant="reg"
        >
          {' '}
          {ButtonText}{' '}
        </ParagraphTextStyled>
      ) : (
        <ParagraphTextStyledSmaller
          data-testid="p-text-style-small"
          variant="reg"
          className="cursor-pointer"
        >
          {ButtonText}{' '}
        </ParagraphTextStyledSmaller>
      )}
    </BottomBtn>
  )
}

export default BottomModalButton
