import { useAuth0 } from '@auth0/auth0-react'
import { convertRoleIntegerToText } from '../../../../../../../utils/convert-role-integer-to-text'
import { useStaffSort } from './hooks/use-staff-sort'
import { useActiveChannels } from '../../../../../../../hooks/use-active-channels'
import { createStaffListForPagination } from './utils/createStaffListForPagination'
import { useRef, useEffect, useState } from 'react'
// Components
import { TableMenu, PaginatedList } from '@thryvlabs/maverick'
import { StaffTableHead, StaffTableRow } from './components'
import { useMediaQuery } from 'react-responsive'

export const StaffMembersTable = ({
  className,
  auth0User,
  staffMembers,
  setOpenEditStaffModal,
  setStaffMemberData,
  setShowPasswordResetModal,
}) => {
  const commandCenterId = auth0User.cc_id
  const businessId = auth0User.businessId
  const { user } = useAuth0()
  const isTablet = useMediaQuery({ maxWidth: 1250 })
  const listSelectRef = useRef(null)
  const [itemsPerPage, setItemsPerPage] = useState(10)
  const masterUser = {
    id: auth0User.cc_uid,
    role: convertRoleIntegerToText(
      (staffMembers.length &&
        staffMembers.filter((member) => auth0User.cc_uid === member.CC_UserID)[0]
          ?.Role) ||
        user.cc_role,
    ),
  }

  const { staff, toggleSort, selectedSort } = useStaffSort(staffMembers)

  const connectedChannels = useActiveChannels()

  const staffListForPagination = createStaffListForPagination({
    commandCenterId,
    businessId,
    staffList: staff,
    masterUser,
    connectedChannels,
  })

  const renderActionsTableHead = () => {
    return (
      <StaffTableHead
        removeChevron
        className={`${
          !isTablet ? 'justify-end' : 'justify-center'
        } pointer-events-none w-[12%] min-w-[140px]`}
      >
        Actions
      </StaffTableHead>
    )
  }

  const handleClick = (event) => {
    if (!listSelectRef.current) return
    const target = event.target.closest('li, button')

    if (target) {
      const item = target.textContent.trim()
      if (['10', '25', '50'].includes(item)) {
        const value = parseInt(item, 10)
        setItemsPerPage(value)
      }
    }
  }

  useEffect(() => {
    listSelectRef.current.addEventListener('click', handleClick)

    return () => {
      listSelectRef.current.removeEventListener('click', handleClick)
    }
  }, [])

  return (
    <div className={`${className} ${isTablet && 'overflow-x-scroll'}`}>
      <TableMenu className="w-full sm:overflow-x-auto">
        <div className="flex items-center w-full sm:min-w-fit [&>button]:border-b [&>button]:border-thryv-gray-light-400">
          <StaffTableHead
            className={'w-[15%] min-w-[125px]'}
            onClick={() => toggleSort('name')}
            removeChevron={selectedSort.name !== 'name'}
          >
            Name
          </StaffTableHead>
          {isTablet && renderActionsTableHead()}
          <StaffTableHead
            className={'w-[15%] min-w-[105px]'}
            onClick={() => toggleSort('channels')}
            removeChevron={selectedSort.name !== 'channels'}
          >
            Channels
          </StaffTableHead>
          <StaffTableHead
            className={'w-[25%] min-w-[225px]'}
            onClick={() => toggleSort('email')}
            removeChevron={selectedSort.name !== 'email'}
          >
            Email
          </StaffTableHead>
          <StaffTableHead
            className={'w-[15%] min-w-[125px]'}
            onClick={() => toggleSort('phone')}
            removeChevron={selectedSort.name !== 'phone'}
          >
            Number
          </StaffTableHead>
          <StaffTableHead
            className={'w-[10%] min-w-[75px]'}
            onClick={() => toggleSort('role')}
            removeChevron={selectedSort.name !== 'role'}
          >
            Role
          </StaffTableHead>
          <StaffTableHead
            className={'w-[13%] min-w-[140px]'}
            onClick={() => toggleSort('status')}
            removeChevron={selectedSort.name !== 'status'}
          >
            Status
          </StaffTableHead>
          {!isTablet && renderActionsTableHead()}
        </div>
      </TableMenu>
      <div className="relative z-10" ref={listSelectRef} onClick={handleClick}>
        <PaginatedList list={staffListForPagination} itemsPerPage={itemsPerPage}>
          <StaffTableRow
            totalData={staffListForPagination.length}
            auth0User={auth0User}
            setOpenEditStaffModal={setOpenEditStaffModal}
            setStaffMemberData={setStaffMemberData}
            isTablet={isTablet}
            setShowPasswordResetModal={setShowPasswordResetModal}
          />
        </PaginatedList>
      </div>
    </div>
  )
}
