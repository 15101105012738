import { Button } from '@thryvlabs/maverick'
import { ParagraphText, EmphasisText } from '../components/text'
import { LoadingSpinner } from '../../../loading-spinner/loading-spinner'

const CancelFreemiumContent = ({
  className,
  planName,
  onNevermindClick,
  onCancelClick,
  isLoading,
}) => {
  return (
    <div className={className}>
      <ParagraphText className="mt-[40px] m-auto mb-[58px]">
        Once your Command Center <EmphasisText>{planName}</EmphasisText> is
        cancelled, you will be logged out from any active sessions across all
        devices. This will take place immediately.
      </ParagraphText>

      <div className="flex justify-center gap-[8px]">
        <Button
          variant="secondary"
          className={`h-[32px] transition-opacity duration-300 ${
            isLoading ? 'opacity-50 pointer-events-none' : ''
          }`}
          onClick={onNevermindClick}
        >
          Nevermind
        </Button>

        {!isLoading && (
          <Button variant="primary" onClick={onCancelClick} className={'h-[32px]'}>
            Cancel my account
          </Button>
        )}

        {isLoading && (
          <div className="max-w-[130px] w-full h-[32px] flex justify-center">
            <LoadingSpinner widthAndHeight={33.7} />
          </div>
        )}
      </div>
    </div>
  )
}

export default CancelFreemiumContent
