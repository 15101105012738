import { useNavigate } from 'react-router-dom'
import { useEffect, useContext, useState } from 'react'
import { useRedux } from '@hooks'
import { AmplifyClientContext } from '../../components/authentication/amplify-context'
import { SUBSCRIPTION_FOR_MEETING_END } from '../../graphql/mutations/vonage-mutations'
import { useAuth0 } from '@auth0/auth0-react'

import { Buffer } from 'buffer'
window.Buffer = Buffer

const MeetingEnd = () => {
  const navigate = useNavigate()

  const [{ room }] = useRedux('meetings')
  const { room_data } = room

  const { getAccessTokenSilently } = useAuth0()
  const [meetingEndedSubs, setMeetingEndedSubs] = useState()

  const client = useContext(AmplifyClientContext)

  const subscribeOnMeetingEnd = async () => {
    const accessToken = await getAccessTokenSilently()
    const subscription = client
      ?.graphql({
        query: SUBSCRIPTION_FOR_MEETING_END,
        authToken: `Bearer ${accessToken}`,
        variables: {
          RoomID: room_data?.RoomID,
        },
      })
      .subscribe({
        next: () => {
          navigate('/calls')
        },
      })
    setMeetingEndedSubs(subscription)
  }

  useEffect(() => {
    subscribeOnMeetingEnd()
    return () => {
      meetingEndedSubs?.unsubscribe()
    }
  }, [])

  return <></>
}

const Meetings = () => {
  const [{ room }] = useRedux('meetings')
  const { room_data } = room

  return (
    <>
      <MeetingEnd />
      <iframe
        src={room_data?.host_url}
        allow="camera *;microphone *;display-capture *"
        title="Embedded Meeting"
        style={{
          height: '100%',
          width: '100%',
        }}
      />
    </>
  )
}

export default Meetings
