import { NavButtonWithMenu } from './common'
import { ChannelActivationButton } from './common'
import { FilterButton } from './common/channel-filter/filter-button'
import { useFlags } from 'launchdarkly-react-client-sdk'

export const InboxMobileNavBar = ({ className, disableMenu }) => {
  const { channelFilterBar } = useFlags()

  return (
    <div
      className={`${className} h-14 bg-thryv-white-300 w-full flex items-center justify-between px-4 z-20`}
    >
      <NavButtonWithMenu disableMenu={disableMenu} />
      <div className="flex gap-2">
        <ChannelActivationButton />
        {channelFilterBar && <FilterButton />}
      </div>
    </div>
  )
}
