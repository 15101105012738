import { useState } from 'react'
import { useDispatch } from 'react-redux'
import {
  setModalContent,
  setShowModal,
  setDisableBackdropClick,
} from '../../../common/modals/slices/connectChannelsSlice'
import { ReferralIcon } from '../../../command-center-icon/icons/icons'

export const ReferralButton = () => {
  const [isReferralHovered, setIsReferralHovered] = useState(false)
  const dispatch = useDispatch()

  const openReferralModal = () => {
    dispatch(setDisableBackdropClick(false))
    dispatch(setModalContent(5))
    dispatch(setShowModal(true))
  }

  return (
    <div
      className="border rounded-full aspect-square h-[30px] flex items-center justify-center cursor-pointer hover:bg-[#151B22] transition-colors duration-200"
      onClick={openReferralModal}
      onMouseEnter={() => setIsReferralHovered(true)}
      onMouseLeave={() => setIsReferralHovered(false)}
    >
      <ReferralIcon
        height="20px"
        width="20px"
        color={isReferralHovered ? 'white' : '#151B22'}
        className="transition-all"
        id="impact-referral-btn"
      />
    </div>
  )
}
