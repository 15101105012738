import { ParagraphText, Button, ModalWrapper } from '@thryvlabs/maverick'
import {
  SecurityStaffSectionPopover,
  SecurityStaffSectionTitle,
} from '../../components'
import ResetSelfPasswordModal from './ResetSelfPasswordModal'

export const SecuritySection = ({
  className,
  showPasswordResetModal,
  setShowPasswordResetModal,
}) => {
  return (
    <SecurityStaffSectionPopover className={`${className}`}>
      <SecurityStaffSectionTitle className="mb-2">
        Security
      </SecurityStaffSectionTitle>

      <ParagraphText
        variant="reg"
        color="thryv-steel"
        className="!font-medium leading-[22px] !font-[Montserrat] mb-4"
      >
        Receive an email to change your password.
      </ParagraphText>

      <Button
        variant="primary"
        buttonType="outlined"
        onClick={() => setShowPasswordResetModal(true)}
      >
        Reset Password
      </Button>

      <ModalWrapper
        show={showPasswordResetModal}
        close={() => setShowPasswordResetModal(false)}
      >
        <ResetSelfPasswordModal close={() => setShowPasswordResetModal(false)} />
      </ModalWrapper>
    </SecurityStaffSectionPopover>
  )
}
