import { PlaceholderImage } from '../../conversation-components/conversation-row/profile-image'

export const ConversationSkeleton = () => {
  return (
    <div className="h-[66px] flex flex-row border-b-2 pl-3 border-[#F1F1F5]">
      <div className="flex items-center justify-center">
        <PlaceholderImage firstName={''} lastName={''} />
      </div>
      <div className="w-full ml-2 pt-2">
        <div className="w-5/6 bg-thryv-gray-medium-200 h-3 rounded-md mt-2" />
        <div className="flex">
          <div className="w-5/6 bg-thryv-gray-medium-200 h-3 rounded-md mt-2" />
        </div>
      </div>
    </div>
  )
}
