import styled, { css } from 'styled-components'
import { Button, ParagraphText, RegularInfoCircle } from '@thryvlabs/maverick'
import { CommandCenterIcon } from '../../../command-center-icon'

export const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
`
export const HeaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: start;
  flex-direction: column;
  width: 100%;
  gap: 22px;
  padding: '1rem 0';
`
export const HeaderDiv = styled.div`
  display: flex;
  gap: 2rem;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  @media (max-width: 640px) {
    /* border: 1px solid red; */
    gap: 1rem;
    justify-content: space-around;
  }
`
export const RoundedBtn = styled(Button)`
  border-radius: 56px !important;
  @media (max-width: 640px) {
    font-size: 16px;
    margin-right: 1rem;
  }
`
export const SuccessBannerContainer = styled.div`
  text-align: left;
  padding: 8px 12px;
  width: 100%;
  background: #e8f6f3;
  border: 1px solid #8acfc2;
  border-radius: 4px;
  display: flex;
  gap: 20px 10px;
  @media (max-width: 640px) {
    width: 100%;
    text-align: center;
  }
`
export const SuccessText = styled(ParagraphText)`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
`
export const SuccessClose = styled(Button)`
  cursor: pointer;
  width: 24px;
  height: 24px;
`

export const ErrorBannerContainer = styled(SuccessBannerContainer)`
  background: #fff9ea;
  border-color: #fddf95;
  width: 37.6rem;
`
export const ChannelsContainer = styled.div`
  display: grid;
  grid-template-columns: auto auto auto auto;
  margin-top: 2%;
  gap: 34px 28px;
  @media (max-width: 640px) {
    display: flex;
    flex-wrap: wrap;
  }
`

export const ConnectCardContainer = styled.div`
  height: 150px;
  width: 130px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  position: relative;
  cursor: pointer;
  margin: 0 auto;

  ${({ isWelcomeModal }) => {
    if (isWelcomeModal) {
      return css`
        @media (max-width: 640px) {
          height: 164px;
          width: 140px;
        }
      `
    }

    return css`
      @media (max-width: 640px) {
        width: 100%;
        height: 68px;
        flex-direction: row;
        align-items: flex-start;
        padding: 0px 20px;
      }
      @media (max-width: 280px) {
        width: 100%;
        padding: 0px 10px;
      }
    `
  }}
`

export const CommandCenterIconResponsive = styled(CommandCenterIcon)`
  width: ${({ width }) => width || '34px'};
  height: ${({ height }) => height || '34px'};

  ${({ isWelcomeModal }) => {
    if (isWelcomeModal) {
      return css`
        @media (max-width: 640px) {
          width: 48px;
          height: 48px;
        }
      `
    }

    return css`
      @media (max-width: 640px) {
        width: ${({ width }) => width || '32px'};
        height: ${({ height }) => height || '32px'};
      }
    `
  }}
`

export const InfoIcon = styled(RegularInfoCircle)`
  width: 16px;
  opacity: 0;
  transition: opacity 300ms ease-in;
  pointer-events: none;
  ${ConnectCardContainer}:hover & {
    opacity: 1;
    pointer-events: all;
  }
`

export const ConnectCardLogo = styled.div``

export const BlueBar = styled.div`
  margin-top: -1rem;
  border-radius: 4px 4px 0px 0px;
  width: 100%;
  height: 24px;
  color: white;
  padding: 0.5rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 640px) {
    // width: 300px;
    height: 20px;
    position: absolute;
    left: 0;
  }
`
export const BlueBannerText = styled(ParagraphText)`
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #ffffff;
  padding: 0.5rem 0;
  @media (max-width: 640px) {
    font-size: 14px;
  }
`
export const ConnectCardTop = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  height: 100%;
  padding-top: 0.5rem;
  width: 100%;

  ${({ isWelcomeModal }) => {
    if (isWelcomeModal) {
      return css`
        @media (max-width: 640px) {
        }
      `
    }

    return css`
      @media (max-width: 640px) {
        gap: 16px;
        flex-direction: row;
        justify-content: start;
      }
    `
  }}
`
export const ConnectCardText = styled(ParagraphText)`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #4d4d4d;
  text-align: center;
  @media (max-width: 640px) {
    font-size: 14px;
    line-height: 20px;
  }
  @media (max-width: 280px) {
    font-size: 11px;
    line-height: 20px;
  }
`

export const ConnectCardSmallerText = styled(ParagraphText)`
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 600;
  line-height: 15px;
  letter-spacing: 0px;
  color: #4d4d4d;
  text-align: center;
  @media (max-width: 640px) {
    font-size: 14px;
    // display: flex;
    // flex-wrap: wrap;
    width: 65px;
  }
  @media (max-width: 280px) {
    font-size: 10px;
    width: 35px;
  }
`
export const ResponsiveButton = styled(Button)`
  @media (max-width: 640px) {
    font-size: 12px;
    display: flex;
    padding: 1rem 1.5rem;
    height: 30px;
    align-items: center;
    @media (max-width: 280px) {
      font-size: 11px;
      display: flex;
      padding: 10px 10px;
      width: 65px;
      height: 25px;
      align-items: center;
      text-align: center;
    }
  }
`
