import styled from 'styled-components'
import { Button } from '@thryvlabs/maverick'

const StyledDropdownButton = styled(Button)`
  & > div > div:last-child {
    margin-top: 4px;
    z-index: 99;
    width: auto;

    & > * > * {
      text-align: left;
    }
  }
`

export const StyledStaffDropdownButton = ({ ...rest }) => (
  <div>
    <StyledDropdownButton data-testid="security-staff-actions" {...rest} />
  </div>
)
