import ChannelsIcon from '../../channels-icon/channels-icon'
import { Icon } from '@thryvlabs/maverick'
import { useEffect, useRef, useState } from 'react'
import formatContactData from '../hooks/formatContactData'

const CHANNEL_TYPES = {
  gmail: 'Gmail',
  outlook: 'Outlook',
  yahoo: 'Yahoo mail',
  instagram: 'Instagram',
  facebook: 'Facebook',
  hotmail: 'Hotmail',
  webchat: 'Webchat',
  microsoft365: 'Microsoft 365',
  phone: 'Phone',
  video: 'Video',
  icloud: 'iCloud',
  imap: 'IMAP',
}

export const ChannelPill = ({ channel, onClick, selected, i, setVisible }) => {
  const ref = useRef()
  const [showTooltip, setShowTooltip] = useState(false)

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setVisible((prev) => {
          const newIsVisible = [...prev]
          newIsVisible[i] = entry.isIntersecting
          return newIsVisible
        })
      },
      {
        threshold: 0.5,
      },
    )
    observer.observe(ref.current)

    return () => {
      observer.disconnect()
    }
  }, [ref, setVisible])

  return (
    <div
      data-testid={`channel-pill-${channel.Provider}`}
      ref={ref}
      className={`relative inline-flex h-10 px-3 py-2 justify-center items-center shrink-0 border gap-[6px] rounded-full select-none cursor-pointer hover:bg-[#E6F2FF] snap-start pointer-events-auto ${
        selected
          ? 'bg-[#E6F2FF] border-utility/text-link-info'
          : 'border-barely-gray'
      }`}
      onClick={onClick}
      onMouseEnter={() => setShowTooltip(true)}
      onMouseLeave={() => setShowTooltip(false)}
    >
      <ChannelsIcon height="20px" width="20px" variant={channel.Provider} />
      <div className=" font-semibold text-sm max-w-[23ch] truncate">
        {channel.Nickname || CHANNEL_TYPES[channel.Provider] || channel.Provider}
      </div>
      {selected && (
        <Icon
          data-testid="clear-channel-filter"
          variant="plus"
          type="regular"
          height="12px"
          width="12px"
          className="rotate-45"
          color="#057AFF"
        />
      )}
      {showTooltip && (
        <Tooltip
          text={
            channel.Provider === 'video' ? 'Video Channel' : channel.AccountUserValue
          }
        />
      )}
    </div>
  )
}

const Tooltip = ({ text }) => {
  return (
    <div className="text-white absolute font-semibold bg-[#231F20] rounded-lg p-2 text-h9 z-30 bottom-12 whitespace-nowrap">
      {formatContactData(text)}
      <div className="absolute bg-[#231F20] top-full -mt-[4px] right-[45%] z-20 w-2 h-2 rotate-45" />
    </div>
  )
}
