import { useAuth0 } from '@auth0/auth0-react'
import { SecuritySection, StaffSection } from './sections'
import { MyChannelsSection } from './sections/my-channels-section/MyChannelsSection'
import { useQuery } from '@apollo/client'
import { useState } from 'react'
import { GET_STAFF } from '../../../graphql'

export const SecurityStaffTab = () => {
  const { user } = useAuth0()
  const { data: staffData } = useQuery(GET_STAFF, {
    fetchPolicy: 'cache-and-network',
  })
  const [showPasswordResetModal, setShowPasswordResetModal] = useState(false)

  return (
    <div className="flex flex-col space-y-6 max-lg:min-w-[800px] sm:max-w-auto sm:m-[15px]">
      <SecuritySection
        showPasswordResetModal={showPasswordResetModal}
        setShowPasswordResetModal={setShowPasswordResetModal}
      />

      <StaffSection setShowPasswordResetModal={setShowPasswordResetModal} />

      <MyChannelsSection auth0User={user} staffData={staffData} />
    </div>
  )
}
