import { useEffect, useRef, useState } from 'react'
import ActionButtonDropDown from './ActionButtonDropDownWeb'

// Hooks
import { useAuth0 } from '@auth0/auth0-react'
import { useClickOutside } from '../../../hooks/use-click-outside'
import { useMediaQuery } from 'react-responsive'

// REFERENCE: https://www.notion.so/thryvlabs/MySQL-SQL-Integer-Meanings-84742993f45e4faa8c817476be6b00de?pvs=4#86ad5a601a9b4d19ae8fa144c43d194e
const COMMAND_CENTER_ROLES = {
  ADMIN: 1,
  STAFF: 2,
  OWNER: 3,
}

export const CustomButtonGroup = ({ onSelectionClick }) => {
  const { user } = useAuth0()

  const isMobile = useMediaQuery({ maxWidth: 640 })
  const dropDownRef = useRef(null)

  const [selectableItems, setSelectableItems] = useState(['Upgrade', 'Cancel'])
  const [showDropDown, setShowDropDown] = useState(false)

  const initSelectableItems = () => {
    const userIsNotOwner = user.cc_role !== COMMAND_CENTER_ROLES.OWNER
    if (userIsNotOwner) {
      setSelectableItems(['Upgrade'])
      return
    }
  }

  useEffect(() => {
    initSelectableItems()
  }, [])

  useClickOutside(() => {
    setShowDropDown(false)
  }, dropDownRef)

  return (
    <ActionButtonDropDown
      ref={dropDownRef}
      isMobile={isMobile}
      items={selectableItems}
      show={showDropDown}
      setShow={setShowDropDown}
      onChange={(selectedItem) => {
        onSelectionClick(selectedItem)
      }}
    />
  )
}
