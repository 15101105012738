import {
  ConnectCardContainer,
  ConnectCardTop,
  ConnectCardLogo,
  BlueBar,
  BlueBannerText,
  InfoIcon,
  ConnectCardText,
  ConnectCardSmallerText,
  CommandCenterIconResponsive,
  ResponsiveButton,
} from './ModalComponent.styles'
import { Button, Icon } from '@thryvlabs/maverick'
import { TooltipWithCenteredArrow } from '../../tooltip-with-centered-arrow'
import {
  handleConnectChannel,
  setModalContent,
} from '../slices/connectChannelsSlice'
import { useDispatch, useSelector } from 'react-redux'
import { useMediaQuery } from 'react-responsive'
import { useAuth0 } from '@auth0/auth0-react'
import { useState, useEffect } from 'react'
import { LoadingSpinner } from '../../loading-spinner'
import popularStar from '@assets/popularStar.png'

const getBorderColor = ({ connectError, connectWarning }) => {
  if (connectError) return 'border-[#DF2A2A]'
  if (connectWarning) return 'border-[#FAC02B]'

  return 'border-thryv-white-300'
}

const getBarColor = ({ connectError, connectWarning }) => {
  if (connectError) return 'bg-[#DF2A2A]'
  if (connectWarning) return 'bg-[#FAC02B]'

  return 'bg-[#5378fc]'
}

const ConnectCard = ({
  iconName,
  ButtonText,
  isConnected,
  clickedID,
  manageChannel,
  iconType,
  connectError,
  channelsConnected,
  description,
  setClickedID,
  connectWarning,
  isPopular,
  isWelcomeModal,
  id,
}) => {
  const dispatch = useDispatch()
  const isMobile = useMediaQuery({ maxWidth: 640 })
  const [token, setToken] = useState()
  const [isClicked, setIsClicked] = useState(false)
  const { channelInfo, maxChannelsAllowed } = useSelector(
    (state) => state.connectChannels,
  )
  const { user, getAccessTokenSilently } = useAuth0()

  useEffect(() => {
    const getToken = async () => {
      try {
        const accessToken = await getAccessTokenSilently()

        setToken(accessToken)
      } catch (err) {
        //
      }
    }

    getToken()
  }, [getAccessTokenSilently])

  const handleClickConnect = () => {
    if (channelInfo?.length >= maxChannelsAllowed) {
      // eslint-disable-next-line no-console
      console.log(
        'cant connect more channels',
        channelInfo?.length,
        maxChannelsAllowed,
      )
      dispatch(setModalContent(4))
      return
    }
    if (isConnected && (iconName === 'calls' || iconName === 'video')) {
      return
    } else {
      const ccuid = user.cc_uid
      dispatch(handleConnectChannel({ clickedID, token, ccuid }))
      setClickedID(clickedID)
      setIsClicked(true)
    }
  }

  useEffect(() => {
    setIsClicked(false)
  }, [])

  const ManageBtn = () => (
    <div className="flex gap-2 items-center mt-auto justify-center mb-3  w-full sm:justify-end">
      <ResponsiveButton variant="form" level={2} onClick={() => manageChannel()}>
        Manage
      </ResponsiveButton>
      {iconName === 'calls' || iconName === 'video' ? null : (
        <button
          className="bg-thryv-white-300 hover:bg-thryv-gray-medium-100 transition duration-200 ease-in-out rounded-full p-2  sm:mr-0"
          onClick={() => {
            handleClickConnect()
          }}
        >
          {isClicked && clickedID === id ? (
            <LoadingSpinner widthAndHeight={'13px'} className="flex" />
          ) : (
            <Icon
              variant="plus"
              fill="#4D4D4D"
              type="solid"
              className="w-[12px] h-[12px] xx-sm:w-[8px] xx-sm:h-[8px]"
            />
          )}
        </button>
      )}
    </div>
  )

  return (
    <>
      <ConnectCardContainer
        isWelcomeModal={isWelcomeModal}
        className={`border ${getBorderColor({ connectError, connectWarning })}`}
      >
        {isMobile ? (
          <div className="absolute top-1 right-1">
            <TooltipWithCenteredArrow
              variant="top"
              width="200px"
              title={description}
              positioning={{ offsetX: '-125px' }}
            >
              <InfoIcon />
            </TooltipWithCenteredArrow>
          </div>
        ) : (
          <div className="absolute top-2 right-2">
            <TooltipWithCenteredArrow
              variant="top"
              width="200px"
              title={description}
            >
              <InfoIcon />
            </TooltipWithCenteredArrow>
          </div>
        )}
        {isConnected ? (
          <BlueBar className={`${getBarColor({ connectError, connectWarning })}`}>
            <BlueBannerText variant="reg">
              {channelsConnected} connected
            </BlueBannerText>
          </BlueBar>
        ) : null}
        {isPopular && (
          <BlueBar className="bg-thryv-orange-500">
            <BlueBannerText variant="reg">
              <img
                src={popularStar}
                width={14}
                height={14}
                className="inline pb-1 mr-1"
              />{' '}
              Popular
            </BlueBannerText>
          </BlueBar>
        )}
        <ConnectCardTop
          isWelcomeModal={isWelcomeModal}
          onClick={() => {
            isConnected ? manageChannel() : handleClickConnect()
          }}
        >
          <ConnectCardLogo>
            <CommandCenterIconResponsive
              type={iconType}
              variant={iconName}
              isWelcomeModal={isWelcomeModal}
            />
          </ConnectCardLogo>

          {iconName === 'ttl' || iconName === 'googleMessenger' ? (
            <ConnectCardSmallerText variant="reg">
              {ButtonText}
            </ConnectCardSmallerText>
          ) : (
            <ConnectCardText variant="reg"> {ButtonText}</ConnectCardText>
          )}
        </ConnectCardTop>
        {iconName === 'ttl' || iconName === 'googleMessenger' ? (
          <Button
            variant="text"
            className="text-sm ml-1 mt-auto mb-5 pointer-events-none"
            level={1}
          >
            Coming Soon
          </Button>
        ) : isConnected ? (
          <ManageBtn />
        ) : (
          <ResponsiveButton
            name={iconName}
            onClick={() => {
              handleClickConnect()
            }}
            className="mt-auto mb-3"
            variant="form"
            level={2}
          >
            {isClicked && clickedID === id ? (
              <LoadingSpinner widthAndHeight={'18px'} className="flex mx-4" />
            ) : (
              'Connect'
            )}
          </ResponsiveButton>
        )}
      </ConnectCardContainer>
    </>
  )
}

export default ConnectCard
