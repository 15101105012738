import { useState, useEffect, useRef } from 'react'
import { useClickOutside } from '../../../../../hooks'
import { useSelector, useDispatch } from 'react-redux'
import { setSearch } from '../../../slices/inbox-search/inbox-search-slice'
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
  useLocation,
} from 'react-router-dom'
import {
  setSelectedThread,
  setBulkEditBtnClicked,
  setBulkSelectAllBtnChecked,
  setBulkSelectedThreadIds,
  setBulkSelectedThreadSk1s,
  setShowMarkAsMenu,
} from '../../../slices/inboxThreadsSlice'
import { useLazyQuery } from '@apollo/client'
import { GET_CLOUDSEARCH_CONTACTS_SUGGESTIONS } from '../../../../../graphql/queries'
import { useAuth0 } from '@auth0/auth0-react'
import { addRecentSearchToCookies } from '../utils'
import RoundedActionButton from '../../../common/rounded-action-button'
// Components
import { SearchBar, SortButton } from '../common'
import { ContactsAndSearchesMenu, FilterMenu, SortMenu } from './menus'
import { Button, Checkbox } from '@thryvlabs/maverick'

export const DesktopInboxMenuSearchBar = ({ className, onChange }) => {
  const dispatch = useDispatch()
  const { user: auth0User } = useAuth0()

  const [getCloudsearchContacts] = useLazyQuery(GET_CLOUDSEARCH_CONTACTS_SUGGESTIONS)
  const [cloudsearchContacts, setCloudsearchContacts] = useState([])
  const [topSuggestion, setTopSuggestion] = useState('')

  const { search } = useSelector((state) => state.inboxSearch)
  const [searchParams] = useSearchParams()
  const { pathname } = useLocation()
  const navigate = useNavigate()

  const {
    selectedThread,
    bulkEditBtnClicked,
    bulkSelectAllBtnChecked,
    inboxThreadIds,
    inboxThreadSk1s,
    inboxPinnedThreadSk1s,
    inboxPinnedThreadIds,
    bulkSelectedThreadCount,
  } = useSelector((state) => state.inboxThreads)

  const searchInputRef = useRef(null)
  const searchContainerRef = useRef(null)

  const [focus, setFocus] = useState(false)
  // const [highlighted, setHighlighted] = useState(-1)

  const [visibleMenu, setVisibleMenu] = useState('') // contacts | filter | sort

  useClickOutside(() => {
    updateVisibleMenu('')
  }, searchContainerRef)

  useEffect(() => {
    const searchTermInUrl = searchParams.get('search')
    if (searchTermInUrl) updateSearch(searchTermInUrl)
  }, [])

  const updateSearch = (newSearch) => {
    if (!newSearch.length && !search.length) return
    dispatch(setSearch(newSearch))
  }

  const updateCloudsearchContacts = async (newSearch) => {
    const { data: contactsData } = await getCloudsearchContacts({
      variables: {
        search: newSearch || search,
        ranks: {
          full_name: 6,
          // contactsk2: 5,
          // contactsk3: 0,
        },
        limit: 4,
      },
    })

    const contacts = contactsData?.contactSuggestions

    setCloudsearchContacts(contacts)
    updateTopSuggestion(contacts)
    return contacts
  }

  const updateTopSuggestion = (contacts) => {
    if (contacts === undefined || !contacts.length || !focus) {
      setTopSuggestion('')
      return
    }

    const topContact = contacts[0]
    const { full_name, contactsk2, sk1 } = topContact
    const lowercaseSearch = search.toLowerCase()

    let bestSuggestion = ''

    if (full_name?.toLowerCase().startsWith(lowercaseSearch)) {
      bestSuggestion = full_name
    } else if (contactsk2?.toLowerCase().startsWith(lowercaseSearch)) {
      bestSuggestion = contactsk2
    } else if (sk1?.toLowerCase().startsWith(search)) {
      bestSuggestion = sk1
    }

    setTopSuggestion(bestSuggestion)
  }

  const handleSearchChange = async (e) => {
    let value
    if (typeof e === 'string') value = e
    else {
      value = e.target.value
      e.stopPropagation()
    }
    if (value.length === 0) setVisibleMenu('')
    else updateVisibleMenu('search')

    onChange && onChange(e)

    updateSearch(value)
    updateCloudsearchContacts(value)
  }

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      updateCloudsearchContacts()
    }, 500)

    return () => clearTimeout(delayDebounceFn)
  }, [search])

  useEffect(() => {
    // Removes / Adds suggestive text in search bar depending if the search bar is focused or not.
    if (!focus) updateTopSuggestion()
    else updateTopSuggestion(cloudsearchContacts)
  }, [focus])

  const handleKeyDown = (e) => {
    const keyClickHandlers = {
      enter: () => {
        updateVisibleMenu()
        addRecentSearchToCookies(search)
        navigateToGlobalSearch()
        updateTopSuggestion()
      },
      tab: () => {
        e.preventDefault()
        updateSearch(search + topSuggestion.slice(search.length))
      },
    }

    const { key } = e

    switch (key) {
      case 'Tab':
        if (search.length < topSuggestion.length) keyClickHandlers.tab()
        break
      case 'Enter':
        keyClickHandlers.enter()
        break
      default:
        break
    }
  }

  const navigateToGlobalSearch = (inputSearch) => {
    let newSearch = inputSearch || search
    let pathToNavigateTo = '/inbox/global-search/messages'
    if (pathname.indexOf('/inbox/global-search') === 0) pathToNavigateTo = pathname

    navigate({
      pathname: pathToNavigateTo,
      search: createSearchParams({ search: newSearch }).toString(),
    })
  }

  const navigateToThread = (threadId, threadTitle) => {
    dispatch(
      setSelectedThread({
        id: threadId,
        name: threadTitle,
      }),
    )

    let pathToNavigateTo = '/inbox'

    navigate({
      pathname: pathToNavigateTo,
      search: createSearchParams({ search: threadTitle }).toString(),
    })
  }

  const updateVisibleMenu = (menuName, isToggleable) => {
    if (isToggleable) {
      switch (menuName) {
        case 'filter':
          if (visibleMenu === 'filter') setVisibleMenu('')
          else setVisibleMenu('filter')
          setFocus(false)
          break
        case 'sort':
          if (visibleMenu === 'sort') setVisibleMenu('')
          else setVisibleMenu('sort')
          setFocus(false)
          break
        default:
          setFocus(false)
          setVisibleMenu('')
      }
    } else {
      switch (menuName) {
        case 'search':
          setFocus(true)
          setVisibleMenu('search')
          break
        case 'filter':
          setFocus(false)
          setVisibleMenu('filter')
          break
        case 'sort':
          setFocus(false)
          setVisibleMenu('sort')
          break
        default:
          setFocus(false)
          setVisibleMenu('')
      }
    }
  }

  const focusOnSearchInput = () => {
    setFocus(true)
    searchInputRef.current.focus()
  }

  const handleSelectAll = () => {
    if (bulkSelectedThreadCount > 0) {
      dispatch(setBulkSelectAllBtnChecked(!bulkSelectAllBtnChecked))
      dispatch(
        setBulkSelectedThreadIds([...inboxThreadIds, ...inboxPinnedThreadIds]),
      )
      dispatch(
        setBulkSelectedThreadSk1s([...inboxThreadSk1s, ...inboxPinnedThreadSk1s]),
      )
    } else {
      dispatch(setBulkSelectAllBtnChecked(!bulkSelectAllBtnChecked))

      dispatch(
        setBulkSelectedThreadIds([...inboxThreadIds, ...inboxPinnedThreadIds]),
      )
      dispatch(
        setBulkSelectedThreadSk1s([...inboxThreadSk1s, ...inboxPinnedThreadSk1s]),
      )
    }
  }

  const handleCancel = () => {
    dispatch(setBulkEditBtnClicked(false))
    dispatch(setBulkSelectAllBtnChecked(false))
    dispatch(setBulkSelectedThreadIds([]))
    dispatch(setBulkSelectedThreadSk1s([]))
    dispatch(setShowMarkAsMenu(false))
  }

  return (
    <>
      <div className={`${className} flex`} ref={searchContainerRef}>
        <div className="relative grow">
          {bulkEditBtnClicked ? null : (
            <div onClick={focusOnSearchInput}>
              <SearchBar
                search={search}
                setSearch={updateSearch}
                ref={searchInputRef}
                placeholderText={search + topSuggestion.slice(search.length)}
                isFocused={focus}
                filterButton={{
                  onClick: () => updateVisibleMenu('filter', true),
                  isActive: visibleMenu === 'filter',
                }}
                searchArea={{
                  onClick: () => updateVisibleMenu('search'),
                }}
                onClearClick={() => {
                  focusOnSearchInput()
                  if (selectedThread.id) {
                    navigateToThread(selectedThread.id, selectedThread.name)
                  }
                }}
                onChange={(e) => {
                  handleSearchChange(e)
                }}
                onKeyDown={handleKeyDown}
              />
            </div>
          )}
          {bulkEditBtnClicked ? null : (
            <ContactsAndSearchesMenu
              className="mt-px"
              show={visibleMenu === 'search'}
              topContactsOptions={cloudsearchContacts}
              textToEmphasize={search}
              onSearchAllClick={() => {
                updateVisibleMenu()
                addRecentSearchToCookies(search)
                navigateToGlobalSearch()
                updateTopSuggestion()
              }}
              onRecentSearchClick={(newSearch) => {
                handleSearchChange(newSearch)
                updateVisibleMenu()
                navigateToGlobalSearch(newSearch)
              }}
              onContactRowClick={(contact) => {
                const { email, fullName, threadId } = contact
                const threadTitle = fullName || email

                handleSearchChange(threadTitle)
                updateVisibleMenu()
                navigateToThread(threadId, threadTitle)
              }}
            />
          )}
          {bulkEditBtnClicked ? null : (
            <>
              {' '}
              <FilterMenu
                auth0User={auth0User}
                className="mt-px"
                show={visibleMenu === 'filter'}
                onSearchClick={() => updateVisibleMenu('')}
              />
              <SortMenu
                className="mt-px"
                show={visibleMenu === 'sort'}
                onSelectionClick={() => updateVisibleMenu('')}
              />
            </>
          )}
        </div>

        <div
          className={`${
            bulkEditBtnClicked
              ? 'flex w-full justify-between mt-[12px] mb-[15px]'
              : 'ml-2 grow-0 flex justify-center items-center gap-2'
          } `}
        >
          {bulkEditBtnClicked ? (
            <>
              <div className="flex justify-center items-center gap-2">
                {' '}
                <Checkbox
                  hasLabel
                  name="Select All"
                  isChecked={bulkSelectAllBtnChecked}
                  onChange={handleSelectAll}
                />
                <span
                  className="font-semibold font-open-sans text-utility/text-link-info text-[14px] cursor-pointer"
                  onClick={handleSelectAll}
                >
                  {bulkSelectAllBtnChecked ? 'Unselect All' : 'Select All'}
                </span>{' '}
              </div>

              <Button
                data-testid="cancel-btn"
                variant="text"
                level={1}
                onClick={handleCancel}
              >
                Cancel
              </Button>
            </>
          ) : (
            <>
              {' '}
              <SortButton
                onClick={() => {
                  handleSearchChange('')
                  updateVisibleMenu('sort', true)
                }}
                active={visibleMenu === 'sort'}
              />
              <RoundedActionButton
                dataTestId="bulk-edit-btn"
                icon={{
                  variant: 'pen',
                  type: 'regular',
                  width: '18',
                  height: '18',
                  color: '#808080',
                  hoverColor: '#4D4D4D',
                }}
                isMavIcon
                onClick={() => dispatch(setBulkEditBtnClicked(true))}
              />{' '}
            </>
          )}
        </div>
      </div>
    </>
  )
}
