import { CardTitle, ParagraphText } from '@thryvlabs/maverick'
import { CommandCenterIcon } from '../command-center-icon'
import { Link } from 'react-router-dom'
import { useRef } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { useAuth0 } from '@auth0/auth0-react'
import { updateAccessInfo } from '../../utils/waffle-switcher-helpers'
import { useSelector } from 'react-redux'

export const WaffleMenu = ({ openWaffleMenu, setOpenWaffleMenu }) => {
  const waffleMenuRef = useRef(null)
  const { user } = useAuth0()
  const centersForUsers = JSON.parse(user.cc_metadata.Values)
  const userAccessToCenters = centersForUsers.data.navigationPreferences.data
  const { countryIso2 } = useSelector((state) => state.countryCode)

  const centersWithAccess = updateAccessInfo(userAccessToCenters, countryIso2)

  // this determines the active center based on the longest matching path
  const getActiveCenter = () => {
    const currentUrl = window.location.href
    return centersWithAccess.reduce((active, center) => {
      if (currentUrl.startsWith(center.path)) {
        if (!active || center.path.length > active.path.length) {
          return center
        }
      }
      return active
    }, null)
  }

  const activeCenter = getActiveCenter()
  const isActivePath = (center) => activeCenter?.name === center.name

  const handleLinkToCenter = (center) => {
    if (center.name === 'ThryvPay') return center.path
    return center.hasAccess ? center.path : center.defaultPath
  }

  return (
    <Transition show={openWaffleMenu} as="div" className="relative ">
      <Dialog
        as="div"
        className="fixed inset-0 overflow-hidden !z-[999]"
        initialFocus={waffleMenuRef}
        onClose={() => setOpenWaffleMenu(false)}
        style={{
          height: '280px',
          top: '65px',
          left: '0',
          width: '207px',
        }}
      >
        <div className="fixed top-16 left-0 w-[207px]">
          <Transition.Child
            as="div"
            enter="transition ease-in duration-200"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-out duration-200"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
            ref={waffleMenuRef}
            className="bg-white rounded-lg p-3 w-[207px] !z-[999]"
            style={{
              height: '280px',
              boxShadow:
                '0px 5px 24px 0px #0000001F, 2px 13px 19px 2px #00000024, 0px 7px 8px -4px #00000033',
            }}
          >
            <CardTitle variant="primary" className="mb-2 pl-1">
              Thryv Apps
            </CardTitle>
            <div className="flex flex-col gap-y-2">
              {centersWithAccess.map((center) => (
                <Link
                  reloadDocument
                  key={center.name}
                  to={handleLinkToCenter(center)}
                  className={`h-[48px] rounded-lg flex gap-2 items-center p-1 transition-all hover:bg-thryv-gray-light-300 ${
                    isActivePath(center) ? 'bg-[#FC51201F]' : ''
                  }`}
                  onClick={() => setOpenWaffleMenu(false)}
                >
                  <CommandCenterIcon
                    variant={center.icon}
                    type="solid"
                    width={34}
                    height={34}
                  />
                  <ParagraphText variant="reg">{center.name}</ParagraphText>
                </Link>
              ))}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  )
}
