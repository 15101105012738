// Hooks
import { useSelector } from 'react-redux'
import { useContactsContext } from '../../user-profile-and-activity/contexts/contacts-context'

// Custom Hooks
import useAvatarComponent from '../../../../hooks/useAvatarComponent'
import { useFormContext } from '../../user-profile-and-activity/contexts/form-context'

// Utils
import { trimPhoneCountryCode } from '../../../common/edit-contact-form/utils'

// Components
import { LoadingDots } from '../../../common/loading-dots'
import { SaveButton } from '../../../common/edit-contact-form/components/save-button/SaveButton'
import { CommandCenterNotification } from '../../../common/command-center-notification/command-center-notification'
import {
  FirstNameInput,
  LastNameInput,
  PhoneInput,
  EmailInput,
} from '../../../common/edit-contact-form/components/inputs/Inputs'

const EditContactForm = ({
  className,
  submissionIsLoading,
  show,
  onSubmit = () => {},
  showError,
  hideErrorMessage,
  errorMessage,
}) => {
  const { countryIso2: countryCode } = useSelector((state) => state.countryCode)

  const { contact } = useContactsContext()
  const { errors, isValid, isDirty, control, handleSubmit } = useFormContext(contact)

  const { RenderUserProfile } = useAvatarComponent({
    avatarSize: 'default',
    picture_url: contact.pictureUrl,
    contactName: contact.fullName.toUpperCase(),
  })

  return (
    <div className={`${className} ${show ? 'block' : 'hidden'}`}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="relative mb-[16px]">
          <div className="opacity-50 pointer-events-none">
            {/* THIS IS THE AVATAR - IT IS DISABLED FOR NOW. */}
            <RenderUserProfile />
          </div>

          {!submissionIsLoading && (
            <SaveButton
              className="absolute top-0 right-0"
              enabled={isValid && isDirty}
            />
          )}

          {submissionIsLoading && (
            <div className="absolute top-0 right-0">
              <LoadingDots />
            </div>
          )}
        </div>

        <ul className="flex flex-col">
          <li>
            <FirstNameInput
              error={errors.firstName?.message}
              control={control}
              disabled={submissionIsLoading}
            />
          </li>

          <li>
            <LastNameInput
              error={errors.lastName?.message}
              control={control}
              disabled={submissionIsLoading}
            />
          </li>

          <li>
            <PhoneInput
              error={errors.phone?.message}
              control={control}
              phoneNumber={trimPhoneCountryCode(contact.phone, countryCode)}
              disabled={contact.phone === 'error' || submissionIsLoading}
            />
          </li>

          <li>
            <EmailInput
              error={errors.email?.message}
              control={control}
              disabled={submissionIsLoading}
            />
          </li>
        </ul>
      </form>

      <CommandCenterNotification
        variant="warning"
        show={showError}
        onCloseClick={hideErrorMessage}
        disableCloseButton={true}
      >
        {errorMessage}
      </CommandCenterNotification>
    </div>
  )
}

export default EditContactForm
