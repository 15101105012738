import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronUp, faChevronDown } from '@fortawesome/pro-regular-svg-icons'
import { CustomButtonGroup } from '../../../../components/common/CustomButtonGroupDropDown/CustomButtonGroup'
import { Entitlements } from './Entitlements'
import { StatusPill } from '../../../../components/common/status-pill/status-pill'
import CancelPlanModal from '../../../../components/common/modals/cancel-plan-modal/CancelPlanModal'
import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  setModalContent,
  setDisableBackdropClick,
  setShowModal,
} from '../../../../components/common/modals/slices/connectChannelsSlice'
import CancelUnavailableModal from '../../../../components/common/modals/cancel-unavailable-modal/CancelUnavailableModal'
import { useFlags } from 'launchdarkly-react-client-sdk'

const SELECTABLE_MODALS = {
  UPGRADE_CC: 'upgrade-modal',
  CANCEL: 'cancel-modal',
  CANCEL_UNAVAILABLE: 'cancel-unavailable-modal',
}

export const SubscriptionMobile = ({ tableData, loading, latestPayment }) => {
  const { commandCenterCancelEnabled } = useFlags()
  const [openMobileTableRow, setOpenMobileTableRow] = useState(0)
  const [selectedModal, setSelectedModal] = useState(null) // null means no modal
  const dispatch = useDispatch()
  const { showModal } = useSelector((state) => state.connectChannels)

  // Closes the Upgrade Plan modal when the user closes it.
  useEffect(() => {
    if (!showModal) setSelectedModal(null)
  }, [showModal])

  const openCancelModal = () => {
    setSelectedModal(SELECTABLE_MODALS.CANCEL)
  }

  const openCancelUnavailableModal = () => {
    setSelectedModal(SELECTABLE_MODALS.CANCEL_UNAVAILABLE)
  }

  const openUpgradePlanModal = () => {
    setSelectedModal(SELECTABLE_MODALS.UPGRADE_CC) // We don't need this here. But long term it would be good to use. Instead of below
    dispatch(setModalContent(4))
    dispatch(setDisableBackdropClick(false))
    dispatch(setShowModal(true))
  }

  const renderPlanName = (plan) => {
    if (
      plan?.subscription?.SUBSCRIPTION_PLAN_NAME === 'Command Center Basic' ||
      plan?.subscription?.SUBSCRIPTION_PLAN_NAME === 'Command Center Plus' ||
      plan?.subscription?.SUBSCRIPTION_PLAN_NAME === 'Command Center Pro'
    ) {
      return plan?.subscription?.SUBSCRIPTION_PLAN_NAME.substring(14)
    } else {
      return plan?.subscription?.SUBSCRIPTION_PLAN_NAME
    }
  }

  return (
    <>
      {loading ? (
        <div className="flex justify-center h-[60px]">
          <span className="w-[38px] h-[38px] border-4 border-white border-b-thryv-orange-300 rounded-[50%] box-border animate-spin relative top-5"></span>
        </div>
      ) : (
        tableData.map((data, index) => (
          <div key={index}>
            {data.subscription.SUBSCRIPTION_PLAN_NAME !== 'Thryv Marketplace' &&
            data.subscription.SUBSCRIPTION_PLAN_NAME !== 'TeamChat' ? (
              <div className="w-full flex items-center h-[48px] bg-[#F8F9FB] mt-[1rem] mb-[1rem]">
                {openMobileTableRow === index + 1 ? (
                  <FontAwesomeIcon
                    className="ml-[8px] text-[12px] text-[#808080]"
                    icon={faChevronUp}
                    onClick={() => setOpenMobileTableRow(0)}
                  />
                ) : (
                  <FontAwesomeIcon
                    data-testid="mobile-expand-subscription"
                    className="ml-[8px] text-[12px] text-[#808080]"
                    icon={faChevronDown}
                    onClick={() => setOpenMobileTableRow(index + 1)}
                  />
                )}
                {/* <img
                className="w-[32px] h-[32px] rounded-[4px]"
                src={data?.center?.logo}
              /> */}
                {/* <div className='flex '> */}
                <div className="flex w-full justify-between items-center">
                  <span className="text-[12px] text-[#4D4D4D] pl-2 font-semibold">
                    {data?.subscription?.SUBSCRIPTION_PLAN_NAME}
                  </span>
                  <div className="pr-2">
                    <CustomButtonGroup
                      planName={data?.subscription?.SUBSCRIPTION_PLAN_NAME}
                      onSelectionClick={(selection) => {
                        // REFERENCE to where I got the plan names: https://www.notion.so/thryvlabs/Recurly-Plans_050924-e8ee0e6be3d244b7bfe5653fab921dc1#f63a3f92c5ba478cb1399437a8f5361d
                        const isFreemiumPlan =
                          data?.subscription.SUBSCRIPTION_PLAN_NAME ===
                            'Command Center Basic' ||
                          data?.subscription.SUBSCRIPTION_PLAN_NAME ===
                            'Command Center Basic+'

                        if (selection === 'Upgrade') openUpgradePlanModal()
                        if (selection === 'Cancel') {
                          if (commandCenterCancelEnabled) {
                            if (isFreemiumPlan) openCancelModal()
                            else openCancelUnavailableModal()
                          } else {
                            openCancelUnavailableModal()
                          }
                        }
                      }}
                    />

                    <CancelPlanModal
                      isOpen={selectedModal === SELECTABLE_MODALS.CANCEL}
                      handleClose={() => setSelectedModal(null)}
                      subscription={data}
                      latestPayment={latestPayment}
                    />

                    <CancelUnavailableModal
                      isOpen={selectedModal === SELECTABLE_MODALS.CANCEL_UNAVAILABLE}
                      handleClose={() => setSelectedModal(null)}
                    />
                  </div>
                </div>
              </div>
            ) : (
              <div className="w-full flex justify-start items-center h-[48px] bg-[#F8F9FB] mt-[1rem] mb-[1rem]">
                {openMobileTableRow === index + 1 ? (
                  <FontAwesomeIcon
                    className="ml-[8px] text-[12px] text-[#808080]"
                    icon={faChevronUp}
                    onClick={() => setOpenMobileTableRow(0)}
                  />
                ) : (
                  <FontAwesomeIcon
                    className="ml-[8px] text-[12px] text-[#808080]"
                    icon={faChevronDown}
                    onClick={() => setOpenMobileTableRow(index + 1)}
                  />
                )}
                <div className="pl-2">
                  <span className="text-[12px] text-[#4D4D4D] font-semibold">
                    {data?.subscription?.SUBSCRIPTION_PLAN_NAME}
                  </span>
                </div>
              </div>
            )}

            {openMobileTableRow === index + 1 && (
              <div>
                <p className="flex justify-between p-[8px]">
                  <span className="text-[#808080] text-[14px]">Plan Name</span>
                  <span className="text-[#231F20] text-[14px]">
                    {renderPlanName(data)}
                  </span>
                </p>
                <p className="flex justify-between p-[8px]">
                  <span className="text-[#808080] text-[14px]">Status</span>
                  <span className="text-[#231F20] text-[14px]">
                    {data?.subscription?.__typename === 'ActiveSubscription' ? (
                      <StatusPill color="#16A085">ACTIVE</StatusPill>
                    ) : (
                      <StatusPill color="#a3a5a7">INACTIVE</StatusPill>
                    )}
                  </span>
                </p>
                <p className="flex justify-between p-[8px]">
                  <span className="text-[#808080] text-[14px]">Users</span>
                  <span className=" text-[#231F20] text-[14px]">
                    {data?.subscription?.SUBSCRIPTION_QUANTITY}
                  </span>
                </p>
                {data?.subscription?.QUANTITY_TIER_PRICE === null ? null : (
                  <p className="flex justify-between p-[8px]">
                    <span className="text-[#808080] text-[14px]">Monthly Price</span>
                    <span className="text-[#231F20] text-[14px]">
                      {' '}
                      ${data?.subscription?.QUANTITY_TIER_PRICE} per user/month
                    </span>
                  </p>
                )}

                {/* {data.entitlementsNo > 0 && <><p className='flex justify-between p-[8px]'>
            <span className='text-[#808080] text-[14px]'>Entitlements</span>
            <span className='text-[#231F20] text-[14px]'>{data.entitlementsNo}</span>
          </p>
            <div className=' p-[8px]'>
                {
                  data.entitlements.map((data, index) => (
                    <Entitlements key={index} entitlement={data}/>
                  ))
                }
            </div>
          </>} */}
                <div className="p-[8px]">
                  {data.addons.map((data, index) => (
                    <Entitlements key={index} entitlement={data.ADD_ON_NAME} />
                  ))}
                </div>
              </div>
            )}
          </div>
        ))
      )}
    </>
  )
}
