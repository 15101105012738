/* eslint-disable no-console */
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useSearchParams, useNavigate } from 'react-router-dom'
import { useApolloClient } from '@apollo/client'
import { AuthLoader } from '@authentication'
import { useAuth0 } from '@auth0/auth0-react'
import {
  setChannelConnectedToAnotherCC,
  setChannelIsConnected,
  setConnectedChannelInfo,
  setConnectionError,
  setMeta400Error,
  setModalContent,
  setNoWebsiteToConnectError,
  setShowFirstChannelModal,
  setShowModal,
  setMetaUserData,
} from '../../components/common/modals/slices/connectChannelsSlice'

import axios from 'axios'
import getApolloLink from '../../utils/apollo-link'
import { batch } from 'react-redux'
import { sha1 } from 'js-sha1'

const connectNylasChannel = async ({
  accessToken,
  axiosParams: { code, ccid, directoryCode },
}) => {
  const { VITE_NYLAS_HOST, VITE_AUTH0_REDIRECT_URI } = import.meta.env

  let url = `${VITE_NYLAS_HOST}/receive_hosted_auth_vthree_callback?`

  const response = await axios({
    url,
    method: 'post',
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    params: {
      code: code,
      ccid: ccid,
      directoryCode: directoryCode,
      redirectURI: `${VITE_AUTH0_REDIRECT_URI}/oauth/callback`,
    },
  })

  return response
}

const connectMicrosoftOfficeChannel = async ({
  accessToken,
  axiosParams: { code, ccid, directoryCode },
}) => {
  const response = await axios({
    url: `${VITE_OFFICE365_CONNECT_URI}`,
    method: 'post',
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    params: {
      code: code,
      ccid: ccid,
      directoryCode: directoryCode,
    },
  })

  return response
}

const connectFacebookChannel = async ({
  accessToken,
  axiosParams: { code, businessId, ccid, directoryCode, agentId },
}) => {
  const response = await axios({
    url: VITE_SUNSHINE_CONNECT_URI,
    method: 'post',
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    params: {
      code: code,
      businessId: businessId,
      channelType: localStorage.getItem('sunshineapp'),
      ccid: ccid,
      direcotryCode: directoryCode,
      agentId: agentId,
      authType: 'desktop',
      selectedPageAccessToken: '',
      pageName: '',
    },
  })

  return response
}

const connectWebchatChannel = async ({
  accessToken,
  axiosParams: {
    businessId,
    ccid,
    directoryCode,
    agentId,
    email,
    country,
    websiteType,
  },
}) => {
  const response = await axios({
    url: VITE_SUNSHINE_CONNECT_URI,
    method: 'post',
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    params: {
      businessId: businessId,
      ccid: ccid,
      direcotryCode: directoryCode,
      agentId: agentId,
      websiteType: websiteType,
      businessIconUrl: '',
      email: email,
      country: country,
      channelType: 'webChat',
      authType: 'desktop',
    },
  })

  return response
}

const { VITE_SUNSHINE_CONNECT_URI, VITE_OFFICE365_CONNECT_URI } = import.meta.env

const Oauth = () => {
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(true)
  const { user, getAccessTokenSilently } = useAuth0()
  const client = useApolloClient()
  const code = searchParams.get('code')

  const hardReloadAuthToken = async () => {
    try {
      const accessToken = await getAccessTokenSilently({ cacheMode: 'off' })
      const link = getApolloLink(accessToken)
      client.setLink(link)
    } catch (error) {
      console.error('Error calling hardReloadAuthToken function:', error)
    }
  }

  const onError = (provider) => {
    console.error('here error connecting ', provider)

    dispatch(setConnectionError(true))
    dispatch(setModalContent(1))

    if (localStorage.getItem('onboarding') === 'true') {
      navigate('/')
      dispatch(setShowModal(false))
      dispatch(setConnectedChannelInfo({ provider }))
    } else {
      navigate('/inbox')
      dispatch(setShowModal(true))
    }
  }

  const connectChannel = async () => {
    let res
    let channelIsConnected = true

    const token = await getAccessTokenSilently({ cacheMode: 'off' })

    // Add new channels here that could appear in `window.location.href`
    const channelMap = {
      nylas: 'oauth/callback?code',
      office: 'oauth=office?code',
      facebook: 'oauth=meta?code',
      webchat: 'oauth=webchat?webtype',
    }

    const channelType =
      Object.entries(channelMap).find(([, value]) =>
        window.location.href.includes(value),
      )?.[0] || ''

    // If we get new channels, add them to the switch statement
    // Also include the necessary url in the channelMap variable above.
    switch (channelType) {
      case 'nylas':
        try {
          res = await connectNylasChannel({
            accessToken: token,
            axiosParams: {
              code: code,
              ccid: user?.cc_id,
              directoryCode: user?.dirCode,
            },
          })
          channelIsConnected = !res.data.connectedToOtherCC
          dispatch(setChannelConnectedToAnotherCC(res.data.connectedToOtherCC))
        } catch (err) {
          console.error('CHANNEL CONNECTION ERROR: ', err)
          onError('nylas')
        }
        break
      case 'office':
        try {
          res = await connectMicrosoftOfficeChannel({
            accessToken: token,
            axiosParams: {
              code: code,
              ccid: user?.cc_id,
              directoryCode: user?.dirCode,
            },
          })
        } catch (err) {
          console.error('CHANNEL CONNECTION ERROR: ', err)
          onError('office')
        }
        break
      case 'facebook':
        try {
          res = await connectFacebookChannel({
            accessToken: token,
            axiosParams: {
              code: code,
              businessId: user.businessId,
              ccid: user.cc_id,
              directoryCode: user.dirCode,
              agentId: user.staffUID,
            },
          })
        } catch (err) {
          console.error('CHANNEL CONNECTION ERROR: ', err)

          if (err?.response?.status === 400) {
            dispatch(setMeta400Error(err?.response?.data))
            dispatch(setModalContent(1))
            dispatch(setShowModal(true))
            if (localStorage.getItem('onboarding') === 'true') {
              navigate('/')
            } else {
              navigate('/inbox')
            }
          } else {
            onError('sunshine')
          }
        }
        break
      case 'webchat':
        try {
          const webType = searchParams.get('webtype')

          res = connectWebchatChannel({
            accessToken: token,
            axiosParams: {
              businessId: user.businessId,
              ccid: user.cc_id,
              directoryCode: user.dirCode,
              agentId: user.staffUID,
              email: user.email,
              country: user.country,
              websiteType: webType,
            },
          })
          channelIsConnected = res.data?.internalWebsite
        } catch (err) {
          onError('webchat')
        }
        break
      default:
        if (localStorage.getItem('onboarding') === 'true') {
          navigate('/')
        } else {
          navigate('/inbox')
        }
        throw new Error('Unhandled OAuth.')
    }

    setLoading(false)
    await hardReloadAuthToken()
    dispatch(setConnectedChannelInfo(res.data))
    if (localStorage.getItem('onboarding') === 'true') {
      navigate('/')
      return
    }
    navigate('/inbox')

    res.data.connectedToOtherCC === true //checks channel isnt connected to another CC
      ? batch(() => {
          dispatch(setShowModal(true))
          dispatch(setModalContent(1))
        })
      : res?.data?.internalWebsite === false //checks webchat channel has a website to connect to
        ? batch(() => {
            dispatch(setShowModal(true))
            dispatch(setModalContent(1))
            dispatch(setNoWebsiteToConnectError(true))
          })
        : res?.data?.reauth === true && res?.data?.selectPage === false // if only reatuh is true display reauth modal for meta
          ? batch(() => {
              dispatch(setMetaUserData(res.data))
              dispatch(setModalContent(10))
            })
          : res?.data?.reauth === true && res?.data?.selectPage === true // if both are true display select page modal for meta
            ? batch(() => {
                dispatch(setMetaUserData(res.data))
                dispatch(setModalContent(11))
              })
            : dispatch(setShowFirstChannelModal(true))

    if (channelIsConnected) {
      // invoke impact conversion function when channel is connected
      if (window.ire && !Array.isArray(window.ire)) {
        const { cc_id, email } = user
        if (email && cc_id) {
          window.ire(
            'trackConversion',
            43455,
            {
              orderId: 'IR_AN_64', // passing this value will create a UUID in impact
              customerId: cc_id,
              customerEmail: sha1(email),
            },
            {
              verifySiteDefinitionMatch: true,
            },
          )
        }
      }
      dispatch(setChannelIsConnected(true))
    }
  }

  useEffect(() => {
    connectChannel()
  }, [window.location.href])

  return loading && <AuthLoader width="full" height="full" />
}

export default Oauth
