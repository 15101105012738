import { gql } from '@apollo/client'

export const SET_ADD_RECURLY_BILLING_INFO = gql`
  mutation set_add_recurly_billing_info_mutation(
    $CCID: String!
    $token: String!
    $isPrimary: Boolean
  ) {
    addRecurlyBillingInfo(CCID: $CCID, token: $token, isPrimary: $isPrimary) {
      id
      accountId
      primaryPaymentMethod
      paymentMethod {
        cardType
        firstSix
        lastFour
        expMonth
        expYear
      }
      address {
        street1
        street2
        city
        region
        postalCode
        country
        phone
      }
    }
  }
`

export const SET_UPDATE_RECURLY_BILLING_INFO = gql`
  mutation set_update_recurly_billing_info_mutation(
    $CCID: String!
    $token: String!
    $billingInfoId: String!
    $isPrimary: Boolean
  ) {
    updateRecurlyBillingInfo(
      CCID: $CCID
      token: $token
      billingInfoId: $billingInfoId
      isPrimary: $isPrimary
    ) {
      id
      accountId
      primaryPaymentMethod
      paymentMethod {
        cardType
        firstSix
        lastFour
        expMonth
        expYear
      }
      address {
        street1
        street2
        city
        region
        postalCode
        country
        phone
      }
    }
  }
`

export const SET_UPDATE_ADD_QUANTITY = gql`
  mutation set_update_add_quantity_mutation($AddOnQuantity: Int) {
    updateMinutesQuantity(AddOnQuantity: $AddOnQuantity) {
      AddOnQuantity
    }
  }
`

export const SET_ADD_RECURLY_MINUTES_QUANTITY = gql`
  mutation addRecurlyMinutes($CCID: String!, $quantity: String!) {
    addRecurlyMinutes(CCID: $CCID, quantity: $quantity) {
      id
      subscriptionId
    }
  }
`

export const SET_SUBSCRIPTION_CHANGE = gql`
  mutation set_subscription_change_mutation($CCID: String!, $planCode: String!) {
    changeRecurlySubscription(CCID: $CCID, planCode: $planCode) {
      id
      subscriptionId
      plan {
        code
        name
      }
    }
  }
`

export const DELETE_BILLING_INFO = gql`
  mutation delete_billing_info_mutation($CCID: String!, $billingInfoId: String!) {
    deleteRecurlyBillingInfo(CCID: $CCID, billingInfoId: $billingInfoId)
  }
`

// NOTE: This will cancel the subscription immediately if freemium cc and at upcoming bill_date if paid cc
// GITHUB FILE: https://github.com/ThryvLabs/Atom-Core/blob/4671ce8969e4241a8d3aa8b25c83871d234bd062/recurly_api/handlers/cancellation.js#L14
// cancelSubscription()
export const CANCEL_CC_SUBSCRIPTION = gql`
  mutation cancel_cc_subscription($CCID: String!) {
    cancelRecurlySubscription(CCID: $CCID)
  }
`
