import { createSlice } from '@reduxjs/toolkit'
import { getThryvSupportNumber } from '../../../../utils/get-thryv-support-number'

function shallowEqual(obj1, obj2) {
  if (typeof obj1 !== 'object' || typeof obj2 !== 'object') return false

  const keys1 = Object.keys(obj1)
  const keys2 = Object.keys(obj2)

  // Compare lengths
  if (keys1.length !== keys2.length) {
    return false
  }

  // Compare values for each key
  for (let key of keys1) {
    if (obj1[key] !== obj2[key] && key !== 'body') {
      return false
    }
  }

  return true
}

const parseFullName = (firstName, lastName) => {
  const fullName = /\d{10}/.test(firstName)
    ? 'Unknown'
    : firstName?.length > 0 && lastName?.length === 0
      ? firstName
      : firstName?.length > 0 && lastName?.length > 0
        ? firstName + ' ' + lastName
        : 'Unknown'

  return fullName
}

const cleanupContact = (contact, countryISO) => {
  let newContact
  const parsedBody = JSON.parse(contact?.body)

  const isThryvSupport = contact.contactsk2 === 'support@thryv.com'
  if (isThryvSupport) {
    newContact = {
      body: parsedBody,
      threadpk: contact.contactsk1,
      username: 'Thryv Support Center',
      phone: getThryvSupportNumber(countryISO),
      email: contact.contactsk2,
      sk1: contact.sk1 || 111111111111111, // RANDOM NUMBERS CREATED FOR THRYV SUPPORT UNIQUE SK1
      pk1:
        contact.pk1 ||
        'not-a-real-pk1-fe3ef3e4-0460-41a7-a157-137ac974a965-contacts-slice.js', // RANDOM UUID CREATED FOR THRYV SUPPORT,
      firstName: '',
      lastName: '',
      pictureUrl: parsedBody.picture_url || '',
    }
    return newContact
  }

  newContact = {
    body: parsedBody,
    threadpk: contact.contactsk1 || '',
    username: parseFullName(parsedBody?.given_name, parsedBody?.surname),
    phone: parsedBody.phone_numbers[0]?.number || contact.contactsk3 || '',
    email: contact.contactsk2 || '',
    sk1: contact.sk1 || '',
    pk1: contact.pk1 || '',
    firstName: parsedBody.given_name || '',
    lastName: parsedBody.surname || '',
    pictureUrl: parsedBody.picture_url || '',
  }

  return newContact
}

const sortContactsList = (sortOrder, contacts, countryISO, phoneAccessType) => {
  const thryvSupportContactDefault = {
    username: 'Thryv Support Center',
    phone: getThryvSupportNumber(countryISO),
    email: 'support@thryv.com',
    body: {},
    threadpk: '',
    sk1: 111111111111111, // RANDOM NUMBERS CREATED FOR THRYV SUPPORT UNIQUE SK1
    pk1: 'not-a-real-pk1-fe3ef3e4-0460-41a7-a157-137ac974a965-contacts-slice.js-for-thryv-support', // RANDOM UUID CREATED FOR THRYV SUPPORT
    firstName: '',
    lastName: '',
    pictureUrl: '',
  }

  if (!contacts.length || phoneAccessType === '0')
    return [thryvSupportContactDefault]

  let thryvSupportContact = {}

  let newContacts = contacts.filter((contact) => {
    if (contact.email !== 'support@thryv.com') {
      return true
    } else {
      thryvSupportContact = contact
      return false
    }
  })

  switch (sortOrder) {
    case 'asc':
      newContacts = newContacts.sort((a, b) => a.username.localeCompare(b.username))
      break
    case 'desc':
      newContacts = newContacts.sort((a, b) => b.username.localeCompare(a.username))
      break
    default:
      break
  }

  const hasThryvSupportContact = thryvSupportContact?.email === 'support@thryv.com'
  if (hasThryvSupportContact) {
    newContacts.unshift(thryvSupportContact)
  } else {
    newContacts.unshift(thryvSupportContactDefault)
  }

  return newContacts
}

const contactsSlice = createSlice({
  name: 'contacts',
  initialState: {
    contacts: {
      // * VITE_CALLS_CONNECTION is a string instead of boolean
      // by default it's false, please use get a number in calls page to set it to true
      connection: false, // FIXME: What is this? Is it related to contacts?
      contactsList: [], // This is the initial contacts list. Shouldn't be modified unless we're pulling in new contacts. Not displayed in the UI.
      filteredContacts: [], // This is the list we display in the UI. It can be modified by search or sort etc.
      phoneNumber: '', // FIXME: Do we need this? At least here in contacts?
      phoneChannelID: '', // FIXME: Do we need this? At least here in contacts?
      countryISO: '',
    },

    selectedContact: {
      id: '',
      username: '', // AKA: fullName
      job: '',
      location: '',

      sk1: '',
      contactsk1: '',
      phone: '', // AKA: contactsk3
      email: '', // AKA: contactsk2
      firstName: '',
      lastName: '',
      pictureUrl: '',
      body: '',
    },

    searchValue: '', // FIXME: What's this for? I think it would be good to have a description here.

    voicemailMetaData: {
      name: '',
      length: 0,
    },
  },
  reducers: {
    /**
     *
     * FIXME: If this is invoked, it will break the UI. This should not be used anymore and must be removed from the codebase.
     * FIXME: Remove this reducer once it isn't used anywhere in the codebase.
     *
     */
    selectContact(state, action) {
      // this condition will be used for solving recentCall can't be shared with contact slice from call slice
      // since recentCall and contact are correlated with each other by "phone"
      if (action.payload.recentCall) {
        const { recentCall } = action.payload

        return {
          ...state,
          selectedContact: state.contacts.contactsList.length
            ? recentCall.username
              ? state.contacts.contactsList.find(
                  (contact) => contact.phone === recentCall.phone,
                )
              : // if select an unkown contact, just uesd unkown contact's info
                recentCall
            : state.selectedContact,
        }
      } else {
        return {
          ...state,
          selectedContact: state.contacts.contactsList.length
            ? state.contacts.contactsList.find(
                (contact) => contact.id === action.payload,
              )
            : state.selectedContact,
        }
      }
    },

    /**
     *
     * This function does the following:
     *
     * - Initializes the contactsList and filteredContacts with the queriedContacts.
     * - Filters out contacts that don't have a body or a username.
     * - Sets countryISO state for use in this file.
     *
     */
    setContactLists: (state, action) => {
      const {
        sortOrder = 'asc',
        queriedContacts,
        countryISO,
        phoneAccessType,
      } = action.payload
      const validContacts = queriedContacts.filter((contact) => {
        const { body, contactsk2 } = contact

        const parsedBody = JSON.parse(body)
        const username = parseFullName(parsedBody?.given_name, parsedBody?.surname)

        if (!body) return false

        if (contactsk2 === 'support@thryv.com') return true

        if (username.length && username !== 'Unknown') return true

        return false
      })

      let contacts = []

      validContacts.forEach((contact) => {
        const newContact = cleanupContact(contact, countryISO)

        if (newContact?.email === 'support@thryv.com') {
          contacts.unshift(newContact)
        } else {
          contacts.push(newContact)
        }
      })

      contacts = sortContactsList(sortOrder, contacts, null, phoneAccessType)

      state.contacts.contactsList = contacts
      state.contacts.filteredContacts = contacts // FIXME: I think this should be updated in another function
      state.contacts.countryISO = countryISO
    },

    /**
     *
     * This function does the following:
     *
     * - Sorts the filteredContacts list in asc or desc order.
     *
     */

    updateContactsListSortOrder: (state, action) => {
      const sortOrder = action.payload
      const contacts = state.contacts.filteredContacts
      const countryISO = state.contacts.countryISO

      if (sortOrder !== 'asc' && sortOrder !== 'desc') return
      if (contacts.length === 0) return

      const sortedContacts = sortContactsList(sortOrder, contacts, countryISO)
      state.contacts.filteredContacts = sortedContacts
    },

    /**
     *
     * NOTE:
     *
     * I HIGHLY recommend if using this function, to only update the filteredContacts list with values from the contacts list.
     * To prevent breaking changes.
     *
     */

    updateFilteredContactsList: (state, action) => {
      const newContacts = action.payload

      if (!newContacts.length) return

      state.contacts.filteredContacts = newContacts
    },

    /**
     *
     * NOTE:
     *
     * Updates selectedContact and filteredContacts based on sk1 or unformattedPhoneNum. Only use one to update selected contact.
     *
     * threadpk added to prevent 'Unknown' contact when updating a contact's number since contacts are thread based.
     *
     */

    updateSelectedContact: (state, action) => {
      const selectedContact = state.selectedContact
      const { sk1 = 0, unformattedPhone = '', defaultValues = {} } = action.payload
      const { email = '', threadpk = '' } = defaultValues // Provided in case of unknown contact and we want to populate unknown contact with data. This is optional.

      const contactsList = state.contacts.contactsList
      const filteredContacts = state.contacts.filteredContacts

      let newSelectedContact = {}

      if (sk1) {
        const contactFromContactsList = contactsList.find(
          (contact) => contact.sk1 === sk1,
        )
        const contactFromFilteredList = filteredContacts.find(
          (contact) => contact.sk1 === sk1,
        )

        if (contactFromFilteredList) {
          newSelectedContact = { ...contactFromFilteredList }
        } else {
          newSelectedContact = { ...contactFromContactsList }
        }
      }

      if (threadpk) {
        const contactFromContactsList = contactsList.find(
          (contact) => contact.threadpk === threadpk,
        )
        const contactFromFilteredList = filteredContacts.find(
          (contact) => contact.threadpk === threadpk,
        )

        if (contactFromFilteredList) {
          newSelectedContact = { ...contactFromFilteredList }
        } else {
          newSelectedContact = { ...contactFromContactsList }
        }
      }

      if (unformattedPhone && Object.keys(newSelectedContact).length === 0) {
        const contactFromContactsList = contactsList.find(
          (contact) => contact.phone === unformattedPhone,
        )

        const contactFromFilteredList = filteredContacts.find(
          (contact) => contact.phone === unformattedPhone,
        )

        if (contactFromFilteredList) {
          newSelectedContact = { ...contactFromFilteredList }
        } else {
          newSelectedContact = { ...contactFromContactsList }
        }
      }

      const isUnknownContact = Object.keys(newSelectedContact).length === 0
      if (isUnknownContact) {
        newSelectedContact = {
          username: 'Unknown',
          phone: unformattedPhone || '',
          email: email || '',
          body: {},
          threadpk: threadpk || '',
          sk1: '',
          pk1: 'not-a-real-pk1-30606409-31eb-4107-a281-1ded8a762fdf-contacts-slice.js-for-unknown-contact', // RANDOM UUID CREATED FOR UNKNOWN CONTACT
          firstName: '',
          lastName: '',
          pictureUrl: '',
        }
      }

      if (shallowEqual(selectedContact, newSelectedContact)) {
        const filteredContacts = state.contacts.filteredContacts

        if (filteredContacts.length > 1) {
          state.contacts.filteredContacts = [selectedContact]
        }

        return
      }

      state.contacts.filteredContacts = [newSelectedContact]
      state.selectedContact = newSelectedContact
    },

    loadAppoloContactsState: (state, action) => {
      state.contacts = { ...state.contacts, ...action.payload }
    },

    selectContactOnPageLoad: (state) => {
      state.selectedContact = state.contacts.contactsList[0]
    },

    filterContactsByNameOrEmail: (state, action) => {
      state.searchValue = action.payload
      state.contacts.filteredContacts = [
        ...state.contacts.contactsList.filter((contact) => {
          return (
            contact?.email?.toLowerCase()?.includes(action.payload.toLowerCase()) ||
            contact?.username?.toLowerCase()?.includes(action.payload.toLowerCase())
          )
        }),
      ]
    },

    filterContactsByPhone: (state, action) => {
      state.searchValue = action.payload
      state.contacts.filteredContacts = [
        ...state.contacts.contactsList.filter((contact) =>
          contact?.phone?.includes(action.payload),
        ),
      ]
    },

    resetSearch: (state) => {
      state.searchValue = ''
      state.contacts.filteredContacts = state.contacts.contactsList
    },

    setUserPhoneNumber: (state, action) => {
      state.contacts = {
        ...state.contacts,
        phoneNumber: action.payload,
        connection: true,
      }
    },

    setUserphoneChannelID: (state, action) => {
      state.contacts = {
        ...state.contacts,
        phoneChannelID: action.payload,
      }
    },

    setVoicemailMetaData(state, action) {
      state.voicemailMetaData = action.payload
    },
  },
})

export const {
  filterContactsByPhone,
  selectContact,
  filterContactsByNameOrEmail,
  setContactLists,
  updateSelectedContact,
  updateFilteredContactsList,
  updateContactsListSortOrder,
  selectContactOnPageLoad,
  loadAppoloContactsState,
  setUserPhoneNumber,
  resetSearch,
  setUserphoneChannelID,
  setVoicemailMetaData,
} = contactsSlice.actions
export default contactsSlice.reducer
