import axios from 'axios'

const delVonageSession = async (vonageClient) => {
  if (!vonageClient.session_id || !vonageClient.jwt_sub) {
    return 'Invalid Vonage Client'
  }

  const response = await axios.delete(
    'https://api.nexmo.com/v1/sessions/' + vonageClient.session_id,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + vonageClient.jwt_sub,
      },
    },
  )

  return response
}

export { delVonageSession }
