import { RegularX } from '@thryvlabs/maverick'
import { useRef } from 'react'

export const ReferralModal = ({ handleClose }) => {
  const ref = useRef()
  return (
    <div className="flex flex-col min-w-[900px] min-h-[760px] p-4 lg:min-w-[740px] lg:p-[10px]">
      <div className="flex items-center justify-between">
        <div className="text-[22px] font-semibold">
          Refer a Friend and <span className="text-thryv-orange">Get Rewarded!</span>
        </div>
        <RegularX
          type="regular"
          variant="x"
          height="18"
          fill="#A3A5A7"
          onClick={handleClose}
          className="cursor-pointer"
        />
      </div>
      <div ref={ref} className="impactembed"></div>
    </div>
  )
}
