import './cancel-plan-modal.css'
import { useEffect, useState } from 'react'

// Packages
import moment from 'moment'

// GraphQL
import { CANCEL_CC_SUBSCRIPTION } from '../../../../graphql/mutations/recurly-mutations'

// Hooks
import { useVonageClient } from '../../../../vonage/useVonageClient'
import { useAuth0 } from '@auth0/auth0-react'
import { useMutation } from '@apollo/client'
import { useMediaQuery } from 'react-responsive'

// Components
import ConfirmCancellationModal from './confirm-cancellation-modal/confirm-cancellation-modal'
import CancellationSummaryModal from './cancellation-summary-modal/cancellation-summary-modal'
import CustomModal from '../../CustomModal/Modal'

// Services
import { deregisterKnock } from './services/knock'
import { delVonageSession } from './services/vonage'

// REFERENCE FOR ACCOUNTING CODES: https://www.notion.so/thryvlabs/Recurly-Plans_050924-e8ee0e6be3d244b7bfe5653fab921dc1#f63a3f92c5ba478cb1399437a8f5361d
// const CC_ACCOUNTING_CODES = ['ccbasic', 'ccbasicpls', 'ccplus', 'ccpro']
const CC_PLANS = {
  ccbasic: {
    name: 'Basic',
  },
  ccbasicpls: {
    name: 'Basic Plus',
  },
  ccplus: {
    name: 'Plus',
  },
  ccpro: {
    name: 'Pro',
  },
}

const CANCEL_STEPS = {
  CONFIRM: 'confirmCancellation',
  SUMMARY: 'cancellationSummary',
}

// Is this something we want to do via the BE?

const CancelPlanModal = ({ isOpen, handleClose, subscription, latestPayment }) => {
  const isMobile = useMediaQuery({ maxWidth: 835 })
  const { user, getAccessTokenSilently } = useAuth0()

  const [vonageClient] = useVonageClient()

  const [step, setStep] = useState(CANCEL_STEPS['SUMMARY'])
  const [showError, setShowError] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const planAccountingCode = subscription.subscription.SUBSCRIPTION_ACCOUNTING_CODE
  const commandCenterPlan = CC_PLANS[planAccountingCode]
  const isFreemiumPlan =
    commandCenterPlan.name === 'Basic' || commandCenterPlan.name === 'Basic Plus'

  const rawNextPaymentDate = latestPayment[1].paymentDate
  const formattedNextPaymentDate = moment(rawNextPaymentDate).format('MMMM Do, YYYY')

  const [cancelSubscription] = useMutation(CANCEL_CC_SUBSCRIPTION, {
    variables: {
      CCID: user.cc_id,
    },
  })

  const cancelFreemiumPlan = async () => {
    setIsLoading(true)
    const accessToken = await getAccessTokenSilently()

    try {
      await cancelSubscription()
      await delVonageSession(vonageClient)
      await deregisterKnock(user.cc_uid, accessToken)
      location.reload()
    } catch (err) {
      setShowError(true)
      // eslint-disable-next-line no-console
      console.error(
        `Error while cancelling ${commandCenterPlan.name} subscription: `,
        err,
      )
    }
    setIsLoading(false)
  }

  const cancelPaidPlan = async () => {
    setIsLoading(true)
    try {
      await cancelSubscription()
    } catch (err) {
      setShowError(true)
      // eslint-disable-next-line no-console
      console.error(
        `Error while cancelling ${commandCenterPlan.name} subscription: `,
        err,
      )
    }
    setIsLoading(false)
  }

  const closeModal = () => {
    if (isLoading) return
    handleClose()
    // Timeout added to delay switching back to the CONFIRM step for better user experience
    setTimeout(() => {
      setStep(CANCEL_STEPS['CONFIRM'])
    }, 1000)
  }

  useEffect(() => {
    setStep(CANCEL_STEPS['CONFIRM'])
  }, [])

  return (
    <CustomModal
      isModalForm={true}
      heading=""
      hasCrossIcon={false}
      isOpen={isOpen}
      setIsOpen={closeModal}
      close={closeModal}
      width={isMobile ? '80%' : '434px'}
    >
      <div data-testid="cancel-modal" className="my-[15px] -mx-[10px]">
        <ConfirmCancellationModal
          isVisible={step === CANCEL_STEPS['CONFIRM']}
          handleClose={closeModal}
          planName={commandCenterPlan.name}
          handleCancel={async () => {
            setShowError(false)

            if (!isFreemiumPlan) {
              await cancelPaidPlan()
            }

            setStep(CANCEL_STEPS['SUMMARY'])
          }}
          isLoading={isLoading}
          showError={showError}
        />

        <CancellationSummaryModal
          planName={commandCenterPlan.name}
          isVisible={step === CANCEL_STEPS['SUMMARY']}
          isLoading={isLoading}
          handleClose={closeModal}
          onCancelClick={async () => {
            if (isFreemiumPlan) await cancelFreemiumPlan()
          }}
          nextPaymentDate={formattedNextPaymentDate}
        />
      </div>
    </CustomModal>
  )
}

export default CancelPlanModal
