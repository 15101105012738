import { NavLink } from 'react-router-dom'
import '../../../../pages/calls/calls.css'
import { useEffect, useState, useRef } from 'react'
import MinutesCounter from '../../minutes-counter/MinutesCounter'
import { useSelector, useDispatch } from 'react-redux'
import { setTabSelected, setMobileKeypadOpen } from '../../slices/callSlice'
import { DialpadIcon } from '../../../command-center-icon/icons/icons'
import { Icon, ParagraphText } from '@thryvlabs/maverick'
import { useClickOutside, usePhoneChannel } from '../../../../hooks'
import { Modal } from '@thryvlabs/maverick'
import styled from 'styled-components'
import { useRedux } from '@hooks'
import { assignedNumberIconBase64 } from '../../../command-center-icon/assets/base-64-icons/icons'
import phoneFormatter from '../../../../utils/phoneFormatter'

export const CallsNavigation = ({ setOpenAddMoreMinutes, connection, isMobile }) => {
  const dispatch = useDispatch()
  const { tabSelected } = useSelector((state) => state.calls)

  useEffect(() => {
    if (!isMobile && tabSelected === 'contacts') {
      dispatch(setTabSelected('recent'))
    }
  }, [isMobile])

  return (
    <div
      className={`flex items-center bg-white md:hidden text-thryv-steel w-full pl-[4px] semi-bold border-b-[1px] border-r-[1px] border-[#F1F1F5] h-[82px]`}
    >
      <div className="flex w-[256px] justify-between">
        <NavLink
          className="hover:cursor-pointer w-[116px] margin-auto text-center"
          to={'/calls'}
        >
          <h4
            onClick={() => {
              dispatch(setTabSelected('recent'))
            }}
            className={`${
              tabSelected === 'recent' || tabSelected === 'contacts'
                ? 'border-b-[4px] border-[#FF5001] text-black pb-[12px]'
                : 'border-none pb-[16px]'
            } pt-[35px] font-semibold relative margin-auto align-middle'`}
          >
            Recent
          </h4>
        </NavLink>
        <NavLink
          className="hover:cursor-pointer w-[116px] margin-auto text-center"
          to={'/calls/voicemail'}
        >
          <h4
            onClick={() => {
              dispatch(setTabSelected('voicemail'))
            }}
            className={`${
              tabSelected === 'voicemail'
                ? 'border-b-[4px] border-[#FF5001] text-black pb-[12px]'
                : 'border-none pb-[16px]'
            } pt-[35px] font-semibold relative margin-auto align-middle`}
          >
            Voicemail
          </h4>
        </NavLink>
      </div>
      {connection && (
        <div className="flex justify-end items-center grow pr-[12px]">
          <MinutesCounter setOpenAddMoreMinutes={setOpenAddMoreMinutes} />
        </div>
      )}
    </div>
  )
}

export const CallsNavigationMobile = () => {
  const [showMenu, setShowMenu] = useState(false)
  const { tabSelected, mobileKeypadOpen } = useSelector((state) => state.calls)
  const dispatch = useDispatch()
  const menuRef = useRef(null)
  const modalRef = useRef(null)
  const [phoneChannel] = usePhoneChannel()
  const phoneAccessType = phoneChannel?.AccessType
  const hasCallsAccess = phoneAccessType !== '0'

  useEffect(() => {
    setShowMenu(false)
  }, [tabSelected])

  useClickOutside((ev) => {
    const buttonElem = menuRef.current.previousSibling
    const buttonClicked = ev.composedPath().includes(buttonElem)
    const modalOpen = ev.composedPath().some((item) => {
      return item.id === 'modal'
    })
    if (!buttonClicked && !modalOpen) setShowMenu(false)
  }, menuRef)

  return (
    <>
      <div className="flex items-center justify-between text-thryv-night min-w-md:hidden py-[12px] w-full pr-[16px] bg-thryv-white-300">
        <div
          onClick={() => {
            setShowMenu(!showMenu)
          }}
          className="flex items-center w-auto justify-between"
        >
          <span className="flex text-p-lg font-open-sans text-[#4D4D4D] font-semibold text-lg capitalize pl-[16px]">
            {tabSelected}
          </span>
          <div className="flex pl-[10px]">
            <Icon
              className={`flex ${showMenu && '-rotate-180'} duration-300`}
              type="solid"
              variant="caretDown"
              color="#4D4D4D"
              height="4.5"
              width="8"
            />
          </div>
        </div>
        {hasCallsAccess && (
          <>
            <button
              onClick={() => {
                dispatch(setMobileKeypadOpen(!mobileKeypadOpen))
              }}
              className="flex items-center justify-between w-[116px] h-[32px] pr-[16px] bg-thryv-night rounded-[15px]"
            >
              <div className="flex pl-[15px]">
                <DialpadIcon />
              </div>
              <ParagraphText
                variant="reg"
                className="font-semibold leading-[14px] tracking-[0.25px]"
                color="white"
              >
                Dialpad
              </ParagraphText>
            </button>{' '}
          </>
        )}
      </div>
      {showMenu && (
        <div
          ref={menuRef}
          className="min-w-md:hidden absolute z-100 top-[116px] bg-white text-thryv-steel font-semibold w-[164px] h-[165px] my-[6px]"
        >
          <div className="flex flex-col shadow-[0px_2px_4px_rgba(0,0,0,0.25)] rounded-[4px] py-[5px] ml-[6px] bg-white">
            <NavLink
              onClick={() => {
                setShowMenu(false)
                dispatch(setTabSelected('recent'))
              }}
              to={'/calls'}
              className="flex w-[115px] hover:bg-[#f8f9fb] py-[8px] ml-[8px] pl-[10px] rounded-[4px]"
            >
              Recent
            </NavLink>
            <NavLink
              onClick={() => {
                setShowMenu(false)
                dispatch(setTabSelected('contacts'))
              }}
              to={'/calls/contacts'}
              className="flex w-[115px] hover:bg-[#f8f9fb] py-[8px] ml-[8px] pl-[10px] rounded-[4px]"
            >
              Contacts
            </NavLink>
            <NavLink
              onClick={() => {
                setShowMenu(false)
                dispatch(setTabSelected('voicemail'))
              }}
              to={'/calls/voicemail'}
              className="flex w-[115px] hover:bg-[#f8f9fb] py-[8px] ml-[8px] pl-[10px] rounded-[4px]"
            >
              Voicemail
            </NavLink>
            <div
              ref={modalRef}
              className="flex w-[115px] hover:bg-[#f8f9fb] py-[8px] ml-[8px] pl-[10px] rounded-[4px] text-left"
            >
              <MyNumberModal />
            </div>
          </div>
        </div>
      )}
    </>
  )
}

const CustomizedModal = styled(Modal)`
  #modal {
    margin: 10px;
    margin-top: 125px;
    width: -webkit-fill-available;
    height: 332px;
  }
`
const MyNumberModal = () => {
  return (
    <>
      <CustomizedModal variant="default" altBtn="My Number">
        <MyNumberModalContent />
      </CustomizedModal>
    </>
  )
}

const MyNumberModalContent = () => {
  const [
    {
      contacts: { phoneNumber },
    },
  ] = useRedux('contacts')
  return (
    <div className="flex flex-col text-center align-middle">
      <div className="flex justify-center pb-2">
        <img className="h-[100px] w-[100px]" src={assignedNumberIconBase64}></img>
      </div>
      <span className="text-lg font-semibold pb-2">My Number</span>
      <span className="font-semibold text-xl">{phoneFormatter(phoneNumber)}</span>
    </div>
  )
}
