import { IoClose } from 'react-icons/io5'

const CloseButton = ({ className, isDisabled, onClick, ...rest }) => (
  <button
    type="button"
    onClick={onClick}
    className={`
        ${className} 
        ${isDisabled && 'opacity-50 pointer-events-none'}
        transition-opacity duration-300
      `}
    {...rest}
  >
    <IoClose size="32" color="#A3A5A7" />
  </button>
)

export default CloseButton
