const getPath = (name) => {
  //eslint-disable-next-line
  const env = process.env.NODE_ENV || 'prod'
  const paths = {
    'Command Center': {
      prod: 'https://command.thryv.com/',
      qa1: 'https://qa-comm-center2-fe.staging.thryv.com/',
      development: 'https://dev-comm-center-fe.sandbox.thryv.com/',
    },
    'Marketing Center': {
      prod: 'https://marketingcenter.labs.thryv.com/',
      qa1: 'https://mc-dev.sandbox.thryv.com/overview',
      development: 'https://mc-dev.sandbox.thryv.com/overview',
    },
    'Business Center': {
      prod: 'https://go.thryv.com/',
      qa1: 'https://emp.thryv.com/',
      development: 'https://emp.thryv.com/',
    },
    ThryvPay: {
      prod: 'https://command.thryv.com/transactions',
      qa1: 'https://qa-comm-center2-fe.staging.thryv.com/transactions',
      development: 'https://dev-comm-center-fe.sandbox.thryv.com/transactions',
    },
    // Keap: {
    //   prod: 'https://keap.com/',
    //   qa: '',
    //   development: 'https://keap.com/',
    // },

    // 'Reporting Center': {
    //   prod: ' https://command.thryv.com/reporting-center/reports',
    //   qa: 'https://qa-reporting-center.staging.thryv.com',
    //   development: 'https://dev-reporting-center.sandbox.thryv.com',
    // },
    // 'Workforce Center': {
    //   prod: 'https://workforce-center.labs.thryv.com/',
    //   qa: 'https://qa-workforce-center.staging.thryv.com/',
    //   development: 'https://dev-workforce-center.sandbox.thryv.com/',
    // },
  }

  return paths[name][env] || paths[name]['prod']
}

const getDefaultPath = (name, countryCode) => {
  const defaultPaths = {
    'Command Center': {
      US: 'https://www.thryv.com/command-center/',
      CA: 'https://www.thryv.ca/command-center/',
      AU: 'https://www.thryv.com.au/command-center/',
      NZ: 'https://www.thryv.co.nz/command-center/',
    },
    'Marketing Center': {
      US: 'https://www.thryv.com/marketing-center/',
      CA: 'https://www.thryv.ca/marketing-center/',
      AU: 'https://www.thryv.com.au/marketing-center/',
      NZ: 'https://www.thryv.co.nz/marketing-center/',
    },
    'Business Center': {
      US: 'https://www.thryv.com/business-center/',
      CA: 'https://www.thryv.ca/business-center/',
      AU: 'https://www.thryv.com.au/business-center/',
      NZ: 'https://www.thryv.co.nz/business-center/',
    },
    ThryvPay: {
      US: 'https://www.thryv.com/features/thryvpay/',
      CA: 'https://www.thryv.ca/features/thryvpay/',
      AU: 'https://www.thryv.com.au/features/thryvpay/',
      NZ: 'https://www.thryv.co.nz/features/thryvpay/',
    },
  }

  return defaultPaths[name][countryCode] || defaultPaths[name]['US']
}

const displayCenters = (countryCode) =>
  [
    {
      name: 'Command Center',
      icon: 'cc-subscription',
    },
    {
      name: 'Marketing Center',
      icon: 'marketingCenterSubscription',
    },
    {
      name: 'Business Center',
      icon: 'businessCenterSubscription',
    },
    {
      name: 'ThryvPay',
      icon: 'thryvPaySubscription',
    },

    /*
  these will be available after they are released 
  { name: 'Workforce Center', icon: 'thryvWorkforceCenter' },
  { name: 'Reporting Center', icon: 'thryvReportingCenter' },
  { name: 'Keap', icon: 'keapIcon'},

  */
  ].map((center) => ({
    ...center,
    defaultPath: getDefaultPath(center.name, countryCode),
    path: getPath(center.name),
  }))

export const updateAccessInfo = (userCenters, countryCode) => {
  const normalize = (str) => str.replace(/\s+/g, ' ').trim().toLowerCase() //using this to prevent from failing in cases where there are extra spaces in the center name

  // using a Set for faster lookup
  const accessibleCenters = new Set(
    userCenters.map((center) => normalize(center.title)),
  )

  return displayCenters(countryCode).map((center) => ({
    ...center,
    hasAccess: accessibleCenters.has(normalize(center.name)),
  }))
}
