import React from 'react'
import ReactDOM from 'react-dom'
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk'
import { AppProviders } from './context'
import App from './App'
import './index.css'
import { Amplify } from 'aws-amplify'

const { VITE_LAUNCH_DARKLY_CLIENT_SIDE_ID, VITE_BASE_API_URL } = import.meta.env

Amplify.configure({
  API: {
    GraphQL: {
      endpoint: VITE_BASE_API_URL,
      region: 'us-east-1',
      defaultAuthMode: 'lambda',
    },
  },
})
;(async () => {
  const LDProvider = await asyncWithLDProvider({
    clientSideID: VITE_LAUNCH_DARKLY_CLIENT_SIDE_ID,
  })

  ReactDOM.render(
    <React.StrictMode>
      <LDProvider>
        <AppProviders>
          <App />
        </AppProviders>
      </LDProvider>
    </React.StrictMode>,
    document.getElementById('root'),
  )
})()
