import { useState } from 'react'
import { useSelector } from 'react-redux'
import { useThreads } from '../../../../../hooks/use-threads'
import { Icon, ModalWrapper } from '@thryvlabs/maverick'
import { FilterModal } from './filter-modal'

export const FilterButton = () => {
  const { updateThreads } = useThreads()
  const {
    inboxAdvancedFilters: { channels: channelFilters },
  } = useSelector((state) => state.inboxThreads)

  const [showModal, setShowModal] = useState(false)
  const [filter, setFilter] = useState(channelFilters || [])

  const handleClose = () => {
    updateThreads(filter)
    setShowModal(false)
  }

  const hasFilter = filter.length > 0

  return (
    <>
      <div
        data-testid="filter-button"
        className={`flex justify-center items-center h-9 min-w-[36px] px-2 border border-white  rounded-full cursor-pointer ${
          hasFilter ? 'bg-utility/text-link-info' : 'bg-white'
        }`}
        onClick={() => setShowModal(true)}
      >
        <Icon
          variant="filter"
          type={hasFilter ? 'solid' : 'regular'}
          height="18px"
          width="18px"
          color={hasFilter ? 'white' : '#057AFF'}
          className="mt-[2px]"
        />
      </div>
      <ModalWrapper
        show={showModal}
        close={() => setShowModal(false)}
        className="inline-block transform align-middle bg-white p-4 rounded-md w-full h-[90vh] my-[10px]"
      >
        <FilterModal
          close={() => setShowModal(false)}
          filter={filter}
          setFilter={setFilter}
          handleClose={handleClose}
        />
      </ModalWrapper>
    </>
  )
}
