import { Button } from '@thryvlabs/maverick'
import {
  ThryvCheckmarkIcon,
  CommandCenterLogoOrange,
  CommandCenterLogoWhite,
} from '../command-center-icon/icons'
import { useAuth0 } from '@auth0/auth0-react'

const CancellationSuccessScreen = () => {
  const { logout } = useAuth0()

  const handleViewSignUpPage = () => {
    const { VITE_COMMAND_CENTER_SIGN_UP_URL } = import.meta.env

    logout({
      logoutParams: {
        returnTo: VITE_COMMAND_CENTER_SIGN_UP_URL,
        clientId: undefined, // VITE_AUTH0_CLIENT_ID not needed since the URL is in Allowed Logout Urls list in Auth0 Settings
      },
    })
  }

  return (
    <div className="bg-sign-up-bg bg-cover bg-center bg-no-repeat h-screen w-screen flex items-center justify-center px-[23px] !overflow-y-scroll">
      <div>
        <CommandCenterLogoWhite className="mb-[40px] hidden sm:block m-auto" />

        <div className="shadow-[0px_2px_4px_0px_rgba(0,0,0,0.25)] bg-white rounded-[4px] px-[32px] py-[36px] max-w-[520px] w-full text-center flex flex-col justify-center items-center">
          <CommandCenterLogoOrange className="mb-[32px] sm:hidden" />

          <ThryvCheckmarkIcon className="mb-[16px]" />

          <h1 className="font-[Montserrat] leading-[36px] text-[28px] text-center mb-[16px]">
            Account Canceled
          </h1>

          <p className="font-[Montserrat] text-[18px] sm:text-[14px] sm:leading-[20px] font-normal leading-[26px] mb-[48px] max-w-[440px]">
            Your Command Center Basic account has been successfully canceled. To
            reactivate your account, contact our{' '}
            <a
              href="https://www.thryv.com/contact-us/"
              target="_blank"
              rel="noreferrer"
              className="underline text-[#057AFF] font-semibold"
            >
              support team
            </a>
            .
          </p>

          <div>
            <Button variant="primary" onClick={handleViewSignUpPage}>
              View Sign-Up Page
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CancellationSuccessScreen
