import { useState } from 'react'
import { useMutation } from '@apollo/client'
import {
  DELETE_LABEL_OPTIONS_BY_LOCATION,
  UPDATE_LABEL_OPTIONS_BY_LOCATION,
} from '../../../graphql/mutations/label-options-mutations'
import { Modal, Input } from '@thryvlabs/maverick'
import { GET_LABEL_OPTIONS_BY_LOCATION } from '../../../graphql/queries'
import { useMediaQuery } from 'react-responsive'

export default function Label({ label }) {
  const [newLabel, setNewLabel] = useState('')
  const isMobile = useMediaQuery({ maxWidth: 835 })
  const charCount = `${newLabel.length}/64`
  const handleNewLabel = (str) => {
    return /^\s*$/.test(str) || /^.{65,}$/.test(str)
  }

  const [updateLabel] = useMutation(UPDATE_LABEL_OPTIONS_BY_LOCATION, {
    variables: { label_id: label.LabelID, label_name: newLabel },
    refetchQueries: [GET_LABEL_OPTIONS_BY_LOCATION],
    onError: () => {},
  })

  const handleEditLabel = (e) => {
    e.preventDefault()
    updateLabel()
  }

  const [deleteLabel] = useMutation(DELETE_LABEL_OPTIONS_BY_LOCATION, {
    variables: { label_id: label.LabelID },
    refetchQueries: [GET_LABEL_OPTIONS_BY_LOCATION],
    onError: () => {},
  })

  const handleDeleteLabel = (e) => {
    e.preventDefault()
    deleteLabel()
  }

  return (
    <div
      className="min-w-[250px] py-3 px-4 rounded-md mb-3 flex flex-row justify-between"
      style={{ height: '56px', backgroundColor: '#F8F9FB' }}
    >
      <div className="flex flex-col justify-center">
        <p className="text-sm">{label.LabelName}</p>
      </div>
      <div className="flex flex-row items-center">
        <Modal
          variant="default"
          id="hyperlink-modal"
          title="Edit label"
          action
          actionClose
          footer
          btnActionText="Save"
          btnAction={handleEditLabel}
          btnActionDisabled={handleNewLabel(newLabel)}
          onOpen={() => setNewLabel(label.LabelName)}
          footerCancel
          modalTestId="edit-label-btn"
          altBtn={<div>Edit</div>}
        >
          <div className="py-5">
            <div className={`${!isMobile && 'flex [&>*:first-child]:grow'} w-full`}>
              <Input
                className="w-full"
                type="text"
                value={newLabel}
                name="newlabel"
                withLabel
                placeholder="New Label"
                labelType="static"
                variant="default"
                onChange={(e) => setNewLabel(e.target.value)}
                maxLength="64"
              />
              <div
                className={`${!isMobile && 'w-[55px] text-center'} ${
                  newLabel.length === 64 ? 'text-notification-red' : ''
                }`}
              >
                {charCount}{' '}
                {isMobile && newLabel.length === 64 && (
                  <span className="text-notification-red text-xs">
                    Max characters reached{' '}
                  </span>
                )}
              </div>
            </div>
            {!isMobile && newLabel?.length === 64 && (
              <span className="absolute text-notification-red text-xs pt-1">
                Max characters reached{' '}
              </span>
            )}
          </div>
        </Modal>

        <span className="px-2 text-[#4D4D4D]">|</span>
        <Modal
          variant="default"
          id="hyperlink-modal"
          title="Delete label"
          action
          actionClose
          footer
          btnActionText="Delete"
          btnAction={handleDeleteLabel}
          footerCancel
          modalTestId="delete-label-btn"
          altBtn={<div>Delete</div>}
        >
          <div className="py-5">
            Are you sure you want to delete this label? Once a label is deleted, you
            will lose all assigned labels to the messages on Command Center Inbox.
          </div>
        </Modal>
      </div>
    </div>
  )
}
