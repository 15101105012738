import { useEffect, useState, useContext } from 'react'
import useRedux from '../../hooks/use-redux'
import {
  setCallStatus,
  setCallMuted,
  setCallOnHold,
  clearCallEvent,
  setCallDuration,
  setClientNumber,
  setClientUid,
  setUserUid,
  setCallDirection,
  setOnIncomingCall,
  setAnswerIsClicked,
  setNotConversationEvent,
} from './slices/callSlice'
import { getLegsApi, transferToVoiceMail } from '../../vonage/vonageAPI'
import {
  GreenCircleIncomingCallIcon,
  RedCircleHangUpCallIcon,
  OutgoingCallMutedIcon,
  OutgoingCallHangupIcon,
  OutgoingCallPauseCallIcon,
} from '../command-center-icon/icons'
import { useAuth0 } from '@auth0/auth0-react'
import { setMinutesRemaining } from '../../redux-toolkit'
import {
  roundToTwoDecimalPlaces,
  secondsToMinutesConverter,
} from './add-minutes/SecondsToMinutesConverter'
import IncomingCallsWidget from '../left-navigation-bar/incoming-calls-widget/IncomingCallsWidget'
import formatContactData from '../inbox/hooks/formatContactData'
import useContactName from './hooks/useContactName'
import { useAudioPermissionModal } from './audioPermissionModal'
import { ON_UPDATE_CALL_MINUTES } from '../../graphql'
import { useRingtone } from './hooks/useRingtone'
import { AmplifyClientContext } from '../authentication/amplify-context'

const IncomingCall = ({
  isMobile,
  setShowKeypad,
  phoneValue,
  phoneAccessType,
  hasCallsAccess,
  isOwner,
}) => {
  const [dispatch, useSelector] = useRedux()
  const client = useContext(AmplifyClientContext)
  const [, , PermissionModal] = useAudioPermissionModal(isMobile)
  const isMobileApp = localStorage.getItem('IS_MOBILE')
  const {
    callStatus,
    callOnHold,
    callMuted,
    callDuration: { secs, mins },
    clientNumber,
    clientUid,
    userUid,
    onIncomingCall,
    answerIsClicked,
    vonageClient,
    audioPermission,
    notConversationEvent,
  } = useSelector((state) => state.calls)
  const { playRingtone, stopRingtone } = useRingtone()
  const { countryIso2 } = useSelector((state) => state.countryCode)
  const { phoneNumber } = useSelector((state) => state.contacts.contacts)
  const [{ minutesRemaining }] = useRedux('meetings')
  const [isTransferring, setIsTransferring] = useState(false)
  const [minutes, setMinutes] = useState(mins)
  const [seconds, setSeconds] = useState(secs)
  const [disabledButton, setDisabledButton] = useState(false)
  const [rejectIsClicked, setRejectIsClicked] = useState(false)
  const [showAudioPermission, setShowAudioPermission] = useState(false)
  const [updateCallMinutesSub, setUpdateCallMinutesSub] = useState()
  const { getAccessTokenSilently } = useAuth0()
  const {
    desktop: desktopNotifications,
    mobile: mobileNotifications,
    doNotDisturb,
  } = useSelector((state) => state.userPreferences.notificationPreferences)
  const incomingWeb = desktopNotifications?.find(
    (item) => item.label === 'Calls',
  )?.value

  const incomingMobile = mobileNotifications?.find(
    (item) => item.label === 'Calls',
  )?.value
  const contactName = useContactName()

  const subscribeOnCallMinutesUpdate = async () => {
    const accessToken = await getAccessTokenSilently()
    const subscription = client
      ?.graphql({
        query: ON_UPDATE_CALL_MINUTES,
        authToken: `Bearer ${accessToken}`,
      })
      .subscribe({
        next: ({ data }) => {
          dispatch(
            setMinutesRemaining(
              roundToTwoDecimalPlaces(
                secondsToMinutesConverter(
                  data.onUpdateCallMinutes?.SecondsRemaining,
                ),
              ),
            ),
          )
        },
      })
    setUpdateCallMinutesSub(subscription)
  }

  useEffect(() => {
    subscribeOnCallMinutesUpdate()
    return () => {
      updateCallMinutesSub?.unsubscribe()
    }
  }, [])

  const resetCallEvent = () => {
    setIsTransferring(false)
    if (!isOwner && phoneAccessType === '1') {
      setDisabledButton(true)
    } else {
      setDisabledButton(false)
    }
    setShowAudioPermission(false)
    stopRingtone()
    setTimeout(() => {
      setMinutes(0)
      setSeconds(0)
      dispatch(clearCallEvent({ phoneValue }))
      setRejectIsClicked(false)
    }, 2000)
  }

  const getClientUid = async () => {
    const convsPage = await vonageClient.app.getConversations()
    const convId = convsPage.conversations[0]?.id
    const legs = await getLegsApi(convId, vonageClient.jwt_no_sub)
    const { id: clientId } = legs.find(({ id }) => id !== userUid)
    dispatch(setClientUid(clientId))
  }

  // Checks AccessType to disable phone calls from users with only Read permissions
  useEffect(() => {
    if (!isOwner && phoneAccessType === '1') {
      setDisabledButton(true)
    }
  }, [phoneAccessType])

  // timer
  useEffect(() => {
    let timer
    if (callStatus === 'answered') {
      timer = setTimeout(() => {
        if (seconds + 1 === 60) {
          setSeconds(0)
          setMinutes(minutes + 1)
        } else {
          setSeconds(seconds + 1)
        }
      }, 1000)
    }
    return () => {
      clearTimeout(timer)
      if (callStatus !== 'completed') {
        dispatch(setCallDuration({ minutes, seconds }))
      }
    }
  }, [seconds, callStatus])

  useEffect(() => {
    if (vonageClient.app) {
      const symbol = vonageClient.app.on('callInvite', (callId, from) => {
        // check if this incoming call is outside of the user's country
        if (countryIso2 && from) {
          // CA/US users will reject calls from AU&NZ
          if (['US', 'CA'].includes(countryIso2) && from[0] !== '1') {
            vonageClient.app.hangup(callId)
          }
          // AU will reject calls from outside AU
          else if (countryIso2 === 'AU' && from.slice(0, 2) !== '61') {
            vonageClient.app.hangup(callId)
          }
          // NZ will reject calls from outside NZ
          else if (countryIso2 === 'NZ' && from.slice(0, 2) !== '64') {
            vonageClient.app.hangup(callId)

            // incoming calls that have the same country code
          } else {
            dispatch(setClientNumber(from))
            dispatch(setOnIncomingCall(true))
            dispatch(setCallDirection('Incoming'))
            dispatch(setUserUid(callId))
            dispatch(setCallStatus('ringing'))
            dispatch(setAnswerIsClicked(false))
          }
        }
      })
      const symbol2 = vonageClient.app.on(
        'legStatusUpdate',
        (_callId, legId, statusObj) => {
          const status = statusObj.y7_1.toLocaleLowerCase()
          dispatch(setNotConversationEvent(true))
          // do nothing update callstatus if other user is making outgoing call so UI won't be affected by this
          if (onIncomingCall) {
            if (status === 'answered') {
              dispatch(setCallStatus('answered'))
            }
            if (status === 'completed' && answerIsClicked && onIncomingCall) {
              dispatch(setCallStatus('completed'))
            }
          }
        },
      )
      const symbol3 = vonageClient.app.on('callInviteCancel', (callId, reason) => {
        if (
          [
            'AnsweredElsewhere',
            'RejectedElsewhere',
            'RemoteCancel',
            'RemoteTimeout',
          ].includes(reason.name)
        ) {
          if (answerIsClicked) dispatch(setCallStatus('completed'))
          if (reason.name === 'RemoteCancel' && !rejectIsClicked && onIncomingCall)
            dispatch(setCallStatus('completed'))
        }
      })
      const symbol4 = vonageClient.app.on('conversationEvent', (conv) => {
        if (
          conv.kind === 'member:left' &&
          callStatus !== 'completed' &&
          !notConversationEvent &&
          onIncomingCall
        ) {
          dispatch(setCallStatus('completed'))
        }
      })
      return () => {
        if (vonageClient.app) {
          vonageClient.app.off('callInvite', symbol)
          vonageClient.app.off('legStatusUpdate', symbol2)
          vonageClient.app.off('callInviteCancel', symbol3)
          vonageClient.app.off('conversationEvent', symbol4)
        }
      }
    }
  }, [answerIsClicked, vonageClient.app, callStatus])

  useEffect(() => {
    if (callStatus === 'ringing') {
      setShowKeypad(false)
    }
    if (
      ['cancelled', 'busy', 'timeout', 'failed', 'rejected'].includes(callStatus)
    ) {
      resetCallEvent()
    }
    if (callStatus === 'ringing' || callStatus === 'started') {
      getClientUid()
      if (
        typeof minutesRemaining === 'number' &&
        minutesRemaining > 0 &&
        !(
          doNotDisturb ||
          (!isMobile && !incomingWeb) ||
          (isMobile && !incomingMobile)
        )
      ) {
        playRingtone()
      }
    }
    if (callStatus === 'answered' && answerIsClicked !== null) {
      if (answerIsClicked) {
        stopRingtone()
      } else resetCallEvent(false)
    }
    // CASE: only happen after call is answered
    if (callStatus === 'completed') {
      resetCallEvent()
    }
  }, [callStatus, answerIsClicked])

  /* Vonage Call Functions */
  const answerCall = async () => {
    setDisabledButton(true)
    await vonageClient.app
      .answer(userUid)
      .then(() => {
        dispatch(setAnswerIsClicked(true))
        dispatch(setCallStatus('answered'))
      })
      .catch(() => {
        dispatch(setAnswerIsClicked(false))
        if (!audioPermission) {
          setShowAudioPermission(true)
        }
        setTimeout(() => {
          rejectCall()
        }, 5000)
      })
  }
  const rejectCall = async () => {
    if (rejectIsClicked) return null
    setRejectIsClicked(true)
    setDisabledButton(true)
    const token = await getAccessTokenSilently()
    stopRingtone()
    setIsTransferring(true)
    resetCallEvent()
    await transferToVoiceMail({
      token,
      clientUid,
      countryIso2,
      clientNumber: phoneNumber,
    }).catch(() => {
      vonageClient.app.hangup(userUid)
    })
  }

  useEffect(() => {
    // Transfer to voicemail if remaining minutes is 0
    if (clientUid && !isTransferring && clientNumber && countryIso2)
      if (typeof minutesRemaining === 'number' && minutesRemaining <= 0) {
        rejectCall()
      }
  }, [clientUid, clientNumber, minutesRemaining])

  // transfer to VM if users not answer within 30s
  useEffect(() => {
    if (clientUid) {
      const timer = setTimeout(() => {
        if (callStatus === 'ringing' && !isTransferring) {
          rejectCall()
        }
      }, 30000)
      return () => clearTimeout(timer)
    }
  }, [clientUid, callStatus, isTransferring])

  const hangUpCall = async () => {
    await vonageClient.app.hangup(userUid)
    dispatch(setCallStatus('completed'))
  }
  const muteCall = async () => {
    if (!callOnHold) {
      if (callMuted) await vonageClient.app.unmute(userUid)
      else await vonageClient.app.mute(userUid)
      dispatch(setCallMuted(!callMuted))
    }
  }
  const onHoldCall = async () => {
    //earmuff
    if (callOnHold) {
      await vonageClient.app.unmute(userUid)
      await vonageClient.app.disableEarmuff(userUid)
    } else {
      await vonageClient.app.mute(userUid)
      await vonageClient.app.enableEarmuff(userUid)
    }
    dispatch(setCallMuted(!callOnHold))
    dispatch(setCallOnHold(!callOnHold))
  }
  const displayDuration = () => {
    let sec = seconds
    let min = minutes
    if (sec < 10) {
      sec = '0' + sec
    }
    if (min < 10) {
      min = '0' + min
    }
    return min + ':' + sec
  }
  if (
    (typeof minutesRemaining === 'number' && minutesRemaining <= 0) ||
    isTransferring ||
    doNotDisturb ||
    (!isMobile && !incomingWeb) ||
    (isMobile && !incomingMobile)
  ) {
    return null
  }
  if (!callStatus) return null

  if (isMobileApp && hasCallsAccess)
    return <IncomingCallsWidget isMobile={isMobile} />

  // Avatar commented out for now to fix call buttons being cut off by dialpad since avatar photos can't be uploaded yet
  // const renderIncomingCallAvatar = contactName ? (
  //   <Avatar
  //     variant="name"
  //     staticBackground
  //     backgroundColor={avatarColorPicker(contactName?.split(' ')[1])}
  //     name={{
  //       firstName: contactName?.split(' ')[0] || '',
  //       lastName: contactName?.split(' ')[1] || '',
  //     }}
  //     size="default"
  //   />
  // ) : (
  //   <AvatarPlaceHolder />
  // )

  return (
    <div className="flex flex-col mt-10 items-center">
      {/* Avatar commented out for now to fix call buttons being cut off by dialpad since avatar photos can't be uploaded yet */}
      {/* {renderIncomingCallAvatar} */}
      {contactName && (
        <div className="font-montserrat font-semibold text-base text-black mt-2">
          {contactName}
        </div>
      )}
      <span
        className={`${
          contactName
            ? 'font-montserrat text-sm font-normal'
            : 'font-montserrat font-semibold text-base text-black mt-2'
        }`}
      >
        {/* FixMe when international code need to be specified */}
        {onIncomingCall
          ? /\d{10}/.test(clientNumber)
            ? formatContactData(clientNumber)
            : 'Unknown Caller'
          : 'Call Ended'}
      </span>
      <span className="font-open-sans font-normal text-base text-black mt-[2px] leading-[22px]">
        {callStatus === 'ringing' && 'Incoming Call'}
        {callStatus === 'completed' && 'Call Ended'}
        {callStatus === 'answered' && answerIsClicked && displayDuration()}
        {/* If this call was already picked up by someone */}
        {callStatus === 'answered' && !answerIsClicked && 'Call already answered'}
      </span>
      <div className="flex flex-row mt-3">
        {callStatus === 'ringing' && (
          <>
            <div className="flex flex-col items-center mr-9">
              <button
                data-testid="accept-call"
                onClick={answerCall}
                disabled={disabledButton}
              >
                <GreenCircleIncomingCallIcon
                  width="40"
                  height="40"
                  disabled={disabledButton}
                />
              </button>
              <span className="font-open-sans font-normal text-base text-black mt-2 leading-[22px]">
                Accept
              </span>
            </div>
            <div className="flex flex-col items-center ml-9">
              <button
                data-testid="reject-call"
                onClick={rejectCall}
                disabled={disabledButton}
              >
                <RedCircleHangUpCallIcon
                  width="40"
                  height="40"
                  disabled={disabledButton}
                />
              </button>
              <span className="font-open-sans font-normal text-base text-black mt-2 leading-[22px]">
                Decline
              </span>
            </div>
          </>
        )}
        {callStatus === 'answered' && answerIsClicked && (
          <>
            <div className="flex flex-col items-center mr-8">
              <button onClick={muteCall} disabled={callStatus === 'completed'}>
                <OutgoingCallMutedIcon
                  width="40"
                  height="40"
                  fill={callMuted ? 'red' : ''}
                />
              </button>
            </div>
            <div className="flex flex-col items-center mr-8">
              <button onClick={hangUpCall} disabled={callStatus === 'completed'}>
                <OutgoingCallHangupIcon width="40" height="40" />
              </button>
            </div>
            <div className="flex flex-col items-center ">
              <button onClick={onHoldCall} disabled={callStatus === 'completed'}>
                <OutgoingCallPauseCallIcon
                  width="40"
                  height="40"
                  fill={callOnHold ? 'red' : ''}
                />
              </button>
            </div>
          </>
        )}
      </div>
      {showAudioPermission ? PermissionModal : null}
    </div>
  )
}

export default IncomingCall
