import { useQuery } from '@apollo/client'
import { useEffect, useMemo, useState } from 'react'
import { GET_AVAILABLE_CHANNELS } from '../../../../graphql'

export const useAvailableChannelsByThread = (channelInfo, threadId) => {
  const [availableChannels, setAvailableChannels] = useState([])
  const [disabledChannels, setDisabledChannels] = useState([])

  const { data: availableChannelsByThread, loading } = useQuery(
    GET_AVAILABLE_CHANNELS,
    {
      variables: {
        threadId: threadId,
      },
      skip: !threadId,
    },
  )

  useEffect(() => {
    if (!availableChannelsByThread || !channelInfo) return // make sure data is available
    const mergedChannels = channelInfo
      .filter((channel) => channel.Provider !== 'video')
      .map((channel) => {
        const matchedChannel =
          availableChannelsByThread?.queryEnabledChannelsByThread?.find(
            (ch) => ch.channelId === channel.ChannelID,
          )
        return {
          ...channel,
          enabled: matchedChannel?.enabled,
          errorMessage: matchedChannel?.errorMessage ?? '',
        }
      })
    setAvailableChannels(mergedChannels.filter((ch) => ch.enabled))
    setDisabledChannels(mergedChannels.filter((ch) => !ch.enabled))
  }, [availableChannelsByThread, channelInfo])

  // memoize the returned values to avoid unnecessary re-renders
  const result = useMemo(
    () => ({
      channelsByThread: availableChannels,
      disabledChannelsByThread: disabledChannels,
      isLoading: loading, //not using this but could be used to make sure the data is loaded before rendering the component
    }),
    [availableChannels, disabledChannels, loading],
  )

  return result
}
