import { createContext, useContext, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { getContactSchema } from '../../../../schema/contact-schema'
import { useSelector } from 'react-redux'
import { trimPhoneCountryCode } from '../../../common/edit-contact-form/utils'

const FormContext = createContext(undefined)

export const FormContextProvider = ({ children, contact }) => {
  const { countryIso2: countryCode } = useSelector((state) => state.countryCode)
  const contactSchema = getContactSchema(countryCode)

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors, isValid, isDirty, dirtyFields },
  } = useForm({
    resolver: yupResolver(contactSchema),
    mode: 'onChange',
  })

  const emailIsDirty = () => {
    if (dirtyFields.email === true && contact.email.length) return true
    return false
  }

  const phoneIsDirty = () => {
    if (dirtyFields.phone === true && contact.phone.length) return true
    return false
  }

  const isEmailOrPhoneDirty = () => {
    if (emailIsDirty() || phoneIsDirty()) return true
    return false
  }

  const initDefaultFormValues = () => {
    reset({
      firstName: contact.firstName,
      lastName: contact.lastName,
      phone: trimPhoneCountryCode(contact.phone, countryCode), // This is sk3
      email: contact.email, // This is sk2
    })
  }

  useEffect(() => {
    initDefaultFormValues()
  }, [contact])

  return (
    <FormContext.Provider
      value={{
        control,
        emailIsDirty,
        phoneIsDirty,
        errors,
        isValid,
        isDirty,
        initDefaultFormValues,
        handleSubmit,
        isEmailOrPhoneDirty,
      }}
    >
      {children}
    </FormContext.Provider>
  )
}

export const useFormContext = () => {
  const data = useContext(FormContext)

  if (data === undefined) {
    throw new Error(
      'useFormContext must be used with a FormContextProvider. Please provide neccessary components in this provider.',
    )
  }

  return data
}
