import { useState, useEffect, useRef } from 'react'
import { useForm } from 'react-hook-form'
import { useMediaQuery } from 'react-responsive'
import { useSelector } from 'react-redux'
import { useAuth0 } from '@auth0/auth0-react'
import { useQuery, useMutation } from '@apollo/client'
import { GET_PERSONAL_INFO } from '../../../../graphql/queries/personal-info-queries'
import { UPDATE_PERSONAL_INFO } from '../../../../graphql/mutations/personal-info-mutations'
import personalInfoFormSchema from '../data/personalInfoFormSchema'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  convertAlphaToInputValue,
  getRole,
  getLocalPhone,
} from '../BusinessCardForms/my-account-helpers'
import { USER_ROLES } from '../../../../utils/constants'
import { useDispatch } from 'react-redux'
import { setUserInfo } from '../../../../redux-toolkit/slices/user-info/user-info.slice'

// Components
import { Button, Header } from '@thryvlabs/maverick'
import ExpandableBoxWrapper from '../../../../components/common/expandable-box-wrapper'
import { EditFormButton } from './EditFormButton'
import { LoadingSpinner } from '../../../../components/common/loading-spinner'
import { PersonalInfoNotification } from './PersonalInfoNotif'
import {
  FormAvatarInput,
  FormCountryCodeInput,
  FormEmailInput,
  FormFirstNameInput,
  FormLastNameInput,
  FormPhoneNumberInput,
  FormRoleInput,
} from './FormInputs/FormInputs'
import { useNotification } from './hooks/use-notification'

const SectionContainer = ({ className, children }) => {
  return (
    <section
      className={`${className} relative bg-[white] rounded border border-[#EFEFEF] shadow-[0px_4px_4px_0px_#0000000A] p-6 max-w-[916px] sm:m-[15px]`}
    >
      {children}
    </section>
  )
}

export const PersonalInformationCard = () => {
  const { user } = useAuth0()
  const notification = useNotification()
  const countryCodeRef = useRef(null)
  const countryISO = useSelector((state) => state.countryCode.countryIso2)
  const [personalInfo, setPersonalInfo] = useState({
    fname: '',
    lname: '',
    email: '',
    role: '',
    phoneNumber: '',
    countryCode: '',
  })

  const isMobile = useMediaQuery({ maxWidth: 640 })
  const [isEdit, setIsEdit] = useState(false)
  const [isAccordianOpen, setIsAccordianOpen] = useState(true)

  const formatNumber = (phoneNumber) => {
    if (personalInfo.countryCode === 'NZ') {
      phoneNumber = phoneNumber.replace(/\D/g, '')

      if (phoneNumber.length === 10) {
        return phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')
      } else if (phoneNumber.length === 9) {
        return phoneNumber.replace(/(\d{2})(\d{3})(\d{4})/, '($1) $2-$3')
      } else {
        return phoneNumber
      }
    } else {
      return phoneNumber
    }
  }

  const dispatch = useDispatch()
  const {
    handleSubmit,
    getValues,
    reset,
    control,
    register,
    formState: { errors },
  } = useForm({
    defaultValues: personalInfo,
    resolver: yupResolver(personalInfoFormSchema(countryISO)),
  })

  const form = {
    reset: () => {
      reset({
        fname: personalInfo.fname,
        lname: personalInfo.lname,
        email: personalInfo.email,
        role: personalInfo.role,
        phoneNumber: formatNumber(personalInfo.phoneNumber),
        countryCode: personalInfo.countryCode,
      })
    },

    toggleEdit: () => {
      notification.toggle.close()
      setIsEdit(true)
      setIsAccordianOpen(true)
    },

    getAllValues: () => {
      const newPersonalInfo = {
        fname: getValues('fname') || '',
        lname: getValues('lname') || '',
        role: getValues('role') || '',
        email: personalInfo.email || '',
        phoneNumber: formatNumber(getValues('phoneNumber')) || '',
        countryCode: personalInfo.countryCode || '',
      }

      return newPersonalInfo
    },
  }

  const accordion = {
    toggle: {
      open: () => setIsAccordianOpen(true),
      close: () => setIsAccordianOpen(false),
      changeState: () => setIsAccordianOpen(!isAccordianOpen),
    },

    delayedClose: () => {
      setTimeout(() => {
        setIsAccordianOpen(false)
      }, 2000)
    },
  }

  const initInitialPersonalInfo = (staff, language, translate) => {
    const { FirstName, LastName, Phone, Email, Role, CountryCode } = staff //Country code comes in as Alpha2Code

    const roleName = getRole(Role)
    const validCountryCode = CountryCode || countryISO
    const localPhone = getLocalPhone(CountryCode, Phone)
    countryCodeRef.current = validCountryCode

    setPersonalInfo({
      fname: FirstName || '',
      lname: LastName || '',
      phoneNumber: formatNumber(localPhone),
      countryCode: convertAlphaToInputValue(validCountryCode) || '',
      email: Email || '',
      role: roleName || '',
    })

    dispatch(
      setUserInfo({
        fname: user.given_name ? user.given_name : FirstName,
        lname: user.family_name ? user.family_name : LastName,
        email: user.email ? user.email : Email,
        role: user.cc_role ? user.cc_role : Role,
        language: language || 'en-US',
        translate: translate || false,
      }),
    )
  }

  const { isLoading } = useQuery(GET_PERSONAL_INFO, {
    variables: {
      CC_UserID: user.cc_uid,
    },
    onCompleted: (data) => {
      initInitialPersonalInfo(
        data.staff[0],
        data.queryAgentLanguage,
        data.queryAutoTranslation,
      )
    },
    onError: () => {},
  })

  const [updatePersonalInfoInDB, { loading: infoSubmissionLoading }] = useMutation(
    UPDATE_PERSONAL_INFO,
    {
      onCompleted: () => {
        notification.toggle.success('Personal Information Updated Successfully!')
        if (isMobile) accordion.delayedClose()
      },

      onError: () => {
        notification.toggle.error('Error Updating the Personal Information')
        if (isMobile) accordion.delayedClose()
      },

      refetchQueries: [GET_PERSONAL_INFO],
    },
  )

  const updatePersonalInformation = async () => {
    const newInfo = form.getAllValues()

    const newPersonalInfoForMutation = {
      CC_UserID: user.cc_uid,
      LocationID: user.businessId,
      FirstName: newInfo.fname,
      LastName: newInfo.lname,
      Phone: newInfo.phoneNumber.replace(/\D/g, ''),
      Role: USER_ROLES[newInfo.role.toLowerCase()],
    }

    dispatch(
      setUserInfo({
        fname: newInfo.fname,
        lname: newInfo.lname,
        email: newInfo.email,
      }),
    )

    setPersonalInfo(newInfo)
    await updatePersonalInfoInDB({ variables: newPersonalInfoForMutation })

    setIsEdit(false)
  }

  useEffect(() => {
    form.reset()
  }, [personalInfo])

  useEffect(() => {
    if (!isMobile) accordion.toggle.open()
  }, [isMobile])

  return (
    !isLoading && (
      <>
        <SectionContainer>
          {/* HEADER */}
          <div className="flex items-center justify-between w-full mb-6 max-h-[26px]">
            <Header
              fontWeight="semibold"
              variant="h6"
              className="mt-5 mb-5 leading-[26px]"
            >
              Personal Information
            </Header>

            <EditFormButton
              show={!isEdit}
              isMobile={isMobile}
              isAccordianOpen={isAccordianOpen}
              onClick={form.toggleEdit}
              onAccordionClick={accordion.toggle.changeState}
            />
          </div>

          {/* FORM */}
          <ExpandableBoxWrapper expanded={isAccordianOpen}>
            <form
              onSubmit={handleSubmit(updatePersonalInformation)}
              className={`flex flex-col gap-[24px]`}
            >
              <div className="flex flex-row items-center gap-[24px] sm:flex-col max-w-[522px]">
                {/* AVATAR THAT UPDATES IMAGE - TEMPORARILY DISABLED */}
                <FormAvatarInput
                  disabled={true}
                  firstName={personalInfo.fname}
                  lastName={personalInfo.lname}
                  isMobile={isMobile}
                />

                <FormFirstNameInput
                  disabled={!isEdit || infoSubmissionLoading}
                  control={control}
                  errors={errors}
                  register={register}
                />

                <FormLastNameInput
                  disabled={!isEdit || infoSubmissionLoading}
                  control={control}
                  errors={errors}
                  register={register}
                />

                <FormRoleInput control={control} disabled={true} />
              </div>

              <div className="flex flex-row sm:flex-col gap-[24px] max-w-[680px]">
                <FormEmailInput control={control} disabled={true} />

                <FormCountryCodeInput
                  disabled={true}
                  countryCode={personalInfo.countryCode}
                />

                <FormPhoneNumberInput
                  control={control}
                  errors={errors}
                  disabled={!isEdit || infoSubmissionLoading}
                  phoneNumber={personalInfo.phoneNumber}
                  getValues={getValues}
                />
              </div>

              <div className="relative flex justify-end items-center sm:justify-center h-[48px]">
                {/* SUBMISSION BUTTONS */}
                <div
                  className={`relative flex gap-[16px] transition-all duration-300 z-[1]
                      ${
                        isEdit && !infoSubmissionLoading
                          ? 'opacity-100 pointer-events-auto'
                          : 'opacity-0 pointer-events-none'
                      }`}
                >
                  <Button
                    onClick={() => {
                      if (isMobile) setIsAccordianOpen(false)
                      setIsEdit(false)
                      form.reset()
                    }}
                    variant="text"
                    level={2}
                    textVariant="light"
                  >
                    Cancel
                  </Button>

                  <Button variant="primary" type="submit">
                    Save
                  </Button>
                </div>

                <div
                  className={`absolute sm:top-1/2 sm:left-1/2 sm:transform sm:-translate-x-1/2 sm:-translate-y-1/2 opacity-0 pointer-events-none ${
                    infoSubmissionLoading && 'opacity-100'
                  }`}
                >
                  <div className="inline-block">
                    <LoadingSpinner />
                  </div>
                </div>

                {/* NOTIFICATION */}
                <PersonalInfoNotification
                  className="absolute w-full pointer-events-auto"
                  close={notification.toggle.close}
                  variant={notification.variant}
                  message={notification.message}
                  show={notification.show}
                />
              </div>
            </form>
          </ExpandableBoxWrapper>
        </SectionContainer>
      </>
    )
  )
}
