import { useRef, useEffect } from 'react'
import incomingRing from '../../../assets/ringtone/Incoming-ring.mp3'

export const useRingtone = () => {
  const audioRef = useRef(null)

  const playRingtone = () => {
    if (!audioRef.current) {
      audioRef.current = new Audio(incomingRing)
      audioRef.current.loop = true
    }

    audioRef.current.play()
  }

  const stopRingtone = () => {
    if (audioRef.current) {
      audioRef.current.pause()
      audioRef.current.currentTime = 0
      audioRef.current.src = '' // Release the resource
      audioRef.current = null
    }
  }

  // Cleanup when the component unmounts (to stop and release audio)
  useEffect(() => {
    return () => {
      stopRingtone()
    }
  }, [])

  return { playRingtone, stopRingtone }
}
