import { useEffect, useState } from 'react'
import { FilterItem, SelectionButtonWithMenu } from '../../common'
import { CheckboxGroup } from '../../../../../../../common/checkbox-group'

// const CHANNEL_TYPES = {
//   Gmail: 'gmail',
//   Outlook: 'outlook',
//   'Yahoo mail': 'yahoo',
//   Instagram: 'instagram',
//   Facebook: 'facebook',
//   Hotmail: 'hotmail',
//   Webchat: 'webchat',
//   'Microsoft 365': 'microsoft365',
//   Phone: 'phone',
//   Video: 'video',
//   IMAP: 'imap',
// }

const CHANNEL_TYPES_INVERTED = {
  gmail: 'Gmail',
  outlook: 'Outlook',
  yahoo: 'Yahoo mail',
  instagram: 'Instagram',
  facebook: 'Facebook',
  hotmail: 'Hotmail',
  webchat: 'Webchat',
  microsoft365: 'Microsoft 365',
  phone: 'Phone',
  video: 'Video',
  icloud: 'iCloud',
  imap: 'IMAP',
}

export const ChannelOption = ({ defaultChannels, defaultSelected }) => {
  const [selectedChannelsStr, setSelectedChannelsStr] = useState('Any')
  const [selectedChannels, setSelectedChannels] = useState([])
  const [channels, setChannels] = useState([])

  const getCheckedChannels = (channels) =>
    [...channels].flatMap((channel) => (channel.checked ? channel.value : []))

  const updateSelectedChannels = (items) => {
    const checkedArr = getCheckedChannels(items)

    if (checkedArr.length) {
      const selectedChannels = defaultChannels
        .filter((channel) => checkedArr.includes(channel.id))
        .map((channel) => CHANNEL_TYPES_INVERTED[channel.provider])
        .join(',')
      setSelectedChannelsStr(selectedChannels)
      setSelectedChannels(checkedArr)
    } else {
      setSelectedChannelsStr('Any')
      setSelectedChannels([])
    }
  }

  useEffect(() => {
    const formattedChannelsStr = defaultSelected
      ?.map((channel) => CHANNEL_TYPES_INVERTED[channel])
      .join(', ')
    setSelectedChannelsStr(
      formattedChannelsStr.length ? formattedChannelsStr : 'Any',
    )

    const newChannels = defaultChannels.map((channel) => {
      return {
        hasLabel: true,
        label: CHANNEL_TYPES_INVERTED[channel.provider],
        value: channel.id,
        checked: defaultSelected.includes(channel),
      }
    })

    setChannels(newChannels)

    setSelectedChannels(
      defaultSelected.map((channel) => CHANNEL_TYPES_INVERTED[channel]),
    )
  }, [])

  return (
    <FilterItem title="Channel">
      <input
        name="channel"
        value={JSON.stringify(selectedChannels)}
        className="hidden"
        readOnly
      />
      <SelectionButtonWithMenu
        dataTestID="channel-filter"
        className="!w-full"
        onStateChange={(state) =>
          state === 'dropdown closed' &&
          updateSelectedChannels(document.getElementsByName('channels'))
        }
        content={
          <div className="py-4 px-2">
            <CheckboxGroup
              className="channels-checkbox-group md:gap-6"
              data={channels}
              groupName="channels"
              column
            />
          </div>
        }
      >
        {selectedChannelsStr}
      </SelectionButtonWithMenu>
    </FilterItem>
  )
}
