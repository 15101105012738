import { useState, useEffect } from 'react'
import { useDeleteModal } from './use-delete-modal'
import { useResetPasswordSentModal } from './use-reset-password-sent-modal'
import { UPDATE_STAFF } from '../../../../../../../../../graphql/mutations'
import { GET_STAFF } from '../../../../../../../../../graphql/queries'
import { useMutation } from '@apollo/client'
import { STAFF_STATUS } from '../../../../data/staff-status'
import { sendResetPasswordEmail } from '../../../../../../../../../utils/send-reset-password-email'

// Components
import { ParagraphText, TableRow } from '@thryvlabs/maverick'
import { StaffTableCell } from '../staff-table-cell/StaffTableCell'
import { StyledStaffDropdownButton } from '../../../../../../components'
import { ChannelIconsStackedHorizontally } from './channel-icons-stacked-horizontally'
import formatContactData from '../../../../../../../../../components/inbox/hooks/formatContactData'
import { PillWithToggle } from './pill-with-toggle'
import { convertRoleTextToInteger } from '../../../../../../../../../utils/convert-role-integer-to-text'

export const StaffTableRow = ({
  user,
  activeChannels,
  statusIsActive,
  masterUser,
  totalData,
  auth0User,
  setOpenEditStaffModal,
  setStaffMemberData,
  isTablet,
  setShowPasswordResetModal,
}) => {
  const [updateStaff, { loading: updateStaffLoading }] = useMutation(UPDATE_STAFF)
  const [userIsMaster, setUserIsMaster] = useState(false)

  const { locationId, id, firstName, lastName, email, mobile_number, role } = user
  const fullName = `${firstName} ${lastName}`

  useEffect(() => {
    setUserIsMaster(masterUser.id === id)
  }, [id])

  const [statusActive, setStatusActive] = useState(statusIsActive)
  // Original 'Delete' code commented out in dropdownItems/setDropdownItems state, onSelectedItemClick, initDropdownItems
  const [dropdownItems, setDropdownItems] = useState(['Delete', 'Reset Password'])

  const { toggleDeleteModalRef, DeleteModal } = useDeleteModal()
  const { toggleResetPasswordSentModalRef, ResetPasswordSentModal } =
    useResetPasswordSentModal()

  const copyTextToClipboard = (text) => {
    navigator.clipboard.writeText(text)
  }

  const onSelectedItemClick = async (selectedOption) => {
    try {
      switch (selectedOption) {
        case 'Delete':
          toggleDeleteModalRef.current()
          break

        case 'Reset Password':
          if (userIsMaster) {
            setShowPasswordResetModal(true)
          } else {
            try {
              await sendResetPasswordEmail(user.email)
              toggleResetPasswordSentModalRef.current()
            } catch (err) {
              //
            }
          }
          break
        case 'Edit':
          setStaffMemberData({
            firstName: firstName,
            lastName: lastName,
            phone: mobile_number,
            email: email,
            role: convertRoleTextToInteger(role).toString(),
            cc_userId: id,
          })
          setOpenEditStaffModal(true)
          break
        default:
          break
      }
    } catch (err) {
      //
    }
  }

  const updateStaffStatus = () => {
    updateStaff({
      variables: {
        LocationID: locationId,
        CC_UserID: id,
        Status: statusIsActive ? STAFF_STATUS.inactive : STAFF_STATUS.active,
        Role: convertRoleTextToInteger(role),
      },
      onError: (err) => {
        // eslint-disable-next-line no-console
        console.log('ERR: ', err)
      },
      onCompleted: () => setStatusActive(!statusActive),
      refetchQueries: [GET_STAFF],
    })
  }

  const initDropdownItems = () => {
    let newDropdownItems = []

    switch (masterUser.role) {
      case 'owner':
        if (role === 'owner') newDropdownItems = ['Reset Password', 'Edit']
        else newDropdownItems = ['Delete', 'Reset Password', 'Edit']
        break
      case 'admin':
        if (role === 'owner') newDropdownItems = []
        if (role === 'admin' || role === 'staff')
          newDropdownItems = ['Delete', 'Reset Password', 'Edit']
        else newDropdownItems = ['Delete', 'Reset Password', 'Edit']
        break
      default:
        newDropdownItems = []
    }

    setDropdownItems(newDropdownItems)
  }

  useEffect(() => {
    initDropdownItems()
  }, [masterUser])

  useEffect(() => {
    setStatusActive(statusIsActive)
  }, [statusIsActive])

  const renderActionsTableRow = () => {
    return (
      <StaffTableCell
        className={`${!isTablet ? 'justify-end' : ''} flex w-[12%] min-w-[140px]`}
      >
        <StyledStaffDropdownButton
          variant="secondary"
          buttonType="dropdown"
          items={dropdownItems}
          className={`
          ${role === 'owner' && masterUser.role !== 'owner' && 'hidden'}
          ${masterUser.role === 'staff' && 'hidden'}
        `}
          setSelectedItem={onSelectedItemClick}
        >
          Actions
        </StyledStaffDropdownButton>
      </StaffTableCell>
    )
  }

  return (
    <TableRow variant="static" compact className="[&>div]:p-0 [&>div]:h-[44px]">
      <StaffTableCell
        className="cursor-pointer w-[15%] min-w-[125px]"
        onClick={() => copyTextToClipboard(fullName)}
      >
        <ParagraphText
          variant="sm"
          className="!font-[Montserrat] font-semibold leading-[20px] truncate"
          color="[#4D4D4D]"
        >
          {fullName}
        </ParagraphText>
      </StaffTableCell>

      {isTablet && renderActionsTableRow()}

      <StaffTableCell className="overflow-hidden w-[15%] min-w-[105px]">
        <ChannelIconsStackedHorizontally channels={activeChannels} />
      </StaffTableCell>

      <StaffTableCell
        className="cursor-pointer !pl-[14px] w-[25%] min-w-[225px]"
        onClick={() => copyTextToClipboard(email)}
      >
        <ParagraphText variant="sm" color="thryv-steel" className="truncate">
          {email}
        </ParagraphText>
      </StaffTableCell>

      <StaffTableCell
        className={`${
          mobile_number?.length && 'cursor-pointer'
        } w-[15%] min-w-[125px]`}
      >
        <ParagraphText
          variant="sm"
          color="thryv-steel"
          onClick={() => copyTextToClipboard(mobile_number)}
        >
          {formatContactData(mobile_number)}
        </ParagraphText>
      </StaffTableCell>

      <StaffTableCell className="w-[10%] min-w-[75px]">
        <ParagraphText
          variant="sm"
          className="!font-[Montserrat] font-semibold leading-[20px] capitalize truncate"
          color="[#4D4D4D]"
        >
          {role}
        </ParagraphText>
      </StaffTableCell>

      <StaffTableCell
        className={`flex items-center ${
          role === 'invalid role provided' && 'pointer-events-none'
        } w-[13%] min-w-[140px]`}
      >
        <PillWithToggle
          isActive={!statusActive || role === 'invalid role provided' ? false : true}
          onToggleChange={updateStaffStatus}
          removeToggle={
            role === 'owner' ||
            masterUser.role === 'staff' ||
            fullName === auth0User.name
          }
          loading={updateStaffLoading}
          role={role}
          totalData={totalData}
        />
      </StaffTableCell>

      {/* ----- Needed in order to be able to toggle delete Modal ----- */}
      <DeleteModal
        modalProps={{
          userToDelete: {
            id: id,
          },
        }}
      />

      {/* ----- Needed in order to be able to toggle rest password sent Modal ----- */}
      <ResetPasswordSentModal
        modalProps={{
          firstName: firstName,
        }}
      />

      {/* ----- Needed in order to toggle rest password Modal ----- */}

      {!isTablet && renderActionsTableRow()}
    </TableRow>
  )
}
