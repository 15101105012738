import { useState } from 'react'
import { FilterItem } from '../../common'
import { Toggle } from '@thryvlabs/maverick'

export const AttachmentOption = ({ defaultValue }) => {
  const [active, setActive] = useState(defaultValue)

  const toggleActive = () => setActive(!active)

  return (
    <FilterItem title="Attachment" className="items-center">
      <input name="attachment" value={active} className="hidden" readOnly />
      <Toggle
        toggleSize="small"
        toggled={active}
        onChange={toggleActive}
        customSelectedColor="#16A085"
        customUnselectedColor="#808080"
      />
    </FilterItem>
  )
}
