import {
  gmailBase64,
  yahooBase64,
  outlookBase64,
  webChatBase64,
  icloudBase64,
  hotmailBase64,
  aolBase64,
  imapBase64,
  activationCoverBase64,
  newAppointmentBase64,
  registerEventBase64,
  requestPaymentBase64,
  newInvoiceBase64,
  chargeCardBase64,
  inviteClientBase64,
  inviteScheduleBase64,
  paymentFailedBase64,
  selectedContactBase64,
  inboxCoverBase64,
  checkBase64,
  reserveNumberIconBase64,
  assignedNumberIconBase64,
  penPaperBase64,
  noSearchResultsIconBase64,
  commandCenterInboxBase64,
  thryvGuyIconBase64,
  billingPhoneIconBase64,
  ccSubscriptionIconBase64,
  ccMarketingCentersubscriptionIconBase64,
  ccBusinessCentersubscriptionIconBase64,
  thryvMetaAutorizationIconBase64,
  circleXGrayBackgroundIconBase64,
  thryvExclamationBase64,
  workforceBase64,
  reportingCenterIconBase64,
  thryvCheckmarkIconBase64,
} from './icons'

export const base64Icons = {
  gmail: gmailBase64,
  yahoo: yahooBase64,
  outlook: outlookBase64,
  office365: outlookBase64,
  webChat: webChatBase64,
  icloud: icloudBase64,
  hotmail: hotmailBase64,
  aol: aolBase64,
  imap: imapBase64,
  activationCover: activationCoverBase64,
  newAppointment: newAppointmentBase64,
  registerEvent: registerEventBase64,
  requestPayment: requestPaymentBase64,
  newInvoice: newInvoiceBase64,
  chargeCard: chargeCardBase64,
  inviteClient: inviteClientBase64,
  inviteSchedule: inviteScheduleBase64,
  paymentFailed: paymentFailedBase64,
  selectedContact: selectedContactBase64,
  inboxCover: inboxCoverBase64,
  check: checkBase64,
  reserveNumberIcon: reserveNumberIconBase64,
  assignedNumberIcon: assignedNumberIconBase64,
  penPaperIcon: penPaperBase64,
  noSearchResults: noSearchResultsIconBase64,
  commandCenterInbox: commandCenterInboxBase64,
  thryvGuyIcon: thryvGuyIconBase64,
  billingPhoneIcon: billingPhoneIconBase64,
  ccSubscriptionIcon: ccSubscriptionIconBase64,
  ccBusinessCenterSubIcon: ccBusinessCentersubscriptionIconBase64,
  ccMarketingCenterSubIcon: ccMarketingCentersubscriptionIconBase64,
  thryvMetaAutorizationIcon: thryvMetaAutorizationIconBase64,
  circleXGrayBackgroundIcon: circleXGrayBackgroundIconBase64,
  thryvExclamationIcon: thryvExclamationBase64,
  workforceIcon: workforceBase64,
  reportingCenterIcon: reportingCenterIconBase64,
  thryvCheckmarkIcon: thryvCheckmarkIconBase64,
}
