import { useState, useRef } from 'react'
import { useSelector } from 'react-redux'
import { useAuth0 } from '@auth0/auth0-react'
import {
  KnockFeedProvider,
  KnockProvider,
  NotificationIconButton,
} from '@knocklabs/react'
import { useFlags } from 'launchdarkly-react-client-sdk'
import {
  setModalContent,
  setShowModal,
  setDisableBackdropClick,
} from '../common/modals/slices/connectChannelsSlice'
import { useDispatch } from 'react-redux'
// Components
import BusinessNameModal from './BusinessNameModal'
import LocationModal from './LocationModal'
import UploadImage from './UploadImage'
import CustomModal from '../common/CustomModal/Modal'
import ProfileAvatar from '../common/profile-avatar/ProfileAvatar'
import { AddStaffButtonWithModal } from './components'
import { Button } from '@thryvlabs/maverick'
import { AvatarSkeleton } from './components/avatar-skeleton'
import NotificationContainer from './notifications/NotificationContainer'
import { ChannelsModalComponent } from '../common/modals/channels-modal-component/ChannelsModalComponent'
import { ReferralButton } from './components/referral-modal/ReferralButton'

import '@knocklabs/react/dist/index.css'

const TopNavBar = () => {
  const [openLocationModal, setOpenLocationModal] = useState(false)
  const [openBusinessModal, setOpenBusinessModal] = useState(false)
  const businessLocation = useSelector((state) => state.businessInfo.location)
  const [isMobile, setIsMobile] = useState(false)
  const [isVisible, setIsVisible] = useState(false)
  const notifButtonRef = useRef(null)
  const modalRef = useRef(null)
  const { VITE_KNOCK_PUBLIC_KEY, VITE_KNOCK_FEED_ID } = import.meta.env
  const { channelFilterBar, showReferralModal } = useFlags()
  const { user } = useAuth0()
  const { fname } = useSelector((state) => state.user)
  const { lname } = useSelector((state) => state.user)
  const { role } = useSelector((state) => state.user)
  const { showModal } = useSelector((state) => state.connectChannels)
  const dispatch = useDispatch()

  const userRole = role || user?.cc_role

  addEventListener('resize', () => {
    if (window.innerWidth < 640) {
      setIsMobile(true)
    } else {
      setIsMobile(false)
    }
  })

  const openUpgradePlanModal = () => {
    dispatch(setDisableBackdropClick(false))
    dispatch(setModalContent(4))
    dispatch(setShowModal(true))
  }

  return (
    <>
      <div
        className="flex h-[66px] bg-[#FFFFFF] border-r-[1px] border-t-[1px] border-b-[1px] border-[#F1F1F5]"
        data-testid="topNavBar"
      >
        {!businessLocation ? (
          <div data-testid="avatarSkeleton" className="ml-10 mt-3">
            <AvatarSkeleton />
          </div>
        ) : (
          <div className="flex w-1/2 justify-start py-[15px] px-[20px]">
            <div className="flex gap-[10px]">
              <UploadImage />
              <div className="flex flex-col">
                <CustomModal
                  isModalForm={true}
                  isOpen={openBusinessModal}
                  setIsOpen={setOpenBusinessModal}
                  handleClose={() => {
                    setOpenBusinessModal(false)
                  }}
                  width={'500px'}
                >
                  <BusinessNameModal setOpenBusinessModal={setOpenBusinessModal} />
                </CustomModal>

                {businessLocation?.name?.length > 0 ? (
                  <div className="flex">
                    <span
                      className="text-[14px] font-primary font-semibold leading-[20px] text-thryv-black cursor-pointer max-w-[700px] truncate"
                      onClick={() => setOpenBusinessModal(true)}
                    >
                      {businessLocation?.name}
                    </span>
                  </div>
                ) : (
                  <div className="flex">
                    <span
                      className="text-[14px] font-primary font-semibold leading-[20px] text-[#057AFF] cursor-pointer"
                      onClick={() => setOpenBusinessModal(true)}
                    >
                      Add business name
                    </span>
                  </div>
                )}

                {businessLocation.address?.length > 0 ? (
                  <div className="flex">
                    <span
                      onClick={() => setOpenLocationModal(true)}
                      className="text-[12px] font-primary font-semibold leading-[18px] text-thryv-night cursor-pointer"
                    >
                      {businessLocation.address}
                      {businessLocation.city.length > 0 &&
                        ', ' + businessLocation.city}
                      {businessLocation.state.length > 0 &&
                        ', ' + businessLocation.state}
                      {businessLocation.zip.length > 0 &&
                        ', ' + businessLocation.zip}
                    </span>
                  </div>
                ) : (
                  <div className="flex">
                    <span
                      onClick={() => setOpenLocationModal(true)}
                      className="text-[12px] font-primary font-semibold leading-[18px]  text-[#057AFF] cursor-pointer"
                    >
                      Update address
                    </span>
                  </div>
                )}
                <CustomModal
                  isModalForm={true}
                  isOpen={openLocationModal}
                  setIsOpen={setOpenLocationModal}
                  handleClose={() => {
                    setOpenLocationModal(false)
                  }}
                  width={isMobile ? '326px' : '500px'}
                >
                  <LocationModal setOpenLocationModal={setOpenLocationModal} />
                </CustomModal>
              </div>
            </div>
          </div>
        )}
        <div className="w-1/2 flex justify-end py-[32.73px] px-[30px] gap-4 items-center">
          {channelFilterBar && (
            <Button
              data-testid="upgrade-button"
              className="font-semibold text-[15px] px-5 h-8 flex justify-center flex-shrink-0"
              variant="primary"
              onClick={openUpgradePlanModal}
            >
              UPGRADE
            </Button>
          )}
          {userRole !== 2 && <AddStaffButtonWithModal />}
          {showReferralModal && <ReferralButton />}
          <Button
            data-testid="help-button"
            className="cc-help rounded-full border-1 font-semibold h-[30px] flex justify-center items-center shrink-0"
            variant="secondary"
            buttonType="outlined"
          >
            ? Help
          </Button>

          <KnockProvider apiKey={VITE_KNOCK_PUBLIC_KEY} userId={user.cc_uid}>
            <KnockFeedProvider
              feedId={VITE_KNOCK_FEED_ID}
              defaultFeedOptions={{
                page_size: 100,
              }}
            >
              <NotificationIconButton
                ref={notifButtonRef}
                onClick={() => setIsVisible(!isVisible)}
                className="relative"
                badgeCountType="unread"
              />
              <NotificationContainer
                isVisible={isVisible}
                ref={notifButtonRef}
                setIsVisible={setIsVisible}
              />
            </KnockFeedProvider>
          </KnockProvider>

          <div className="flex items-center justify-center">
            <ProfileAvatar
              variant="name"
              size="default"
              firstName={fname.toUpperCase()}
              lastName={lname.toUpperCase()}
            />
          </div>
        </div>
      </div>
      {showModal && <ChannelsModalComponent modalRef={modalRef} />}
    </>
  )
}

export default TopNavBar
