import { useState } from 'react'

// Components
import { ContactPanel } from './contact-panel/ContactPanel'
import { CloseModalButton } from '../mobile/components/CloseModalButton'
import { HorizontalLine } from '../mobile/components/HorizontalLine'
import {
  ContactsContextProvider,
  useContactsContext,
} from './contexts/contacts-context'
import { FormContextProvider } from './contexts/form-context'
import BackButton from '../../calls/contacts/components/back-button'
import EditContactPanel from '../edit-contact-panel/edit-contact-panel'

const EditPanel = ({ show, isMobile }) => {
  const { contact } = useContactsContext()

  return (
    <FormContextProvider contact={contact}>
      <EditContactPanel show={show} isMobile={isMobile} />
    </FormContextProvider>
  )
}

const UserProfileAndActivity = ({
  isMobile = false,
  onCloseModalButtonClick = () => {},
  openConnectPhoneModal = () => {},
}) => {
  const isDesktop = !isMobile

  // OPTIONS: contact, edit
  const [selectedPanel, setSelectedPanel] = useState('contact')

  return (
    <ContactsContextProvider>
      <div
        className={`flex justify-between items-center ${
          isMobile ? 'my-[16px]' : 'mb-[16px]'
        }`}
      >
        {selectedPanel === 'edit' && (
          <BackButton
            id="contact-panel-back-button"
            onClick={() => setSelectedPanel('contact')}
          >
            Back
          </BackButton>
        )}

        {/* THIS DIV KEEPS THE X ON THE FAR RIGHT IN THE MODAL */}
        <div></div>

        <CloseModalButton
          className={`${
            isDesktop && 'opacity-0 pointer-events-none'
          } w-[25px] h-[25px]`}
          onClick={onCloseModalButtonClick}
          aria-label="Close Contact Modal"
        />
      </div>

      {isMobile && <HorizontalLine className="mb-[24px]" />}

      <div className="grow flex flex-col">
        <EditPanel show={selectedPanel === 'edit'} isMobile={isMobile} />

        <ContactPanel
          className="grow"
          openConnectPhoneModal={openConnectPhoneModal}
          show={selectedPanel === 'contact'}
          isMobile={isMobile}
          onEditClick={() => {
            setSelectedPanel('edit')
          }}
        />
      </div>
    </ContactsContextProvider>
  )
}

UserProfileAndActivity.displayName = 'UserProfileAndActivity'

export default UserProfileAndActivity
