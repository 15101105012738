import { TransitionContainer } from '../../../../common/transition-container'
import { UnderlineBorder } from '../compose-header/compose-header'
import { CommandCenterIcon } from '../../../../command-center-icon'
import { ParagraphText } from '@thryvlabs/maverick'
import { useDispatch } from 'react-redux'
import { useClickOutside } from '../../../../../hooks/use-click-outside'
import { useRef } from 'react'
import {
  setModalContent,
  setShowModal,
} from '../../../../common/modals/slices/connectChannelsSlice'
import { useSelector } from 'react-redux'
import { TooltipWithCenteredArrow } from '../../../../common/tooltip-with-centered-arrow'
import { ChannelItem } from './channel-item'

const ExploreChannels = ({ handleOpenModal, className }) => {
  return (
    <button
      data-testid="explore-channel"
      onClick={() => {
        handleOpenModal()
      }}
      name="explore channels"
      className={`rounded hover:bg-thryv-gray-light-200 w-full h-[36px] pb-1 ${className}`}
    >
      <li className={`flex p-2 gap-2 w-full`}>
        <CommandCenterIcon
          variant="towerBroadcast"
          type="solid"
          width={20}
          height={20}
          color={'#057AFF'}
        />
        <ParagraphText variant="reg" className="text-[#057AFF]">
          Explore Channels
        </ParagraphText>
      </li>
    </button>
  )
}

export const ChannelsPopup = ({
  show,
  setActive,
  className,
  availableChannels,
  disabledChannels,
}) => {
  const dropDownRef = useRef(null)
  const dispatch = useDispatch()

  useClickOutside(() => {
    setActive(false)
  }, dropDownRef)

  const handleOpenModal = () => {
    dispatch(setShowModal(true))
    dispatch(setModalContent(1))
  }
  const { channelInfo } = useSelector((state) => state.connectChannels)
  /* 
  This should be divided it into 3 lists of channels: 
    1. the channels that are selectable and can send the message - availableChannels
    2. the channels that are disabled because of the thread: ig/fb/webchat cant send on threads that have email, email cant send on sunshine threads - disabledChannels
    3. the disconnected channels that need to be reauthenticated. - disconnectedChannels
  for list of channels #2 we need to show the tooltip that says "No active session." and have the disabled styles applied to this array. 
  */
  const disconnectedChannels = channelInfo?.filter(
    (channel) => channel.Status === '99',
  )

  return (
    <TransitionContainer
      show={show}
      className={`${className} p-2 pt-0 max-h-[200px] ${
        availableChannels?.length > 1 || disabledChannels?.length > 1
          ? 'overflow-y-scroll'
          : ''
      } `}
      width={
        availableChannels?.length > 0 || disabledChannels?.length > 0 ? 250 : 200
      }
      ref={dropDownRef}
    >
      <ExploreChannels
        handleOpenModal={handleOpenModal}
        className={'z-10 sticky inset-0  bg-white'}
      />
      <ul className={`flex flex-col`}>
        <UnderlineBorder className="mb-1" />
        {availableChannels?.map((channel, idx) => {
          return (
            <div className="flex flex-col" key={idx}>
              <ChannelItem setActive={setActive} channel={channel} />
            </div>
          )
        })}
        {disabledChannels?.map((channel, idx) => {
          return (
            <div className="flex flex-col" key={idx}>
              <TooltipWithCenteredArrow
                variant="top"
                width={
                  channel.VendorID === '1' || channel.VendorID === '2'
                    ? '200px'
                    : 'max-content'
                }
                title={
                  channel?.errorMessage || 'Not available in this conversation.'
                }
                positioning={{ offsetX: '0px', offsetY: '-16px' }}
                disableVisibleOnClick={true}
              >
                <ChannelItem
                  disabled={true}
                  setActive={setActive}
                  channel={channel}
                />
              </TooltipWithCenteredArrow>
            </div>
          )
        })}
        {disconnectedChannels?.length > 0 && <UnderlineBorder className="mb-2" />}
        {disconnectedChannels?.map((channel, idx) => {
          return (
            <div className="flex flex-col" key={idx}>
              <ChannelItem setActive={setActive} channel={channel} />
            </div>
          )
        })}
      </ul>
    </TransitionContainer>
  )
}
