const DEFAULT_INBOX_FOLDERS = ['Unread', 'Completed', 'Spam']

const createFolder = (
  folderName,
  threadId,
  startTime,
  endTime,
  updateReadTime,
  addThreadToCompleted,
  addThreadToSpam,
  getThreads,
  time,
  inboxFilter,
  removeThreadFromSpam,
  removeThreadFromTrash,
  isViewed,
  removeThreadFromCompleted,
  setSelectedThreadCompleted,
  dispatch,
  updateToUnread,
  updateToRead,
) => {
  const folder = {}
  folder.text = folderName

  switch (folderName) {
    case 'All':
      folder.onClick = () => {}
      break
    case 'Unread':
      folder.onClick = () => {
        updateToUnread()
      }
      break
    case 'Read':
      folder.onClick = () => {
        updateToRead()
      }
      break
    case 'Completed':
      folder.onClick = () => {
        addThreadToCompleted()
      }
      break
    case 'Incomplete':
      folder.onClick = () => {
        removeThreadFromCompleted()
      }
      break
    case 'Spam':
      folder.onClick = () => {
        addThreadToSpam()
      }
      break
    case 'Not Spam':
      folder.onClick = () => {
        removeThreadFromSpam()
      }
      break
    case 'Not Trash':
      folder.onClick = () => {
        removeThreadFromTrash()
      }
      break
    default:
      folder.onClick = () => {}
  }

  return folder
}

export const createFolders = (
  folderNameToExclude,
  threadId,
  startTime,
  endTime,
  updateReadTime,
  addThreadToCompleted,
  addThreadToSpam,
  getThreads,
  time,
  inboxFilter,
  removeThreadFromSpam,
  removeThreadFromTrash,
  isViewed,
  removeThreadFromCompleted,
  setSelectedThreadCompleted,
  dispatch,
  updateToUnread,
  updateToRead,
) => {
  const folderToFilter =
    inboxFilter === 'Spam'
      ? ['Not Spam']
      : inboxFilter === 'Trash'
        ? ['Not Trash']
        : inboxFilter === 'Completed' && isViewed
          ? ['Unread', 'Incomplete', 'Spam']
          : inboxFilter === 'Completed' && !isViewed
            ? ['Read', 'Incomplete', 'Spam']
            : inboxFilter === 'Inbox' && !isViewed
              ? ['Read', 'Completed', 'Spam']
              : inboxFilter === 'Inbox' && isViewed
                ? ['Unread', 'Completed', 'Spam']
                : inboxFilter === 'Unread'
                  ? ['Read', 'Completed', 'Spam']
                  : DEFAULT_INBOX_FOLDERS

  const filteredFolders = folderToFilter.filter(
    (name) => name !== folderNameToExclude,
  )

  const newFolders = filteredFolders.map((name) =>
    createFolder(
      name,
      threadId,
      startTime,
      endTime,
      updateReadTime,
      addThreadToCompleted,
      addThreadToSpam,
      getThreads,
      time,
      inboxFilter,
      removeThreadFromSpam,
      removeThreadFromTrash,
      isViewed,
      removeThreadFromCompleted,
      setSelectedThreadCompleted,
      dispatch,
      updateToUnread,
      updateToRead,
    ),
  )

  return newFolders
}
