import { useRef } from 'react'
import { useSelector } from 'react-redux'
import { Icon, Button } from '@thryvlabs/maverick'
import { FilterOption } from './filter-option'

export const FilterModal = ({ close, filter, setFilter, handleClose }) => {
  const scroll = useRef(null)
  const { channelInfo: channels } = useSelector((state) => state.connectChannels)

  const toggleChannel = (channel) => {
    if (filter.includes(channel)) {
      setFilter(filter.filter((item) => item !== channel))
    } else {
      if (filter.length + 1 === channels.length) {
        setFilter([])
      } else {
        setFilter([...filter, channel])
      }
    }
  }

  const handleReset = () => {
    setFilter([])
    scroll.current.scrollTo({ top: 0, behavior: 'smooth' })
  }

  return (
    <div className="flex flex-col gap-3 h-full font-primary font-semibold">
      <div className="flex items-center justify-between">
        <h2 className="text-lg">Filter by Channels</h2>
        <Icon
          variant="x"
          type="regular"
          height="18px"
          width="18px"
          color="#A3A5A7"
          onClick={close}
        />
      </div>
      <div
        className="ml-auto text-sm text-utility/text-link-info"
        onClick={handleReset}
      >
        Reset
      </div>
      <div className="flex flex-col gap-3 overflow-scroll snap-y grow" ref={scroll}>
        <FilterOption
          channel={{ Provider: 'All Channels' }}
          onClick={() => setFilter([])}
          selected={filter.length === 0}
        />
        {channels.map((channel, i) => (
          <FilterOption
            key={i}
            channel={channel}
            onClick={() => toggleChannel(channel.ChannelID)}
            selected={filter.includes(channel.ChannelID)}
          />
        ))}
      </div>
      <Button
        variant="primary"
        className="w-min whitespace-nowrap m-auto"
        onClick={handleClose}
      >
        Apply Filters
      </Button>
    </div>
  )
}
