import { useEffect, useState } from 'react'
import {
  GET_CLOUDSEARCH_CONTACTS_SUGGESTIONS,
  GET_CLOUDSEARCH_MESSAGES,
} from '../../../graphql/queries'

import {
  Routes,
  Route,
  useNavigate,
  useSearchParams,
  createSearchParams,
  useLocation,
} from 'react-router-dom'
import { useLazyQuery } from '@apollo/client'
import { setSelectedThread } from '../slices/inboxThreadsSlice'
import { useDispatch } from 'react-redux'
import {
  setSelectedInboxMobilePage,
  handleStartNewMessage,
  setHideThreadsMenu,
} from '../slices/inboxSlice'
import { setSearch } from '../slices/inbox-search/inbox-search-slice'
import { useSelector } from 'react-redux'
import { useThreadSelecting } from './hooks/use-thread-selecting'

// Components
import { CommandCenterNavigation } from '../../common/command-center-navigation'
import { Icon, Header } from '@thryvlabs/maverick'
import ContactsSection from './sections/contacts-section'
import MessagesSection from './sections/messages-section'
import { batch } from 'react-redux'

const SearchIcon = () => (
  <div className="flex justify-center items-center h-10 w-10 bg-[#3D5199] rounded-full">
    <Icon type="regular" variant="search" width="16" height="16" color="white" />
  </div>
)

const INITIAL_URL_PATH = '/inbox/global-search'

const GlobalSearchSection = () => {
  const dispatch = useDispatch()

  const { inboxAdvancedFilters } = useSelector((state) => state.inboxThreads)
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const [searchTerm, setSearchTerm] = useState('')
  const [selectedTab, setSelectedTab] = useState(0)

  const updateSelectedThread = useThreadSelecting(searchTerm)

  const [contacts, setContacts] = useState([])
  const [getCloudsearchContacts, { loading: contactsLoading }] = useLazyQuery(
    GET_CLOUDSEARCH_CONTACTS_SUGGESTIONS,
  )

  const [messages, setMessages] = useState([])
  const [getCloudsearchMessages, { loading: messagesLoading }] = useLazyQuery(
    GET_CLOUDSEARCH_MESSAGES,
  )

  const navigateToPage = (pageName) => {
    navigate(
      {
        pathname: `${INITIAL_URL_PATH}/${pageName}`,
        search: createSearchParams({ search: searchTerm }).toString(),
      },
      {
        replace: true,
      },
    )
  }

  const navigationLinks = [
    {
      title: `Messages ${messages.length}`,
      onClick: () => navigateToPage('messages'),
    },
    // {
    //   title: `Files ${filesList.length}`,
    //   onClick: () => navigateToPage('files'),
    // },
    {
      title: `Contacts ${contacts.length}`,
      onClick: () => navigateToPage('contacts'),
    },
  ]

  const initSelectedTab = () => {
    // FIXME: Temporary fix until we get messages and files back
    if (pathname === `${INITIAL_URL_PATH}/messages`) setSelectedTab(0)
    // else if (pathname === `${INITIAL_URL_PATH}/files`) setSelectedTab(1)
    if (pathname === `${INITIAL_URL_PATH}/contacts`) setSelectedTab(1)
  }

  const initSearchTerm = () => {
    const searchTermInUrl = searchParams.get('search')
    if (searchTermInUrl) setSearchTerm(searchTermInUrl)
  }

  const initContacts = async () => {
    const { data: cloudsearchData } = await getCloudsearchContacts({
      variables: {
        search: searchTerm,
        ranks: {
          contactsk2: 5,
          full_name: 5,
        },
      },
    })

    const cloudsearchContacts = cloudsearchData.contactSuggestions
    setContacts(cloudsearchContacts || [])
  }

  const initMessages = async () => {
    const dates = inboxAdvancedFilters.date.dates
    const betweenDate1 = dates[0] ? new Date(dates[0]) : new Date(0)
    const betweenDate2 = dates[1] ? new Date(dates[1]) : new Date()

    const channels = inboxAdvancedFilters.channels.filter((channel) => {
      const unavailableCloudsearchChannels = ['video']
      if (unavailableCloudsearchChannels.includes(channel)) return
      return channel
    })

    const { data: cloudsearchData } = await getCloudsearchMessages({
      variables: {
        search: searchTerm,
        limit: 100,
        channel_types: channels,
        time_filter: {
          between: [betweenDate1.toISOString(), betweenDate2.toISOString()],
        },
        has_attachments: inboxAdvancedFilters.attachment,
      },
    })

    const cloudsearchMessages = cloudsearchData.cloudsearchMessages

    setMessages(cloudsearchMessages || [])
  }

  useEffect(() => {
    initSelectedTab()
  }, [pathname])

  useEffect(() => {
    initSearchTerm()
  }, [searchParams, pathname])

  useEffect(() => {
    if (searchTerm.length) {
      initContacts()
      initMessages()
    }
  }, [searchTerm, inboxAdvancedFilters])

  useEffect(() => {
    dispatch(setHideThreadsMenu(true))
  }, [])

  const navigateToThread = ({ threadId, threadTitle, openRightPanel }) => {
    dispatch(
      setSelectedThread({
        id: threadId,
        name: threadTitle,
      }),
    )

    const navigateObj = {
      search: createSearchParams({ search: threadTitle }).toString(),
      state: {
        openRightPanel: openRightPanel,
      },
    }

    dispatch(setSearch(''))
    navigate('/inbox', navigateObj)
  }

  const onMobileBackButtonClick = () => {
    dispatch(handleStartNewMessage(false))
    dispatch(setSelectedInboxMobilePage('inbox-menu'))
  }

  return (
    <div className="flex flex-col h-full w-full bg-white">
      <div className="flex h-[88px] items-center pl-[30px] gap-2.5 border-b border-[#F1F1F5]">
        <Icon
          type="regular"
          variant="chevronLeft"
          height="20"
          width="12"
          color="#4D4D4D"
          className={'hidden md:block'}
          onClick={onMobileBackButtonClick}
        />
        <SearchIcon />
        <Header fontWeight="semibold" variant="h6" className="normal-case">
          Search results for "{searchTerm}"
        </Header>
      </div>

      <CommandCenterNavigation
        variant="tabs"
        wrapperClassName="grow"
        desktopNavClassName="px-6"
        linksArray={navigationLinks}
        selected={selectedTab}
        setSelectedOption={setSelectedTab}
      >
        <Routes>
          <Route
            path="messages"
            element={
              <MessagesSection
                searchTerm={searchTerm}
                messagesLoading={messagesLoading}
                messagesList={messages.map((message) => {
                  const threadId = message.threadpk.replace('#MSG', '')

                  const onContainerClick = () => {
                    batch(() => {
                      dispatch(setHideThreadsMenu(false))
                      dispatch(setSearch(''))
                    })
                    updateSelectedThread(
                      threadId,
                      message?.thread_name,
                      message?.msgpk,
                    )
                  }

                  const onNameClick = (e) => {
                    e.stopPropagation()
                    dispatch(setHideThreadsMenu(false))
                    updateSelectedThread(
                      threadId,
                      message?.email_from?.name,
                      message?.msgpk,
                    )
                  }

                  return {
                    id: threadId,
                    user: {
                      name: message?.thread_name,
                      image: '',
                    },
                    social: message.chan_type,
                    title: message.email_subject,
                    text: message.email_snippet
                      ? message.email_snippet
                      : message.body,
                    timestamp: message.timestamp,
                    onClick: {
                      container: onContainerClick,
                      name: onNameClick,
                    },
                  }
                })}
              />
            }
          />
          {/* <Route
            path="files"
            element={<FilesSection searchTerm={searchTerm} filesList={filesList} />}
          /> */}
          <Route
            path="contacts"
            element={
              <ContactsSection
                searchTerm={searchTerm}
                contactsLoading={contactsLoading}
                contactsList={contacts}
                onContactClick={navigateToThread}
              />
            }
          />
        </Routes>
      </CommandCenterNavigation>
    </div>
  )
}

export default GlobalSearchSection
