import { useEffect, useContext, useState } from 'react'
import { useSelector } from 'react-redux'
import { SUBSCRIPTION_FOR_MESSAGE_CREATED } from '../../../../graphql'
import { useSharedPhotos } from '../hooks/use-shared-photos'
import { Buffer } from 'buffer'
import useHandleImageClick from '../hooks/use-handle-image-click'
import { useLoadImageState } from '../hooks/use-shared-media-props'
import { useAuth0 } from '@auth0/auth0-react'

// Components
import { InteractiveImage } from '../components/interactive-image/InteractiveImage'
import { SharedMediaHeader } from '../components/SharedMediaHeader'
import { AmplifyClientContext } from '../../../authentication/amplify-context'

window.Buffer = Buffer

const SharedPhotosPreview = ({ show, onSeeAllClick, className }) => {
  // Re-rendering the component when this pings true //

  const selectedThreadId = useSelector(
    (state) => state.inboxThreads.selectedThread.id,
  )

  const client = useContext(AmplifyClientContext)
  const { getAccessTokenSilently } = useAuth0()

  const [createMessageSubs, setCreateMessageSubs] = useState()

  const { imagePreviewPhotos, imageAttachmentData, refetchAttachments } =
    useSharedPhotos(selectedThreadId)

  const visibleImagesUrls = Array.from(
    imagePreviewPhotos,
    ([, value]) => value,
  ).slice(0, 4)

  const visibleImages = imageAttachmentData.slice(0, 4)
  const hasImages = Boolean(visibleImages.length)

  const { useImageLoader, imageLoaded, imageProcessing, setImageProcessing } =
    useLoadImageState({ images: visibleImagesUrls })

  const { handleClickImage, handlePhotoAttachmentDownload } = useHandleImageClick({
    imageProcessing,
    setImageProcessing,
  })

  const subscribeOnMessage = async () => {
    const accessToken = await getAccessTokenSilently()
    const subscription = client
      ?.graphql({
        query: SUBSCRIPTION_FOR_MESSAGE_CREATED,
        authToken: `Bearer ${accessToken}`,
      })
      .subscribe({
        next: ({ data: subscriptionAlert }) => {
          if (subscriptionAlert) {
            refetchAttachments()
          }
        },
      })
    setCreateMessageSubs(subscription)
  }

  useEffect(() => {
    subscribeOnMessage()
    return () => {
      createMessageSubs?.unsubscribe()
    }
  }, [])

  useImageLoader()

  return (
    <section className={`${show ? 'block' : 'hidden'} ${className}`}>
      <SharedMediaHeader
        className="mb-[24px]"
        button={{ text: 'See All', onClick: onSeeAllClick }}
        disableButton={!hasImages || imageAttachmentData.length <= 4}
      >
        Shared Photos
      </SharedMediaHeader>

      {/* Image Grid */}
      {hasImages && (
        <ul className="flex gap-[10px] flex-wrap justify-between">
          {visibleImages.map((attachment, i) => {
            const imageUrl = imagePreviewPhotos.get(attachment.body)
            const imageIsLoading =
              Boolean(imageProcessing[i]) === true ||
              Boolean(imageLoaded[i]) === false

            return (
              <li key={`${attachment.pk1}-${i}`}>
                <InteractiveImage
                  variant={i === 3 ? 'numeric' : 'interactive'}
                  isLoading={imageIsLoading}
                  image={{
                    filesk: attachment.filesk,
                    src: imageUrl,
                    alt: `Image ${i + 1}`,
                    expand: () => handleClickImage(attachment, i),
                    download: () => handlePhotoAttachmentDownload(attachment, i),
                  }}
                  handleSeeAllClick={onSeeAllClick}
                >
                  +{imageAttachmentData.length - 4}
                </InteractiveImage>
              </li>
            )
          })}
        </ul>
      )}

      {!hasImages && (
        <p className="self-start font-open-sans font-normal text-sm text-[#808080] my-5">
          Your shared photos will appear here.
        </p>
      )}
    </section>
  )
}

export default SharedPhotosPreview
