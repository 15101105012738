import {
  HideKeyPadIcon,
  KeypadCallButton,
  KeypadDeleteButton,
  RedCircleXIcon,
  DownLeftAndUpRightArrowCircle,
} from '../../command-center-icon/icons'
import { useRedux } from '@hooks'
import { setDtmf } from '../slices/callSlice'
import { setOnOutgoingCall, setMobileKeypadOpen } from '../slices/callSlice'

const keyStyles =
  'w-[5.5vh] h-[5.5vh] bg-[#ECEEF1] flex flex-col justify-start items-center p-1 rounded-full cursor-pointer active:bg-[#CCC] transition-all duration-150 ease-in-out'
const keyNumberStyles =
  'font-montserrat font-semibold pt-[2px] text-[22px] leading-[2.6vh] text-[#292D2C]'
const keyCharsStyles =
  'font-montserrat font-semibold text-[1.3vh] text-[#292D2C] uppercase relative top-[-4px]'
const symbolKeyStyles =
  'w-[5.5vh] h-[5.5vh] bg-none font-montserrat font-semibold leading-[34px] text-[#292D2C] rounded-full flex items-start justify-center cursor-pointer active:bg-[#ECEEF1] transition-all duration-150 ease-in-out'
const actionKeyStyles =
  'w-[5.5vh] h-[5.5vh] bg-none flex items-center justify-center cursor-pointer active:bg-[#ECEEF1] rounded-full transition-all duration-150 ease-in-out'

const key = ({
  number,
  chars,
  symbol,
  type = 'key',
  onChange,
  setShowKeypad,
  sourceIsWidget,
  setEndCall,
  callAllowed,
}) => {
  const [{ onOutgoingCall }, dispatch] = useRedux('calls')
  const handleCall = () => {
    if (sourceIsWidget) {
      return setEndCall()
    }
    if (callAllowed) {
      onChange({ type })
      dispatch(setOnOutgoingCall(true))
      setShowKeypad(false)
    }
  }

  const handleKeyPadClose = () => {
    dispatch(setMobileKeypadOpen(false))
    setShowKeypad(false)
  }

  const handleClickOnKey = ({ key, type }) => {
    onChange({ key, type })
    if (onOutgoingCall === true) {
      dispatch(setDtmf([key]))
    }
  }

  const chooseNumberOrChars = () => {
    if (number?.length && !chars?.length) {
      return number
    }

    if (chars?.length && !number?.length) {
      return chars
    }

    return number
  }
  const renderNumber = number ? (
    <span className={keyNumberStyles}>{number}</span>
  ) : null

  const renderLetters = chars ? (
    <span className={keyCharsStyles}>{chars}</span>
  ) : null

  const renderDefaultKey =
    (number && type === 'key') || (number && chars) === 'key' ? (
      <div
        className={keyStyles}
        data-testid={`keypad-${number}`}
        onClick={() => handleClickOnKey({ key: chooseNumberOrChars(), type })}
      >
        {renderNumber}
        {renderLetters}
      </div>
    ) : null

  const renderStarKey =
    symbol === '*' && type === 'key' ? (
      <span
        className={`${symbolKeyStyles} text-[5.5vh] pt-[2.25vh]`}
        onClick={() => handleClickOnKey({ key: symbol, type })}
      >
        {symbol}
      </span>
    ) : null

  const renderPoundKey =
    symbol === '#' && type === 'key' ? (
      <span
        className={`${symbolKeyStyles} text-[3vh] pt-[1.2vh]`}
        onClick={() => handleClickOnKey({ key: symbol, type })}
      >
        {symbol}
      </span>
    ) : null

  const renderHideKey =
    type === 'hide' ? (
      <span className={`${actionKeyStyles}`} onClick={handleKeyPadClose}>
        {sourceIsWidget ? <DownLeftAndUpRightArrowCircle /> : <HideKeyPadIcon />}
      </span>
    ) : null

  const renderCallKey =
    type === 'call' ? (
      <span onClick={handleCall} className={`${actionKeyStyles}`}>
        {sourceIsWidget ? (
          <RedCircleXIcon width={56} height={56} />
        ) : (
          <KeypadCallButton />
        )}
      </span>
    ) : null

  const renderDeletelKey =
    type === 'delete' ? (
      <span
        className={`${actionKeyStyles}`}
        onClick={() => onOutgoingCall || onChange({ type })}
      >
        <KeypadDeleteButton />
      </span>
    ) : null

  return (
    <>
      {renderDefaultKey}
      {renderStarKey}
      {renderPoundKey}
      {renderHideKey}
      {renderCallKey}
      {renderDeletelKey}
    </>
  )
}

export default key
