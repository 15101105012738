import { useEffect, useState } from 'react'

const ReportingCenter = () => {
  const { VITE_ENV } = import.meta.env
  const REPORTING_CENTER_BASE_URL =
    VITE_ENV === 'prod'
      ? 'https://reporting-center.labs.thryv.com'
      : VITE_ENV === 'development'
        ? 'https://dev-reporting-center.sandbox.thryv.com'
        : VITE_ENV === 'localhost'
          ? 'http://localhost:3001'
          : 'https://qa-reporting-center.staging.thryv.com'

  const RC_ROUTES = {
    Reports: 'reports',
    Favorites: 'favorites',
    Bin: 'bin',
    ThryvAI: 'thryvAI',
  }

  const [firstLoadRoute, setFirstLoadRoute] = useState('reports')

  useEffect(() => {
    setFirstLoadRoute(getCurrentRoute(window.location.href))
  }, [])

  useEffect(() => {
    const handleMenuItemClick = (event) => {
      let target = event.target
      while (target && !target.id?.startsWith('menu-item-')) {
        target = target.parentElement
      }

      if (target?.id?.startsWith('menu-item-')) {
        const route = target.id.replace('menu-item-', '')
        sendNavigationMessage(route)
      }
    }

    const leftNavElement = document.getElementById('cc-left-nav')
    leftNavElement.addEventListener('click', handleMenuItemClick)

    return () => {
      leftNavElement.removeEventListener('click', handleMenuItemClick)
    }
  }, [])

  // Function to send navigation message to iframe
  const sendNavigationMessage = (route) => {
    if (!RC_ROUTES[route]) return
    const iframe = document.getElementById('reporting-center-iframe')
    if (iframe && iframe.contentWindow) {
      iframe.contentWindow.postMessage(
        { type: 'page:navigation', path: `/${RC_ROUTES[route]}` },
        '*',
      )
    }
  }

  return (
    <div>
      <iframe
        allowFullScreen
        id="reporting-center-iframe"
        style={{
          width: '100%',
          height: 'calc(100vh - 66.18px)',
          border: 'none',
          overflow: 'hidden',
        }}
        src={`${REPORTING_CENTER_BASE_URL}/${firstLoadRoute}?env=commandcenter`}
      ></iframe>
    </div>
  )
}

export default ReportingCenter

const getCurrentRoute = (url) => {
  const trimmedUrl = url.endsWith('/') ? url.slice(0, -1) : url
  const segments = trimmedUrl.split('/')
  return segments[segments.length - 1]
}
