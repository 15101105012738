// Hooks
import { useEffect, useState, useContext } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useMutation, useLazyQuery, useQuery } from '@apollo/client'

// Custom hooks
import useError from '../../common/edit-contact-form/hooks/use-error'
import { useFormContext } from '../user-profile-and-activity/contexts/form-context'
import { useContactsContext } from '../user-profile-and-activity/contexts/contacts-context'
import { useInboxMenuConversations } from '../../inbox/inbox-menu/hooks/use-inbox-menu-conversation/use-inbox-menu-conversations'

// Graphql
import {
  FETCH_THREADS,
  UPDATE_CONTACT,
  DELETE_MESSAGES,
  FETCH_MESSAGES_BY_THREAD_ID,
  DELETE_MESSAGES_WITH_MERGE,
  FETCH_CONTACT_WITH_PHONE,
  GET_MESSAGES_BY_PHONE_CHANNEL,
  FETCH_CONTACT_BY_THREAD,
  SUBSCRIPTION_FOR_SUCCESSFUL_CONTACT_MERGE,
} from '../../../graphql'

// Utils
import {
  USER_FRIENDLY_ERRORS,
  addCountryCodeToPhone,
  shapeContactForSubmission,
  formatSelectedThreadName,
} from '../../common/edit-contact-form/utils'
import { AmplifyClientContext } from '../../authentication/amplify-context'
import { useAuth0 } from '@auth0/auth0-react'

// Redux
import { setSelectedThread } from '../../inbox/slices/inboxThreadsSlice'
import { setUpdatedContactMergeNotification } from '../../inbox/slices/inboxSlice'
import { updateSelectedContact, setContactLists } from '../../../redux-toolkit'
import {
  setCalls,
  setPrepopulatedNumber,
  setVoicemails,
} from '../../calls/slices/callSlice'

// Components
import EditContactForm from './edit-contact-form/EditContactForm'
import ContactUpdateConfirmationPanel from '../../common/edit-contact-form/components/contact-update-confirmation-panel/contact-update-confirmation-panel'

const EditContactPanel = ({ show, isMobile }) => {
  const dispatch = useDispatch()

  const client = useContext(AmplifyClientContext)
  const { getAccessTokenSilently } = useAuth0()
  const { createVariables, initMessageThreads, initPinnedMessageThreads } =
    useInboxMenuConversations()

  const { contact, refetchContact } = useContactsContext()

  const { showError, errorMessage, updateErrorMessage, hideErrorMessage } =
    useError()

  const {
    emailIsDirty,
    phoneIsDirty,
    isEmailOrPhoneDirty,
    initDefaultFormValues,
    handleSubmit,
  } = useFormContext(contact)

  const selectedThreadId = useSelector(
    (state) => state.inboxThreads.selectedThread.id,
  )

  // OPTIONS: 'form', 'confirm-edit-contact'
  const [selectedPanel, setSelectedPanel] = useState('form')
  const [userConfirmedSubmission, setUserConfirmedSubmission] = useState(false)
  const [submissionIsLoading, setSubmissionIsLoading] = useState(false)

  const { countryIso2: countryCode } = useSelector((state) => state.countryCode)
  const { phoneChannelID } = useSelector((state) => state.contacts.contacts)

  const showContactPanel = () => {
    const backButton = document.getElementById('contact-panel-back-button')
    backButton.click()
  }

  // const { createVariables, initMessageThreads, initPinnedMessageThreads } =
  //   useInboxMenuConversations()

  const [deleteMessages] = useMutation(DELETE_MESSAGES)

  const [deleteMessagesWithMerge] = useMutation(DELETE_MESSAGES_WITH_MERGE, {
    onCompleted: () => {
      dispatch(setUpdatedContactMergeNotification(contact.contactsk1))
    },
  })

  const { refetch: refetchMessages } = useQuery(FETCH_MESSAGES_BY_THREAD_ID)

  // This is used to get the threads associated with the new email or phone.
  const [getThreads, { data: threadsData }] = useLazyQuery(FETCH_THREADS, {
    fetchPolicy: 'no-cache',
    variables: createVariables(),
    onCompleted: () => {
      const threads = threadsData?.queryThreads.items
      initMessageThreads(threads)
      const pinned = threadsData?.pinned?.items
      initPinnedMessageThreads(pinned)
    },
  })

  const [getContacts] = useLazyQuery(FETCH_CONTACT_WITH_PHONE, {
    fetchPolicy: 'network-only', // Doesn't check cache before making a network request
  })

  const [getRecentCalls] = useLazyQuery(GET_MESSAGES_BY_PHONE_CHANNEL, {
    fetchPolicy: 'network-only',
  })

  const [getVoicemails] = useLazyQuery(GET_MESSAGES_BY_PHONE_CHANNEL, {
    fetchPolicy: 'network-only',
  })

  const [contactMergeSubs, setContactMergeSubs] = useState()

  const subscribeContactMerge = async () => {
    const accessToken = await getAccessTokenSilently()
    const subscription = client
      ?.graphql({
        query: SUBSCRIPTION_FOR_SUCCESSFUL_CONTACT_MERGE,
        authToken: `Bearer ${accessToken}`,
      })
      .subscribe({
        next: async ({ data: mergeAlert }) => {
          if (
            mergeAlert &&
            !JSON.parse(mergeAlert?.onGenericUpdate?.body)?.channelId
          ) {
            await refetchAllContactInformation()
            setSubmissionIsLoading(false)
            showContactPanel()
          }
        },
      })
    setContactMergeSubs(subscription)
  }

  useEffect(() => {
    subscribeContactMerge()
    return () => {
      contactMergeSubs?.unsubscribe()
    }
  }, [])

  const [updateContact] = useMutation(UPDATE_CONTACT, {
    errorPolicy: 'all',
    refetchQueries: () => [
      {
        query: FETCH_CONTACT_BY_THREAD,
        variables: { threadid: contact.thread_id },
      },
    ],
  })

  const handleDeleteMessages = async (newContact) => {
    //Vendor ID 1 for Nylas 2 for Vonage
    await deleteMessages({
      variables: {
        VendorID: newContact.phone === contact.phone ? 1 : 2,
        thread_id: contact.contactsk1,
      },
    })
  }

  const handleDeleteMessagesAndMergeContacts = async (newContact, mergeContact) => {
    //Vendor ID 1 for Nylas 2 for Vonage
    await deleteMessagesWithMerge({
      variables: {
        VendorID: newContact.phone === contact.phone ? 1 : 2,
        thread_id: contact?.contactsk1,
        body: mergeContact.body,
        contactsk1: mergeContact.contactsk1,
        contactsk2: mergeContact.contactsk2,
        contactsk3: mergeContact.contactsk3,
        pk1: mergeContact.pk1,
        sk1: mergeContact.sk1,
      },
    })
  }

  const refetchAllContactInformation = async () => {
    await refetchContact()
    await refetchMessages({ threadid: contact.contactsk1 })
    await getThreads()
  }

  const reinitContactsList = async () => {
    const fetchedContactsData = await getContacts()
    const queriedContacts = fetchedContactsData.data.queryContactsWithPhoneNumber

    dispatch(
      setContactLists({
        sortOrder: 'asc',
        queriedContacts: queriedContacts,
        countryISO: countryCode,
      }),
    )
  }

  const reinitCallsLists = async () => {
    const fetchedRecentCalls = await getRecentCalls({
      fetchPolicy: 'network-only',
      variables: {
        chanpk: phoneChannelID,
        item_type: 'CALL',
      },
    })

    const fetchedVoicemails = await getVoicemails({
      fetchPolicy: 'network-only',
      variables: {
        chanpk: phoneChannelID,
        item_type: 'VOICEMAIL',
      },
    })

    const recentCalls = await fetchedRecentCalls.data.queryMessagesByPhoneChannel
    const voicemails = await fetchedVoicemails.data.queryMessagesByPhoneChannel

    dispatch(setCalls(recentCalls))
    dispatch(setVoicemails(voicemails))
  }

  const updateContactInfoAcrossUI = async (newsk1, phone) => {
    await reinitContactsList()
    await reinitCallsLists()

    dispatch(
      updateSelectedContact({
        sk1: newsk1,
      }),
    )

    if (phone) dispatch(setPrepopulatedNumber(phone))
  }

  const updateContactInBackend = async (newContact) => {
    const UPDATED_CONTACT = { ...newContact }

    if (UPDATED_CONTACT.phone.length) {
      UPDATED_CONTACT.phone = addCountryCodeToPhone(
        UPDATED_CONTACT.phone,
        countryCode,
      )
    }

    // Submission shape if all values are valid: { body: '', contactsk2: '', contactsk3: '', sk1: ''}
    const shapedContact = shapeContactForSubmission(UPDATED_CONTACT, contact.sk1)

    const response = await updateContact({
      variables: shapedContact,
    })

    return response
  }

  const onSubmit = async (newContact) => {
    setSubmissionIsLoading(true)
    hideErrorMessage()

    if (isEmailOrPhoneDirty() && !userConfirmedSubmission) {
      setSelectedPanel('confirm-edit-contact')
      setSubmissionIsLoading(false)
      return
    }

    try {
      const res = await updateContactInBackend(newContact)

      const requestFailed = res?.errors?.length

      if (requestFailed) {
        const errorMsg = res?.errors[0]?.message || ''
        const errCode = res?.errors[0]?.errorType || ''

        const mergeContact = res?.data?.update_contact?.mergeContact

        if (errCode === 'ERR009') {
          if (mergeContact) {
            // If getting back ERR009 WITH mergeContact need to run deleteMessages with mergeContact
            await handleDeleteMessagesAndMergeContacts(newContact, mergeContact)
          } else {
            // If getting back ERR009 WITHOUT mergeContact need to run deleteMessages without mergeContact
            await handleDeleteMessages(newContact)
          }
        } else {
          // This error means failure to update in Vcita
          if (errCode === 'ERRO14') {
            await updateContactInfoAcrossUI(
              res?.data?.update_contact?.sk1,
              newContact.phone,
            )
            showContactPanel()
            return
          }
          // You can update contact first name and last and still reach here...
          if (errorMsg.length) updateErrorMessage(USER_FRIENDLY_ERRORS[errCode])
          await refetchAllContactInformation()
          await updateContactInfoAcrossUI(
            res?.data?.update_contact?.sk1,
            newContact.phone,
          )
          setSubmissionIsLoading(false)
          showContactPanel()
          return
        }
      } else {
        await refetchAllContactInformation()
        await updateContactInfoAcrossUI(
          res?.data?.update_contact?.sk1,
          newContact.phone,
        )
        setSubmissionIsLoading(false)
        showContactPanel()
      }

      // If the current page is not calls... Idk how I feel about this code here.
      if (!window.location.href.includes('/calls')) {
        dispatch(
          setSelectedThread({
            id: selectedThreadId,
            name: formatSelectedThreadName(res),
          }),
        )
      }
    } catch (err) {
      setSubmissionIsLoading(false)
      updateErrorMessage('Contact changes not saved. Please try again.')
    }
  }

  useEffect(() => {
    initDefaultFormValues()
    setSelectedPanel('form')
  }, [show])

  return (
    <div className={`${show ? 'block' : 'hidden'}`}>
      <EditContactForm
        submissionIsLoading={submissionIsLoading}
        onSubmit={onSubmit}
        show={selectedPanel === 'form'}
        showError={showError}
        hideErrorMessage={hideErrorMessage}
        errorMessage={errorMessage}
      />

      <ContactUpdateConfirmationPanel
        contact={{
          pictureUrl: contact.pictureUrl,
          username: contact.fullName,
          email: contact.email,
          phone: contact.phone,
        }}
        show={selectedPanel === 'confirm-edit-contact'}
        variant={
          (emailIsDirty() && phoneIsDirty() && 'both') ||
          (emailIsDirty() && 'email') ||
          (phoneIsDirty() && 'phone')
        }
        onCancelClick={() => setSelectedPanel('form')}
        onConfirmClick={() => {
          setSelectedPanel('form')
          setUserConfirmedSubmission(true)
          handleSubmit(onSubmit)
        }}
        isMobile={isMobile}
      />
    </div>
  )
}

export default EditContactPanel
