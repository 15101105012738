import { useDispatch } from 'react-redux'
import { useLazyQuery } from '@apollo/client'
import { FETCH_THREADS } from '../graphql'
import { useInboxMenuConversations } from '../components/inbox/inbox-menu/hooks/use-inbox-menu-conversation/use-inbox-menu-conversations'
import {
  setInboxAdvancedFilters,
  clearInboxThreadIds,
} from '../components/inbox/slices/inboxThreadsSlice'

export const useThreads = () => {
  const dispatch = useDispatch()
  const { createVariables, initMessageThreads, initPinnedMessageThreads } =
    useInboxMenuConversations()

  const [getThreads, { data: threadsData }] = useLazyQuery(FETCH_THREADS, {
    errorPolicy: 'ignore',
    fetchPolicy: 'network-only',
    variables: createVariables(),
    onCompleted: () => {
      const threads = threadsData?.queryThreads.items
      initMessageThreads(threads)
      const pinned = threadsData?.pinned?.items
      initPinnedMessageThreads(pinned)
    },
  })

  const updateThreads = (filter) => {
    dispatch(setInboxAdvancedFilters({ channels: [...filter] }))
    dispatch(clearInboxThreadIds([]))
    getThreads()
  }

  return { updateThreads }
}
